import ClientList from "./ClientList";
import ClientDetailPage from "./ClientDetailPage";
import CreateClientPage from "./CreateClientPage";
import { isAdmin } from "app/helpers/utilsHelper";

const routes = [
  {
    path: "/clientsList",
    component: ClientList,
  },
  {
    path: "/clientDetail/:productId",
    component: ClientDetailPage,
  },
  {
    path: "/clientDetail",
    component: ClientDetailPage,
  },
];
export const ClientListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes,
};
if (isAdmin) {
  routes.push({
    path: "/add-client",
    component: CreateClientPage,
  });
}
/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
