import { AuthActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: AuthState = {
  user: {},
  success: true,
  loading: false,
  referrals: [],
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
}

const AuthReducer = (
  state: AuthState = INITIAL_STATE,
  action: Action
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case AuthActionTypes.LOGIN_USER_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.LOGOUT_USER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.LOGOUT_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.REGISTER_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.REGISTER_USER_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.USER_INFO_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.USER_INFO_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        success: true,
      };
    }
    case AuthActionTypes.USER_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.SET_REFERRALS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case AuthActionTypes.REFERRALS_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.REFERRALS_LIST_SUCCESS: {
      return {
        ...state,
        referrals: action.payload,
        loading: false,
        success: true,
      };
    }
    case AuthActionTypes.USER_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default AuthReducer;
