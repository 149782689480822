import { paginationActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: Pagination = {
  limit: 10,
  page: 0,
};

interface Action {
  payload: any;
  type: string;
}

const PaginationReducer = (
  state: Pagination = INITIAL_STATE,
  action: Action
): Pagination => {
  switch (action.type) {
    case paginationActionTypes.SET_LIST_LIMIT_PAGE:
      return updateObject(state, {
        limit: action.payload.limit,
        page: action.payload.page,
      });
    default: {
      return state;
    }
  }
};

export default PaginationReducer;
