import { useContext } from "react";
import { LanguageContext } from "./LanguageContext"

export const useLanguageHelper = () => {
  const { changeLocale, messages } = useContext(LanguageContext);

  const changeLanguage = locale => changeLocale(locale);

  return {
    languageStrings: messages,
    changeLanguage
  };
};
