import React from "react";

export const RegisterPageConfig = {
  settings: {
    layout: {
      config: {
        scroll: "content",
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        mode: "fullwidth"
      }
    }
  },
  routes: [
    {
      path: "/register/:locale?",
      component: React.lazy(() => import("./RegisterPage")),
    }
  ]
};
