import React from "react";
import { TableCell } from "@material-ui/core";
import VenueStatus from "app/main/venueList/components/VenueStatus";

const VenueStatusCell = (props) => {
  const { status } = props;

  return (
    <TableCell className="truncate" component="th" scope="row">
      <VenueStatus status={status} />
    </TableCell>
  );
};

export default VenueStatusCell;
