import { axiosInstance } from "app/api/axios";
import { CollaboratorUserActionTypes } from "../redux/actionTypes";
import {
  COLLABORATOR_USER,
  COLLABORATOR_USER_DETAIL,
  COLLABORATOR_USER_GROUP,
} from "app/api/Endpoint";
import { toast } from "react-toastify";
import {
  CreateCollaboratorUserGroupModel,
  CreateCollaboratorUserModel,
} from "app/models/CollaboratorUser";
import History from "@history";
export function setSearchText(event) {
  return {
    type: CollaboratorUserActionTypes.COLLABORATOR_USER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getCollaboratorUserList = () => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_START,
    });

    const url = COLLABORATOR_USER;

    axiosInstance
      .get(url)
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.collaboratorUsers &&
          Array.isArray(res.data.data.collaboratorUsers)
        ) {
          getCollaboratorUserListSuccess(
            dispatch,
            res.data.data.collaboratorUsers
          );
        } else {
          getCollaboratorUserListFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getCollaboratorUserListFail(dispatch, "There was an error connection");
      });
  };
};
const getCollaboratorUserListSuccess = (dispatch, data) => {
  dispatch({
    type: CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_SUCCESS,
    payload: data,
  });
};
const getCollaboratorUserListFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const createCollaboratorUser = (
  payload: CreateCollaboratorUserModel,
  languageStrings: Record<string, string>
) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_START,
    });

    const url = COLLABORATOR_USER;

    axiosInstance
      .post(url, payload)
      .then((res) => {
        if (res && res.data) {
          createCollaboratorUserSuccess(dispatch);
          toast.success(languageStrings["COllABORATED_CREATED"]);
        } else {
          createCollaboratorUserFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        createCollaboratorUserFail(
          dispatch,
          languageStrings["CONNECTION_ERROR"]
        );
      });
  };
};
const createCollaboratorUserSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_SUCCESS,
  });
  dispatch(getCollaboratorUserList());
  History.push({
    pathname: "/collaboratorsList",
  });
};
const createCollaboratorUserFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const deleteCollaboratorUsers = (
  data: string[],
  languageStrings: Record<string, string>
) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.REMOVE_COLLABORATOR_USER_START,
    });

    const url = COLLABORATOR_USER;

    axiosInstance
      .delete(url, { data })
      .then((res) => {
        if (res && res.data) {
          deleteCollaboratorUsersSuccess(dispatch);
          toast.success(languageStrings["COllABORATED_REMOVED"]);
        } else {
          deleteCollaboratorUsersFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        deleteCollaboratorUsersFail(
          dispatch,
          languageStrings["CONNECTION_ERROR"]
        );
      });
  };
};
const deleteCollaboratorUsersSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorUserActionTypes.REMOVE_COLLABORATOR_USER_SUCCESS,
  });
  dispatch(getCollaboratorUserList());
};
const deleteCollaboratorUsersFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorUserActionTypes.REMOVE_COLLABORATOR_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const createCollaboratorUserGroupGroup = (
  payload: CreateCollaboratorUserGroupModel
) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_GROUP_START,
    });

    const url = COLLABORATOR_USER_GROUP;

    axiosInstance
      .post(url, payload)
      .then((res) => {
        if (res && res.data) {
          createCollaboratorUserGroupSuccess(dispatch);
          toast.success(
            "Collaborator Users Added to Collaborator Group Successfully"
          );
        } else {
          createCollaboratorUserGroupFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        createCollaboratorUserGroupFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};
const createCollaboratorUserGroupSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_GROUP_SUCCESS,
  });
  dispatch(getCollaboratorUserList());
};
const createCollaboratorUserGroupFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_GROUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const getCollaboratorUserDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_START,
    });

    const url = `${COLLABORATOR_USER_DETAIL}/${id}`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res && res.data && res.data.data) {
          getCollaboratorUserDetailSuccess(dispatch, res.data.data);
        } else {
          getCollaboratorUserDetailFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getCollaboratorUserDetailFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};
const getCollaboratorUserDetailSuccess = (dispatch, data) => {
  dispatch({
    type: CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_SUCCESS,
    payload: data,
  });
};
const getCollaboratorUserDetailFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const editCollaboratorUser = (
  payload: CreateCollaboratorUserModel,
  languageStrings: Record<string, string>
) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_START,
    });

    const url = COLLABORATOR_USER;

    axiosInstance
      .put(url, payload)
      .then((res) => {
        if (res && res.data) {
          editCollaboratorUserSuccess(dispatch);
          toast.success(languageStrings["COllABORATED_EDITED"]);
        } else {
          editCollaboratorUserFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        editCollaboratorUserFail(dispatch, languageStrings["CONNECTION_ERROR"]);
      });
  };
};
const editCollaboratorUserSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_SUCCESS,
  });
  dispatch(getCollaboratorUserList());
  History.push({
    pathname: "/collaboratorsList",
  });
};
const editCollaboratorUserFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
