import React from "react";
import _ from "@lodash";
import { Typography, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const VenueListCard = ({ venues }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <div className="pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Typography className="h2" color="textSecondary">
            Venues
          </Typography>
        </div>
        <div className="table-responsive ">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.ADDRESS"]}</th>
                <th>{languageStrings["GENERAL.PHONE"]}</th>
              </tr>
            </thead>
            <tbody>
              {venues &&
                venues.map((item) => {
                  const { address, city, postcode, phone } = item.contactInfo;
                  const addressDetail = [address, city, postcode].join(" ");
                  return (
                    <tr>
                      <td>
                        <Typography className="truncate">
                          {addressDetail}
                        </Typography>
                      </td>
                      <td>
                        <Typography className="truncate">{phone}</Typography>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default VenueListCard;
