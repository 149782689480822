import React, { useEffect, useState } from "react";
import {
  Icon,
  Tab,
  Tabs,
  Typography,
  TextField,
  Button,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Link } from "react-router-dom";
import withReducer from "app/store/withReducer";
import LoadingOverlay from "react-loading-overlay";
import clsx from "clsx";

import reducer from "app/store/client/ClientReducer";
import {
  checkEmail,
  getClientDetails,
  getClientVenueDetail,
  saveSquareAccessTokenInVenue,
  updateCheckClient,
  updateVenue,
} from "app/store/client/ClientActions";
import { updatePos } from "app/store/pos/PosActions";
import { useDispatch, useSelector } from "react-redux";
import ClientDetailCard from "./components/ClientDetailCard";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import VenueListCard from "./components/VenuesListCard";
import { useForm } from "@fuse/hooks";
import { isEmailValid } from "app/helpers/validators";
import _ from "@lodash";
import { VenueDetails } from "./VenueDetails";
import InputDropDown from "./components/InputDropDown";
import { ClientActionTypes } from "app/store/redux/actionTypes";
import { ClientIntegration } from "./ClientIntegration";
import { updateVenuePopup } from "app/store/venuePopup/VenuePopupActions";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { StaffDropdown } from "./components/StaffDropdown";
import CreateGroupDropDown from "../common/CreateGroupDropDown";
import { getUserRole } from "app/helpers/storageHelper";
import { CurrencyTypeDropdown } from "../common/CurrencyTypeDropdown";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));
const label = { inputProps: { "aria-label": "Size switch demo" } };
const ClientDetailPage = (props) => {
  const isAdmin = getUserRole() !== DASHBOARD_ROLES.SALESPERSON;
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const productId = props.match.params.productId;

  const dispatch = useDispatch();
  const client = useSelector(({ banzzu }) => banzzu.client.clientDetails);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const venueDetail = useSelector((state) => state.banzzu.client.venueDetail);
  const loading = useSelector(({ banzzu }) => banzzu.client.loading);
  const emailTaken = useSelector(({ banzzu }) => banzzu.client.emailTaken);
  const [tabValue, setTabValue] = useState(0);
  const [comments, setComment] = useState(client.comments);
  const [validEmail, setValidEmail] = useState(true);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [posInputValue, setPosInputValue] = useState({
    posType: "",
    inputs: {},
  });
  const initialVenueData = {
    restaurantCharges: 0,
    cif: "",
    razonSocial: "",
    connectedAccountId: "",
    taxId: "",
    autoServices: false,
    hasVirtualBasket: false,
    service: "",
    serviceFee: "",
    orderType: "1",
    menuType: "2",
    payCash: false,
  };

  const initialPopupData = {
    venueId: "",
    venuePopupActive: false,
    popupImage: "",
    popupImageFile: "",
  };

  const initialCocacolaData = {
    isCocacola: false,
    cocacolaTerminalId: "",
  };

  const initialData = {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    commission: "2",
    comments: "",
    venueDetail: initialVenueData,
    popupData: initialPopupData,
    menuType: "2",
    cocacola: initialCocacolaData,
    autoServiceFeatureEnabled: false,
    revoExtraTraifEnabled: false,
    checkTables: false,
    stockEnabled: false,
    kioskEnabled: false,
    languagesModal: false,
    currencyId: "",
  };
  const { form, handleChange, setForm } = useForm(initialData);
  const currentIntegration = useSelector((state) => state.banzzu.PosReducer);

  useEffect(() => {
    setForm((prevForm) => ({ ...prevForm, referralCode: user.code }));
  }, [user]);

  useEffect(() => {
    const __ = venueDetail ? venueDetail : {};
    let _orderType = "1";
    if (__.hasVirtualBasket) _orderType = "4";
    else if (__.autoServices) _orderType = "3";
    setForm((prevForm) => ({
      ...prevForm,
      venueDetail: { orderType: _orderType, ...__ },
    }));
  }, [venueDetail]);
  useEffect(() => {
    dispatch(getClientDetails(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    setComment(client.comments);
    const existingStaff =
      client && client.staffIds && client.staffIds.length
        ? client.staffIds
        : [];
    setForm((prevForm) => ({
      ...prevForm,
      commission: client && client.commission ? client.commission : "2",
      comments: client && client.comments ? client.comments : "",
      email: client && client.email ? client.email : "",
      phoneNumber: client && client.phoneNumber ? client.phoneNumber : "",
      name: client && client.firstName ? client.firstName : "",
      surname: client && client.lastName ? client.lastName : "",
      isStaging: client && client.isStaging ? client.isStaging : false,
      cocacola: {
        ...prevForm.cocacola,
        isCocacola: client && client.isCocacola ? client.isCocacola : false,
        cocacolaTerminalId:
          client && client.cocacolaTerminalId ? client.cocacolaTerminalId : "",
      },
      staffIds: existingStaff,
      group: client && client.group ? client.group : "",
      hasCatalogueAvailable:
        client && client.hasCatalogueAvailable
          ? client.hasCatalogueAvailable
          : false,
      hasMenuV2: client && client.hasMenuV2 ? client.hasMenuV2 : false,
      autoServiceFeatureEnabled:
        (client &&
          client.settings &&
          client.settings.autoServiceFeatureEnabled) ||
        false,
      revoExtraTraifEnabled:
        (client && client.settings && client.settings.revoExtraTraifEnabled) ||
        false,

      checkTables: client.checkTables || false,
      stockEnabled: client.stockEnabled || false,
      kioskEnabled: client.kioskEnabled || false,
      languagesModal: client.showLanguageModal || false,
      currencyId: client.currencyId || "66ded580b29f52c62361dd87",
    }));
  }, [client]);

  const onVenueSelected = (event) => {
    setSelectedVenue(event.target.value);
    dispatch(getClientVenueDetail(event.target.value));
  };
  const sortedVenueList = () => {
    if (client && client.venues && client.venues.length) {
      const sortedList = client.venues.sort(function (a, b) {
        let x = a.title.toLowerCase();
        let y = b.title.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      return sortedList;
    }
    return [];
  };
  useEffect(() => {
    const venueList = sortedVenueList();
    const venueId =
      venueList && venueList.length && venueList[0]._id ? venueList[0]._id : "";
    if (venueId) {
      setSelectedVenue(venueId);
      dispatch(getClientVenueDetail(venueId));
    } else {
      dispatch({
        type: ClientActionTypes.GET_CLIENT_VENUE_DETAIL_FAIL,
      });
    }
  }, [client]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }
  const onEmailChange = (e) => {
    handleChange(e);
    if (!isEmailValid(e.target.value)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
      dispatch(checkEmail(e.target.value, false));
    }
  };

  const getNotificationError = () => {
    let notificationError = false;
    const _venue = form && form.venueDetail ? form.venueDetail : {};
    if (
      _venue &&
      _venue.orderType === "3" &&
      _venue.notifications &&
      !_venue.notifications.length
    )
      notificationError = true;

    return { notificationError };
  };
  function canBeSubmitted() {
    if (tabValue === 2) {
      const _venue = form && form.venueDetail ? form.venueDetail : {};
      let canSubmit = true;
      const isRating = _venue && _venue.isRatingEnabled === true;
      const ratingUrl = _venue && _venue.ratingUrl;
      if (isRating && !ratingUrl) {
        canSubmit = false;
      }
      const __ = getNotificationError();
      return !__.notificationError && canSubmit;
    }
    const { email, name, surname } = form;
    const isError =
      name.length > 0 &&
      surname.length > 0 &&
      email.length > 0 &&
      !emailTaken &&
      !_.isEmpty(checkFormChanges());
    return tabValue === 1 ? isError : true;
  }
  const checkFormChanges = () => {
    const newForm = {};
    if (client && form && form.email !== client.email) {
      newForm.email = form.email;
    }
    if (client && form && form.commission !== client.commission) {
      newForm.commission = form.commission;
    }
    if (client && form && form.comments !== client.comments) {
      newForm.comments = form.comments;
    }
    if (client && form && form.phoneNumber !== client.phoneNumber) {
      newForm.phoneNumber = form.phoneNumber;
    }
    if (client && form && form.name !== client.firstName) {
      newForm.name = form.name;
    }
    if (client && form && form.surname !== client.lastName) {
      newForm.surname = form.surname;
    }
    if (client && form && form.isStaging !== client.isStaging) {
      newForm.isStaging = form.isStaging;
    }
    if (client && form && form.staffIds !== client.staffIds) {
      newForm.staffIds = form.staffIds;
    }
    if (client && form && form.group !== client.group) {
      newForm.group = form.group;
    }
    if (
      client &&
      form &&
      form.hasCatalogueAvailable !== client.hasCatalogueAvailable
    ) {
      newForm.hasCatalogueAvailable = form.hasCatalogueAvailable;
    }
    if (client && form && form.hasMenuV2 !== client.hasMenuV2) {
      newForm.hasMenuV2 = form.hasMenuV2;
    }
    if (client && form && form.stockEnabled !== client.stockEnabled) {
      newForm.stockEnabled = form.stockEnabled;
    }
    if (client && form && form.kioskEnabled !== client.kioskEnabled) {
      newForm.kioskEnabled = form.kioskEnabled;
    }
    if (client && form && form.languagesModal !== client.languagesModal) {
      newForm.languagesModal = form.languagesModal;
    }
    return newForm;
  };

  const onSubmit = () => {
    if (tabValue === 1) {
      const newForm = checkFormChanges();
      const payload = {
        id: client._id,
        form: { ...newForm, showLanguageModal: form.languagesModal },
        stockEnabled: form.stockEnabled,
        kioskEnabled: form.kioskEnabled,
        currencyId: form.currencyId,
      };
      if (_.isEmpty(newForm)) {
        return;
      }
      dispatch(updateCheckClient(payload, productId));
      return;
    }
    if (tabValue === 3) {
      dispatch(updatePos(posInputValue, productId, languageStrings));
      dispatch(
        updateCheckClient(
          {
            id: client._id,
            form: {
              ...form.cocacola,
              checkTables: form.checkTables,
              revoExtraTraifEnabled: form.revoExtraTraifEnabled,
            },
          },
          productId
        )
      );
      return;
    }
    //added new key autoServiceFeatureEnabled in venue updated api
    const newdata = {
      ...form.venueDetail,
      autoServiceFeatureEnabled: form.autoServiceFeatureEnabled,
      revoExtraTraifEnabled: form.revoExtraTraifEnabled,
      checkTables: form.checkTables,
      stockEnabled: form.stockEnabled,
      kioskEnabled: form.kioskEnabled,
      languagesModal: form.languagesModal,
    };
    dispatch(updateVenue(selectedVenue, newdata));
    dispatch(updateVenuePopup(form.popupData, productId));
  };

  const onVenueSubmit = (key, value) => {
    const _venueForm = form && form.venueDetail ? form.venueDetail : {};
    if (value === "3") {
      _venueForm.autoServices = true;
      _venueForm.hasVirtualBasket = false;
    } else if (value === "4") {
      _venueForm.autoServices = false;
      _venueForm.hasVirtualBasket = true;
    } else {
      _venueForm.autoServices = false;
      _venueForm.hasVirtualBasket = false;
    }
    _venueForm[key] = value;
    setForm((prevForm) => ({
      ...prevForm,
      venueDetail: _venueForm,
    }));
  };

  const onCocacolaSubmit = (name, value) => {
    const cocacolaForm = form && form.cocacola ? form.cocacola : {};
    cocacolaForm[name] = value;
    setForm((prevForm) => ({
      ...prevForm,
      cocacola: cocacolaForm,
    }));
  };

  const onTableChanges = (name, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      checkTables: value,
    }));
  };

  const handleChangeStaff = (selectedStaff) => {
    setForm((prevForm) => ({
      ...prevForm,
      staffIds: selectedStaff,
    }));
  };

  const hadleNotificationChange = (key, value) => {
    const _venueForm = form && form.venueDetail ? form.venueDetail : {};
    _venueForm[key] = value;
    setForm((prevForm) => ({
      ...prevForm,
      venueDetail: _venueForm,
    }));
  };
  const _isStaging = form && form.isStaging ? form.isStaging : false;
  const stockEnabled = form && form.stockEnabled ? form.stockEnabled : false;
  const kioskEnabled = form && form.kioskEnabled ? form.kioskEnabled : false;
  const languagesModal =
    form && form.languagesModal ? form.languagesModal : false;
  const hasCatalogueAvailable =
    form && form.hasCatalogueAvailable ? form.hasCatalogueAvailable : false;

  const handleStockChange = () => {
    setForm((prevForm) => ({
      ...prevForm,
      stockEnabled: !stockEnabled,
    }));
  };
  const handleKioskChange = () => {
    setForm((prevForm) => ({
      ...prevForm,
      kioskEnabled: !kioskEnabled,
    }));
  };

  const handleLanguagesModalChange = () => {
    setForm((prevForm) => ({
      ...prevForm,
      languagesModal: !languagesModal,
    }));
  };
  const handleEnvChange = () => {
    setForm((prevForm) => ({
      ...prevForm,
      isStaging: !_isStaging,
    }));
  };
  const integrationLoading =
    currentIntegration && currentIntegration.loading ? true : false;
  return (
    <LoadingOverlay
      active={loading || integrationLoading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading || integrationLoading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full">
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Typography
                  className="normal-case flex items-center sm:mb-12"
                  component={Link}
                  role="button"
                  to={"/clientsList"}
                  color="inherit"
                >
                  <Icon className="mr-4 text-20">arrow_back</Icon>
                  {languageStrings["CLIENT.CLIENT_LIST"]}
                </Typography>
              </FuseAnimate>
            </div>
            {tabValue === 2 && (
              <div className="flex flex-col w-1/2 items-center sm:items-start">
                <InputDropDown
                  label={languageStrings["HOME_PAGE.RESTAURANT"]}
                  handleChange={onVenueSelected}
                  clientsList={sortedVenueList()}
                  selectedValue={selectedVenue}
                  keyPropFn={(option) => option._id}
                  valuePropFn={(option) => option.title}
                />
              </div>
            )}
            <div className="flex flex-col min-w-0 items-center sm:items-start">
              {isAdmin && tabValue !== 0 && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!canBeSubmitted()}
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              )}
            </div>
          </div>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["CLIENT_INFO"]}
            />
            <Tab
              style={{
                display: isAdmin ? undefined : "none",
              }}
              className="h-64 normal-case"
              label={languageStrings["MODIFY_CLIENT_DETAILS"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["VENUE_DETAILS"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["INTEGRATION"]}
            />
          </Tabs>
        }
        content={
          form ? (
            <div className="p-16 sm:p-24 w-full">
              {tabValue === 0 && (
                <div>
                  {client && (
                    <>
                      <div className="flex flex-col">
                        <ClientDetailCard clientDetails={client} />
                        <VenueListCard venues={client.venues} />
                      </div>
                    </>
                  )}
                </div>
              )}
              {tabValue === 1 && (
                <div style={{ display: isAdmin ? undefined : "none" }}>
                  {client && (
                    <>
                      <div className="flex flex-col">
                        <>
                          <div className="flex justify-start items-center mb-12">
                            <div className="flex items-center">
                              <Typography>
                                {languageStrings["ENV_SWITCH"]}
                              </Typography>
                              <Switch
                                checked={_isStaging}
                                onChange={handleEnvChange}
                                {...label}
                                defaultChecked
                                size="small"
                                disabled={isSalesPerson}
                              />
                            </div>
                            <div className="flex items-center ml-10">
                              <Typography>
                                {languageStrings["ENABLE_STOCK"]}
                              </Typography>
                              <Switch
                                checked={form.stockEnabled}
                                onChange={handleStockChange}
                                {...label}
                                defaultChecked
                                size="small"
                                disabled={isSalesPerson}
                              />
                            </div>
                            <div className="flex items-center ml-10">
                              <Typography>
                                {languageStrings["ENABLE_KIOSK"]}
                              </Typography>
                              <Switch
                                checked={form.kioskEnabled}
                                onChange={handleKioskChange}
                                {...label}
                                defaultChecked
                                size="small"
                                disabled={isSalesPerson}
                              />
                            </div>
                            <div className="flex items-center ml-10">
                              <Typography>
                                {languageStrings["LANGUAGES_MODAL"]}
                              </Typography>
                              <Switch
                                checked={form.languagesModal}
                                onChange={handleLanguagesModalChange}
                                {...label}
                                defaultChecked
                                size="small"
                                disabled={isSalesPerson}
                              />
                            </div>
                          </div>
                          <div className="flex">
                            <TextField
                              className="mt-8 mb-16 mr-8"
                              label={languageStrings["GENERAL.NAME"]}
                              id="name"
                              name="name"
                              value={form.name}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              fullWidth
                              disabled={isSalesPerson}
                            />
                            <TextField
                              className="mt-8 mb-16"
                              label={languageStrings["GENERAL.SURNAME"]}
                              id="surname"
                              name="surname"
                              value={form.surname}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              fullWidth
                            />
                          </div>
                          <div className="flex">
                            <TextField
                              className="mt-8 mb-16"
                              label={languageStrings["GENERAL.EMAIL"]}
                              id="email"
                              name="email"
                              value={form.email}
                              onChange={onEmailChange}
                              variant="outlined"
                              multiline
                              fullWidth
                              error={!validEmail || emailTaken}
                              helperText={
                                !validEmail
                                  ? languageStrings["ERRORS.INVALID_EMAIL"]
                                  : emailTaken
                                  ? languageStrings["ERRORS.EMAIL_TAKEN"]
                                  : ""
                              }
                            />
                            <CurrencyTypeDropdown
                              value={form.currencyId}
                              onChange={(currencyId) => {
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  currencyId,
                                }));
                              }}
                            />
                          </div>
                          <div className="flex">
                            <TextField
                              className="mt-8 mb-16"
                              label={"Phone Number"}
                              id="phoneNumber"
                              name="phoneNumber"
                              value={form.phoneNumber}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              fullWidth
                            />
                            <CreateGroupDropDown
                              handleChange={handleChange}
                              value={form && form.group ? form.group : ""}
                              showNoOption
                            />
                          </div>
                          <div className="flex">
                            <TextField
                              className="mt-8 mb-16"
                              label={`${languageStrings["GENERAL.COMMISSIONS"]} %`}
                              id="commission"
                              name="commission"
                              value={form.commission}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              fullWidth
                            />
                            <StaffDropdown
                              handleChangeStaff={handleChangeStaff}
                              staffName={
                                form &&
                                form.staffIds &&
                                Array.isArray(form.staffIds)
                                  ? form.staffIds
                                  : []
                              }
                            />
                          </div>
                          <div className="flex">
                            <TextField
                              className=""
                              label={languageStrings["GENERAL.COMMENTS"]}
                              id="comments"
                              name="comments"
                              value={form.comments}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              rows={4}
                              fullWidth
                            />
                          </div>
                        </>
                      </div>
                    </>
                  )}
                </div>
              )}
              {tabValue === 2 && (
                <VenueDetails
                  selectedVenue={selectedVenue}
                  venueForm={form}
                  setForm={setForm}
                  hadleVenueChange={onVenueSubmit}
                  hadleNotificationChange={hadleNotificationChange}
                  error={getNotificationError().notificationError}
                  productId={productId}
                  tabValue={tabValue}
                />
              )}
              {tabValue === 3 && (
                <ClientIntegration
                  clientId={productId}
                  posInputValue={posInputValue}
                  setPosInputValue={setPosInputValue}
                  handleCocacolaChange={onCocacolaSubmit}
                  clientDetail={client}
                  handleTablesChange={onTableChanges}
                  integrationForm={form}
                  setIntegrationForm={setForm}
                />
              )}
            </div>
          ) : null
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("ClientReducer", reducer)(ClientDetailPage);
