import { axiosInstance } from "app/api/axios";
import { CollaboratorGroupActionTypes } from "../redux/actionTypes";
import {
  COLLABORATOR_GROUP,
  COLLABORATOR_GROUP_DETAIL,
  UN_ASSIGN_COLLABORATOR_GROUP,
} from "app/api/Endpoint";
import { toast } from "react-toastify";
import { CollaboratorGroupModel } from "app/models/CollaboratorGroup";

export function setSearchText(event) {
  return {
    type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getCollaboratorGroupList = () => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_START,
    });

    const url = COLLABORATOR_GROUP;

    axiosInstance
      .get(url)
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.collaboratorGroups &&
          Array.isArray(res.data.data.collaboratorGroups)
        ) {
          getCollaboratorGroupListSuccess(
            dispatch,
            res.data.data.collaboratorGroups
          );
        } else {
          getCollaboratorGroupListFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getCollaboratorGroupListFail(dispatch, "There was an error connection");
      });
  };
};
const getCollaboratorGroupListSuccess = (dispatch, data) => {
  dispatch({
    type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_SUCCESS,
    payload: data,
  });
};
const getCollaboratorGroupListFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const getCollaboratorGroupDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_START,
    });

    const url = `${COLLABORATOR_GROUP_DETAIL}/${id}`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res && res.data && res.data.data) {
          getCollaboratorGroupDetailSuccess(dispatch, res.data.data);
        } else {
          getCollaboratorGroupDetailFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getCollaboratorGroupDetailFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};
const getCollaboratorGroupDetailSuccess = (dispatch, data) => {
  dispatch({
    type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_SUCCESS,
    payload: data,
  });
};
const getCollaboratorGroupDetailFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const createCollaboratorGroup = (payload: CollaboratorGroupModel) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_START,
    });

    const url = COLLABORATOR_GROUP;

    axiosInstance
      .post(url, payload)
      .then((res) => {
        if (res && res.data) {
          createCollaboratorGroupSuccess(dispatch);
          toast.success("Collaborator Group Created Successfully");
        } else {
          createCollaboratorGroupFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        console.log("e.message", e.message);
        createCollaboratorGroupFail(dispatch, "There was an error connection");
      });
  };
};
const createCollaboratorGroupSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_SUCCESS,
  });
  dispatch(getCollaboratorGroupList());
};
const createCollaboratorGroupFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const editCollaboratorGroup = (payload: CollaboratorGroupModel) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_START,
    });

    const url = COLLABORATOR_GROUP;

    axiosInstance
      .put(url, payload)
      .then((res) => {
        if (res && res.data) {
          dispatch(getCollaboratorGroupList());
          editCollaboratorGroupSuccess(dispatch);
          toast.success("Collaborator Group Created Successfully");
        } else {
          editCollaboratorGroupFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        console.log("e.message", e.message);
        editCollaboratorGroupFail(dispatch, "There was an error connection");
      });
  };
};
const editCollaboratorGroupSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_SUCCESS,
  });
  dispatch(getCollaboratorGroupList());
};
const editCollaboratorGroupFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const deleteCollaboratorGroup = (data: string[]) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_START,
    });

    const url = COLLABORATOR_GROUP;

    axiosInstance
      .delete(url, { data })
      .then((res) => {
        if (res && res.data) {
          deleteCollaboratorGroupSuccess(dispatch);
          toast.success("Collaborator Groups Removed Successfully");
        } else {
          deleteCollaboratorGroupFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        deleteCollaboratorGroupFail(dispatch, "There was an error connection");
      });
  };
};
const deleteCollaboratorGroupSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_SUCCESS,
  });
  dispatch(getCollaboratorGroupList());
};
const deleteCollaboratorGroupFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const unAssignCollaboratorGroup = (payload, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_START,
    });

    const url = UN_ASSIGN_COLLABORATOR_GROUP;

    axiosInstance
      .delete(url, { data: { ...payload } })
      .then((res) => {
        if (res && res.data) {
          unAssignCollaboratorGroupSuccess(dispatch);
          toast.success(languageStrings["COLLABORATOR_GROUP_UN_ASSIGNED"]);
        } else {
          deleteCollaboratorGroupFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        unAssignCollaboratorGroupFail(
          dispatch,
          languageStrings["CONNECTION_ERROR"]
        );
      });
  };
};
const unAssignCollaboratorGroupSuccess = (dispatch) => {
  dispatch(getCollaboratorGroupList());
  dispatch({
    type: CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_SUCCESS,
  });
};
const unAssignCollaboratorGroupFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
