import React from "react";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export const venueStatuses = [
  {
    id: 0,
    name: "VENUE_STATUS.TEST",
    color: "#FFF",
    bgColor: "red",
  },
  {
    id: 1,
    name: "VENUE_STATUS.REAL",
    color: "#FFF",
    bgColor: "green",
  },
  {
    id: 2,
    name: "VENUE_STATUS.NOT_COMPUTE",
    color: "#FFF",
    bgColor: "blue",
  },
  {
    id: 3,
    name: "VENUE_STATUS.CLIENT_TESTING",
    color: "#FFF",
    bgColor: "black",
  },
];

const VenueStatus = (props) => {
  const status = _.find(venueStatuses, { id: Number(props.status || 0) });
  const { languageStrings } = useLanguageHelper();

  return (
    <div
      style={{
        display: "inline",
        padding: " 0.4rem",
        backgroundColor: status.bgColor,
        color: status.color,
      }}
      className="text-12 rounded truncate"
    >
      {languageStrings[status.name]}
    </div>
  );
};

export default VenueStatus;
