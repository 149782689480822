import React from "react";
import _ from "@lodash";
import { Typography, Icon, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import OrdersStatus from "./OrdersStatus";
import moment from "moment";
import { getTipAmount } from "app/helpers/utilsHelper";

const OrderStatusDetail = ({
  orderTime,
  orderNumber,
  statuses,
  table,
  order = {},
}) => {
  const { languageStrings } = useLanguageHelper();
  const tip = getTipAmount([order]).totalTip;

  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Typography className="h2" color="textSecondary">
            {languageStrings["ORDER_STATUS.ORDER_NUMBER"]}: {orderNumber}
          </Typography>
        </div>
        {tip ? (
          <Typography className="h2" color="textSecondary">
            {languageStrings["TIP"]}: {tip} €
          </Typography>
        ) : null}
        {orderTime && (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              access_time
            </Icon>
            <Typography className="h2" color="textSecondary">
              {`${languageStrings["ORDER_STATUS.ORDER_TIME"]} : ${moment(
                orderTime
              ).format("hh:mm:a")}`}
            </Typography>
          </div>
        )}

        {table && (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              local_dining
            </Icon>
            <Typography className="h2" color="textSecondary">
              {`${languageStrings["ORDER_STATUS.ORDER_TABLE"]} : ${table.title}`}
            </Typography>
          </div>
        )}

        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.STATUS"]}</th>
                <th>{languageStrings["ORDER_STATUS.UPDATED_AT"]}</th>
              </tr>
            </thead>
            <tbody>
              {statuses &&
                statuses.map((status) => (
                  <tr key={status.id}>
                    <td>
                      <OrdersStatus status={status.status} />
                    </td>
                    <td>{moment(status.createdAt).format("LLL")}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default OrderStatusDetail;
