import React, { Component } from "react";
import { FuseUtils } from "@fuse";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/AppContext";
import { clientSettings } from "config";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";
class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: false,
      routes,
      publicRoutes: [
        "/register",
        `/register/${clientSettings.language}`,
        `/register/${clientSettings.language}`,
        "/login",
        `/login/${clientSettings.language}`,
        `/login/${clientSettings.language}`,
      ],
    };
  }

  componentDidMount() {
    if (this.checkUser()) {
      this.redirectRoute();
    }
  }

  componentDidUpdate() {
    if (this.checkUser()) {
      this.redirectRoute();
    }
  }

  checkUser = () => {
    const userId = getStorageItem(STORAGE_KEY.USER_ID);
    const accessToken = getStorageItem(STORAGE_KEY.ACCESS_TOKEN);
    return (
      !userId ||
      userId == "undefined" ||
      !accessToken ||
      accessToken == "undefined"
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];

    return {
      accessGranted: matched
        ? FuseUtils.hasPermission(matched.route.auth, userRole)
        : true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute() {
    const { history, location } = this.props;

    if (location.pathname === "/register") {
      history.replace({
        pathname: "/register/en",
      });
    }

    if (location.pathname === "/login") {
      history.replace({
        pathname: "/login/es",
      });
    }

    if (!this.state.publicRoutes.includes(location.pathname)) {
      history.replace({
        pathname: "/login/es",
      });
    }
  }

  render() {
    return this.state.accessGranted ? (
      <React.Fragment>{this.props.children}</React.Fragment>
    ) : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, {})(FuseAuthorization));
