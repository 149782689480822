import { axiosInstance } from "app/api/axios";

import { OrderActionTypes } from "../redux/actionTypes";
import { setListLimitPage } from "../pagination/PaginationActions";
import {
  getOrderListUrl,
  getOrderDetailUrl,
  EXPORT_ORDERS,
  deleteOrderUrl,
} from "app/api/Endpoint";
import _ from "@lodash";
import History from "@history";
import { errorAlert } from "app/helpers/alerts";
import { exportOrders } from "app/helpers/utilsHelper";

export function setOrderSearchText(event) {
  return {
    type: OrderActionTypes.SET_ORDER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getOrderList = ({ searchText, status, page, limit, order }) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDERS_LIST_START,
    });

    setListLimitPage(dispatch, limit, page);
    const url = getOrderListUrl();
    axiosInstance
      .get(
        url +
          `?searchText=${searchText}&status=${status}&page=${page}&limit=${limit}&order=${JSON.stringify(
            order
          )}`
      )
      .then((res) => {
        getOrderListSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        getOrderListFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};
export const getExportOrders = ({ startDate, endDate, languageStrings }) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.EXPORT_ORDERS_LIST_START,
    });
    const payload = {
      startDate,
      endDate,
    };
    const url = EXPORT_ORDERS;
    axiosInstance
      .get(url, { params: payload })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.length)
          exportOrders(res.data.data, languageStrings);
        dispatch({
          type: OrderActionTypes.EXPORT_ORDERS_LIST_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OrderActionTypes.EXPORT_ORDERS_LIST_FAIL,
        });
      });
  };
};

const getOrderListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_SUCCESS,
    payload: data,
  });
};
export const getOrderDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDER_DETAIL_START,
    });

    const url = getOrderDetailUrl(id);

    axiosInstance
      .get(url)
      .then((res) => {
        getOrderDetailSuccess(dispatch, res.data);
      })
      .catch((error) => {
        getOrderDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getOrderDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/orders`,
  });
};

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const deleteOrders = (orderIds: string[]) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.DELETE_ORDERS_START,
    });

    const url = deleteOrderUrl();

    axiosInstance
      .delete(url, { data: { orderIds } })
      .then((res) => {
        console.log(res);
        dispatch({
          type: OrderActionTypes.DELETE_ORDERS_SUCCESS,
        });
        dispatch(
          getOrderList({
            searchText: "",
            status: "0",
            page: 0,
            limit: 10,
            order: {},
          })
        );
      })
      .catch(() => {
        dispatch({
          type: OrderActionTypes.DELETE_ORDER_FAIL,
        });
      });
  };
};
