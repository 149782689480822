import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
import {
  getClientsList,
  setClientSearchText,
  accessClient,
  deleteClients,
} from "app/store/client/ClientActions";
import { getUserInfo } from "app/store/auth/AuthActions";
import { translate } from "app/helpers/LanguageHelper/index";
import { ExportFiles } from "../common/FIleExport";
import { clientHeaders } from "../common/data";
import moment from "moment";
import { clientSettings } from "config";
import { utils, writeFile } from "xlsx";
import {
  DASHBOARD_ROLES,
  isCollaborator,
  isCollaboratorAdmin,
} from "app/helpers/utilsHelper";
import history from "@history";
import AssignCollaboratorModal from "../collaborator/components/AssignCollaboratorModal";
import { getUserRole } from "app/helpers/storageHelper";
import { isSuperAdmin } from "app/helpers/utilsHelper";
class ClientList extends Component {
  state = {
    selectedClientId: "",
    showCollaboratorModal: false,
    clientList: [],
    clientCollaborators: [],
    order: {},
    page: 0,
    rowsPerPage: 10,
    open: false,
    showModal: false,
    accessLevels: [
      {
        id: "1",
        name: "Gratis",
      },
      {
        id: "2",
        name: "Advanced",
      },
      {
        id: "3",
        name: "Premium",
      },
    ],
    accessLevel: "0",
  };

  static getDerivedStateFromProps(props, state) {
    const { accessLevel } = state;
    const { clientsList } = props;
    if (accessLevel === "0") {
      let filterClientsList = clientsList;
      return {
        ...state,
        filterClientsList,
      };
    } else if (accessLevel !== "0") {
      let newList = clientsList.filter(
        (client) => client.accessLevel === accessLevel
      );
      return {
        ...state,
        filterClientsList: newList,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clientsList.length !== this.props.clientsList.length) {
      this.setState({
        clientList: this.props.clientsList.map((client) => ({
          ...client,
        })),
      });
    }
  }

  componentDidMount() {
    this.props.getClientsList({
      searchText: this.props.searchText ? this.props.searchText : "",
      page: 0,
      limit: 10,
      order: {},
    });
  }

  handleRowsPerPageChange = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 }, () => this.filterClientList());
  };

  handlePageChange = (event, page) => {
    this.setState({ page }, () => this.filterClientList());
  };

  handleSearch = (searchText) => {
    this.props.setClientSearchText(searchText);
    this.setState({ page: 0 }, () => this.filterClientListSearch());
  };

  handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (
      this.state.order.id === property &&
      this.state.order.direction === "desc"
    ) {
      direction = "asc";
    }

    this.setState({
      order: {
        direction,
        id,
      },
    });
    this.setState({ page: 0 }, () => this.filterClientList());
  };

  filterClientListSearch = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    const delay = 600;
    this.timeoutId = setTimeout(() => this.filterClientList(), delay);
  };

  filterClientList = () => {
    let { clientsList } = this.props;
    let { order, page, rowsPerPage } = this.state;
    let { totalClients } = this.props;
    let filteredClientsList = clientsList;
    const pageNumber =
      page * rowsPerPage > totalClients
        ? Math.ceil(totalClients / rowsPerPage - 1)
        : page;
    if (page * rowsPerPage > totalClients)
      this.setState({
        page: Math.ceil(totalClients / rowsPerPage - 1),
      });

    this.props.getClientsList({
      searchText: this.props.searchText,
      page: pageNumber,
      limit: rowsPerPage,
      order,
    });

    this.setState({
      clientList: filteredClientsList.map((client) => ({
        ...client,
      })),
    });
  };

  handleButtonClick = (n) => {
    this.props.accessClient(n._id);
  };
  handleWebButtonClick = (n) => {
    const url = process.env.REACT_APP_WEB_ACCESS_LINK + n.permalink;

    window.open(`${url}`, "_blank");
  };

  handleClientDetails = (item) => {
    history.push("/clientDetail/" + item._id);
  };
  handleAssignCollaborator = (item) => {
    this.setState({
      showCollaboratorModal: true,
      selectedClientId: item._id,
      clientCollaborators: item.collaboratorClients,
    });
  };
  filterByAccessLevel = (ev) => {
    let { value } = ev.target;
    this.setState({ accessLevel: value });
  };

  onHandleDelete = (selected) => this.props.deleteClients(selected);

  render() {
    const { languageStrings } = this.props;
    const isAdmin = getUserRole() !== DASHBOARD_ROLES.SALESPERSON;

    const headerRows = {
      data: [
        {
          id: "venueName",
          align: "left",
          disablePadding: false,
          label: languageStrings["HOME_PAGE.RESTAURANT"],
          sort: true,
          type: "text",
        },
        {
          id: "email",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.EMAIL"],
          sort: true,
          type: "text",
        },
        ...(clientSettings.showGoals
          ? [
              {
                id: "goals",
                align: "left",
                disablePadding: false,
                label: languageStrings["CLIENT.GOALS"],
                sort: true,
                type: "text",
              },
            ]
          : []),
        ...(clientSettings.showReferido
          ? [
              {
                id: "createdByCompany",
                align: "left",
                disablePadding: false,
                label: languageStrings["CLIENT.REFERRED_BY"],
                sort: true,
                type: "createdType",
              },
            ]
          : []),
        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["CLIENT.REGISTRATION_DATE"],
          sort: true,
          type: "date",
        },
        {
          id: "lastInteraction",
          align: "left",
          disablePadding: false,
          label: languageStrings["CLIENT.LAST_INTERACTION"],
          sort: true,
          type: "date",
        },
        ...(clientSettings.showSubscription
          ? [
              {
                id: "accessLevel",
                align: "left",
                disablePadding: false,
                label: languageStrings["CLIENT.SUBSCRIPTION"],
                sort: true,
                type: "accessLevel",
              },
            ]
          : []),
        {
          id: "",
          align: "left",
          disablePadding: false,
          label: languageStrings["CLIENT.ACTIONS"],
          actions: [
            {
              text: languageStrings["GENERAL.ACCESS_CLIENT"],
              onclick: this.handleButtonClick,
            },
            ...(clientSettings.showVisitWeb
              ? [
                  {
                    text: languageStrings["GENERAL.VISIT_WEB"],
                    onclick: this.handleWebButtonClick,
                  },
                ]
              : []),
            !isCollaborator && {
              text: languageStrings["CLIENT.CLIENT_DETAILS"],
              onclick: this.handleClientDetails,
            },
            isCollaboratorAdmin && {
              text: languageStrings["ASSIGN_COLLABORATOR"],
              onclick: this.handleAssignCollaborator,
            },
          ].filter(Boolean),
          sort: true,
          type: "actions",
        },
      ],
      checkbox: isAdmin && !isCollaborator,
      clickable: false,
      locationSearch: false,
      searchBy: ["email", "venueName"],
    };

    const exportFiles = () => {
      const usersData = [];

      this.props.clientsList.forEach((user) => {
        const usersObj = {};
        usersObj["venueName"] = user.venueName;
        usersObj["email"] = user.email;
        usersObj["goals"] = user.goals;
        usersObj["createdByCompany"] = user.createdByCompany;
        usersObj["createdAt"] = moment(user.createdAt).format("L");
        usersObj["lastInteraction"] = moment(user.lastInteraction).format("LT");
        usersObj["accessLevel"] = moment(user.accessLevel).format("LT");

        usersData.push(usersObj);
      });
      return usersData;
    };

    const exportDataHandler = () => {
      const data = this.props.clientsList.map((user) => {
        const { venueName, email } = user || {};

        return {
          [languageStrings["HOME_PAGE.RESTAURANT"]]: venueName,
          [languageStrings["GENERAL.EMAIL"]]: email,
          [languageStrings["CLIENT.REFERRED_BY"]]: user.createdByCompany,
          [languageStrings["CLIENT.REGISTRATION_DATE"]]: moment(
            user.createdAt
          ).format("DD/MM/YYYY"),
          [languageStrings["CLIENT.LAST_INTERACTION"]]: moment(
            user.lastInteraction
          ).format("LT"),
          [languageStrings["CLIENT.SUBSCRIPTION"]]: moment(
            user.accessLevel
          ).format("LT"),
        };
      });

      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "clients-list.xlsx");
    };

    return (
      <>
        <LoadingOverlay
          active={this.props.loading || this.props.collaboratorClientLoading}
          spinner
          styles={{
            wrapper: {
              width: "100%",
              height: "100%",
              overflow: this.props.loading ? "hidden" : "scroll",
            },
          }}
          text="Loading..."
        >
          <div
            style={{
              flex: 1,
              filter: this.state.open ? "blur(3px)" : "none",
              marginTop: 30,
            }}
          >
            <FusePageCarded
              classes={{
                content: "flex",
                header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
              }}
              header={
                <div
                  className={
                    "flex w-full items-center flex-row justify-between"
                  }
                >
                  <ListHeader
                    title={languageStrings["CLIENT.CLIENT_LIST"]}
                    setSearchText={this.handleSearch}
                    searchType={"client"}
                    selectors={
                      clientSettings.showSubscriptionsDropdown
                        ? [
                            {
                              onChange: this.filterByAccessLevel,
                              values: this.state.accessLevels,
                              keys: ["id", "name"],
                              title: languageStrings["CLIENT.SUBSCRIPTION"],
                              firstOption: languageStrings["CLIENT.ALL"],
                            },
                          ]
                        : []
                    }
                  />
                  {isSuperAdmin && (
                    <ExportFiles
                      fileName={"client-list"}
                      data={exportFiles()}
                      header={clientHeaders}
                      onXLSXClick={exportDataHandler}
                      showXlsx
                    />
                  )}
                </div>
              }
              content={
                <ListTablePaginated
                  handleDelete={this.onHandleDelete}
                  headerRows={headerRows}
                  data={this.state.clientList}
                  totalData={this.props.totalClients}
                  searchText={this.props.searchText}
                  order={this.state.order}
                  handleRequestSort={this.handleRequestSort}
                  page={this.state.page}
                  rowsPerPage={this.state.rowsPerPage}
                  handleRowsPerPageChange={this.handleRowsPerPageChange}
                  handlePageChange={this.handlePageChange}
                />
              }
              innerScroll
            />
          </div>
          <AssignCollaboratorModal
            clientCollaborators={this.state.clientCollaborators}
            selectedClientId={this.state.selectedClientId}
            open={this.state.showCollaboratorModal}
            onClose={() => {
              this.setState({
                selectedClientId: "",
                showCollaboratorModal: false,
              });
            }}
            order={this.state.order}
          />
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.client.loading,
    clientsList: state.banzzu.client.clientsList,
    totalClients: state.banzzu.client.totalClients,
    searchText: state.banzzu.client.searchText,
    collaboratorClientLoading: state.banzzu.CollaboratorClient.loading,
  };
};
const mapDispatchToProps = {
  getClientsList,
  setClientSearchText,
  accessClient,
  getUserInfo,
  deleteClients,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(ClientList));
