import { axiosInstance } from "app/api/axios";
import { CollaboratorClientActionTypes } from "../redux/actionTypes";
import { COLLABORATOR_CLIENT } from "app/api/Endpoint";
import { toast } from "react-toastify";
import History from "@history";
import { getClientsList } from "../client/ClientActions";

export const createCollaboratorClient = (payload, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_START,
    });

    const url = COLLABORATOR_CLIENT;

    axiosInstance
      .post(url, payload)
      .then((res) => {
        if (res && res.data) {
          dispatch(
            getClientsList({
              searchText: "",
              page: 0,
              limit: 10,
              order: {},
            })
          );
          createCollaboratorClientSuccess(dispatch);
          toast.success(languageStrings["COLLABORATOR_ASSIGNED"]);
        } else {
          createCollaboratorClientFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        createCollaboratorClientFail(
          dispatch,
          languageStrings["CONNECTION_ERROR"]
        );
      });
  };
};
const createCollaboratorClientSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_SUCCESS,
  });
  History.push({
    pathname: "/clientsList",
  });
};
const createCollaboratorClientFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const deleteCollaboratorClient = (payload, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_START,
    });

    const url = COLLABORATOR_CLIENT;

    axiosInstance
      .delete(url, { data: { ...payload } })
      .then((res) => {
        if (res && res.data) {
          dispatch(
            getClientsList({
              searchText: "",
              page: 0,
              limit: 10,
              order: {},
            })
          );
          deleteCollaboratorClientSuccess(dispatch);
          toast.success(languageStrings["COLLABORATOR_DELETED"]);
        } else {
          deleteCollaboratorClientFail(
            dispatch,
            languageStrings["CONNECTION_ERROR"]
          );
        }
      })
      .catch((e) => {
        deleteCollaboratorClientFail(
          dispatch,
          languageStrings["CONNECTION_ERROR"]
        );
      });
  };
};
const deleteCollaboratorClientSuccess = (dispatch) => {
  dispatch({
    type: CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_SUCCESS,
  });
  History.push({
    pathname: "/clientsList",
  });
};
const deleteCollaboratorClientFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
