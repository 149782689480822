import { CopyMenuV2ActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  menuList: [],
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const CopyMenuV2Reducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case CopyMenuV2ActionTypes.GET_MENU_LIST_START: {
      return { ...state, loading: true };
    }
    case CopyMenuV2ActionTypes.GET_MENU_LIST_SUCCESS: {
      return {
        ...state,
        menuList: action.payload,
        loading: false,
      };
    }
    case CopyMenuV2ActionTypes.GET_MENU_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CopyMenuV2ActionTypes.COPY_MENU_V2_START: {
      return { ...state, loading: true };
    }
    case CopyMenuV2ActionTypes.COPY_MENU_V2_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CopyMenuV2ActionTypes.COPY_MENU_V2_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default CopyMenuV2Reducer;
