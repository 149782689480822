import { combineReducers } from "redux";
import auth from "app/store/auth/AuthReducer";
import client from "app/store/client/ClientReducer";
import order from "app/store/order/OrderReducer";

import analytics from "app/store/analytics/AnalyticsReducer";
import collaborator from "../collaborator/CollaboratorReducer";
import venues from "../venueList/VenueReducer";
import DublicarMenu from "../dublicarMenu/DublicarMenuReducer";
import TwilioMessages from "../TwilioMessages/TwilioMessagesReducer";
import PosReducer from "../pos/PosReducer";
import VenuePopupReducer from "../venuePopup/VenuePopupReducer";
import PaginationReducer from "../pagination/PaginationsReducer";
import rating from "../rating/RatingReducer";
import Staff from "../staff/StaffReducer";
import CollaboratorGroup from "../collaboratorGroup/CollaboratorGroupReducer";
import CollaboratorUser from "../collaboratorUser/CollaboratorUserReducer";
import CollaboratorClient from "../collaboratorClient/collaboratorClientReducer";
import menuV2 from "app/store/copyMenu/CopyMenuReducer";
import failedPayment from "app/store/failedPayments/FailedPaymentsActionsReducer";

const banzzuReducers = combineReducers({
  auth,
  client,
  order,
  analytics,
  collaborator,
  venues,
  DublicarMenu,
  TwilioMessages,
  PosReducer,
  VenuePopupReducer,
  PaginationReducer,
  rating,
  Staff,
  CollaboratorGroup,
  CollaboratorUser,
  CollaboratorClient,
  menuV2,
  failedPayment,
});

export default banzzuReducers;
