import _ from "@lodash";
import { orderStatuses } from "app/main/orders/components/OrdersStatus";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import { STORAGE_KEY, getStorageItem, getUserRole } from "./storageHelper";

export function formatImageUrl(imageUrl) {
  return imageUrl.replace(
    "http://server.banzzu.com:8003/",
    "https://server.banzzu.com:8009/"
  );
}

export const venueStatusCheck = (value) => {
  let venueStatus = "";

  if (value === 1) {
    venueStatus = "VENUE_STATUS.REAL";
  } else if (value === 0) {
    venueStatus = "VENUE_STATUS.TEST";
  } else if (value === 2) {
    venueStatus = "VENUE_STATUS.NOT_COMPUTE";
  } else if (value === 3) {
    venueStatus = "VENUE_STATUS.CLIENT_TESTING";
  }
  return venueStatus;
};
export const getTipAmount = (order = []) => {
  let totalTip = 0;
  for (let i = 0; i < order.length; i++) {
    const orderDetail = order[i];
    if (orderDetail && orderDetail.tip && orderDetail.tip.amount)
      totalTip += orderDetail.tip.amount;
  }

  return { totalTip: totalTip > 0 ? totalTip.toFixed(2) : 0 };
};
export const createNotificationData = (notifications = [], value = []) => {
  const selectedNotifications = [];
  notifications.map((item) => {
    if (item && item.id && value && value.includes(item.id))
      selectedNotifications.push(item);
  });
  return { selectedNotifications };
};
export const getUpdatedNotificationList = (value = []) => {
  const updatedList = [];
  value.map((item) => {
    if (item && item.id) updatedList.push(item.id);
  });
  return { updatedList };
};
export const removeNotifications = (id = "", selectedNotifications = []) => {
  const updatedList = [];
  selectedNotifications.map((item) => {
    if (item.id !== id) updatedList.push(item.id);
  });

  return { updatedList };
};
export const getLabelWith = (label = "", val = 6.8) => {
  let labelWith = 100;
  if (label) labelWith = label.length * val;

  return { labelWith };
};
export const getOrderStatus = (orderStatus = "1") => {
  const _status = _.find(orderStatuses, { id: Number(orderStatus) });
  return _status.name;
};
export const exportOrders = (orderList = [], languageStrings) => {
  if (!languageStrings) return null;

  const sortedOrderList = _.orderBy(orderList, ["createdAt"], ["asc"]);
  const data = sortedOrderList.map((ord) => {
    return {
      [languageStrings["GENERAL.REF"]]: ord.referenceNo,
      [languageStrings["VENUE_LIST.VENUE_NAME"]]: ord.venueTitle,
      [languageStrings["GENERAL.PRICE"]]: ord.price,
      [languageStrings["GENERAL.STATUS"]]:
        languageStrings[getOrderStatus(ord.orderStatus)],
      [languageStrings["DATE"]]: moment(ord.createdAt).format("DD/MM/YYYY"),
    };
  });

  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "orders-list.xlsx");
};
export const capitalizeFirstAlphabet = (value: string) => {
  const __ = `${value ? value : ""}`;
  return __.charAt(0).toUpperCase() + __.slice(1).toLowerCase();
};

export enum DASHBOARD_ROLES {
  TECHNICIAN = "TECHNICIAN",
  SALESPERSON = "SALESPERSON",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export enum COLLABORATOR_ROLES_ENUM {
  COLLABORATOR = "COLLABORATOR",
  ADMIN = "ADMIN",
}
export const COLLABORATOR_ROLES = ["COLLABORATOR", "ADMIN"];

export const isSuperAdmin = getUserRole() === DASHBOARD_ROLES.ADMINISTRATOR;
export const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
export const isTechnician = getUserRole() === DASHBOARD_ROLES.TECHNICIAN;
export const isAdmin = getUserRole() !== DASHBOARD_ROLES.SALESPERSON;
export const isCollaborator =
  getStorageItem(STORAGE_KEY.LOGIN_TYPE) ===
  COLLABORATOR_ROLES_ENUM.COLLABORATOR;
export const isCollaboratorAdmin =
  getStorageItem(STORAGE_KEY.LOGIN_TYPE) ===
    COLLABORATOR_ROLES_ENUM.COLLABORATOR &&
  getUserRole() === COLLABORATOR_ROLES_ENUM.ADMIN;
export const getRolesTitle = (role: string): string => {
  let title = "";
  if (
    DASHBOARD_ROLES.ADMINISTRATOR === role ||
    COLLABORATOR_ROLES_ENUM.ADMIN === role
  ) {
    title = "ADMIN";
  } else if (DASHBOARD_ROLES.SALESPERSON == role) {
    title = "SALES_PERSON";
  } else if (DASHBOARD_ROLES.TECHNICIAN === role) {
    title = "TECHNICIAN";
  } else if (COLLABORATOR_ROLES_ENUM.COLLABORATOR === role) {
    title = "COLLABORATOR";
  }
  return title;
};
export const sortByName = (list = []): any[] => {
  const updatedList = list;
  if (!list.length) return updatedList;
  updatedList.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return updatedList;
};

export const orderTypeValue = (orderType, hasVirtualBasket, autoServices) => {
  let value = orderType;
  if (hasVirtualBasket === false && autoServices === true) {
    value = "3";
  } else if (hasVirtualBasket === true && autoServices === false) {
    value = "4";
  } else {
    value = orderType;
  }
  return orderType;
};

export const dosePatternPresentInString = (str = "", pattern) => {
  // to check pattern or str found or str as empty string
  if (!Boolean(pattern) || !Boolean(str) || str === "") {
    return true;
  }
  return str.search(pattern) === -1 ? false : true;
};