import React from "react";
import { useEffect, useState } from "react";
import withReducer from "app/store/withReducer";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getPosInputs } from "app/store/pos/PosActions";
import { IntegrationDropdown } from "../common/IntegrationDropdown";
import { FrontrestMixAndMatchDropdown } from "../common/FrontrestMixAndMatchDropdown";
import { HosteltactilTarifDropdown } from "../common/HosteltactilTarifDropdown";
import reducer from "app/store/pos/PosReducer";
import { TextField, FormControlLabel, Checkbox, Typography, Switch } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

export const ClientIntegration = (props) => {
  const {
    clientId,
    setPosInputValue,
    clientDetail,
    handleCocacolaChange,
    handleTablesChange,
    integrationForm,
    setIntegrationForm
  } = props;
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  const dispatch = useDispatch();
  const currentIntegration = useSelector((state) => state.banzzu.PosReducer);
  const initialData = {};
  const { form, setForm } = useForm(initialData);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectCocacola, setSelectCocacola] = useState(false);
  const { languageStrings } = useLanguageHelper();
  const posName =
    clientDetail && clientDetail.integrationType
      ? clientDetail.integrationType
      : "none";
  useEffect(() => {
    dispatch(getPosInputs(posName, clientId));
  }, [dispatch, clientId, posName]);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      integrationType: currentIntegration.integrationType,
      currentInputs: currentIntegration.currentInputs,

    }));
  }, [currentIntegration, clientId]);
  useEffect(() => {
    const _posType =
      form && form.integrationType ? form.integrationType : "none";
    const form_ = form && form.currentInputs ? form.currentInputs : {};
    const _form = _posType === "none" ? {} : { ...form_ };
    setSelectedIntegration(_posType);
    setPosInputValue({
      posType: _posType,
      inputs: _form,
    });
  }, [form]);
  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      isCocacola: clientDetail.isCocacola,
      cocacolaTerminalId: clientDetail.cocacolaTerminalId,
      checkTables: clientDetail.checkTables,

    }));
    setSelectCocacola(clientDetail.isCocacola);
  }, [clientDetail, clientId]);
  const onChange = (posType) => {
    setSelectedIntegration(posType);
    dispatch(getPosInputs(posType, clientId));
    setForm((prevForm) => ({
      ...prevForm,
      integrationType: posType,
      currentInputs: currentIntegration.currentInputs,
    }));
  };

  const handleInputChange = (inputName, e) => {
    const _form = { ...form.currentInputs };
    _form[`${inputName}`] = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      currentInputs: _form,
      integrationType: selectedIntegration,
    }));
  };

  const handleCocacolaCheck = (e) => {
    const isChecked = e.target.checked;
    setSelectCocacola(!selectCocacola);
    setForm((prevForm) => ({
      ...prevForm,
      isCocacola: isChecked,
    }));
    handleCocacolaChange("isCocacola", isChecked);
  };
  const onChangeTablesCheck = (e) => {
    const isChecked = e.target.checked;
    setForm((prevForm) => ({
      ...prevForm,
      checkTables: isChecked,
    }));
    handleTablesChange("checkTables", isChecked);
  };

  const handleCocacolaInputChange = (e) => {
    const terminalId = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      cocacolaTerminalId: terminalId,
    }));
    handleCocacolaChange("cocacolaTerminalId", terminalId);
  };

  const handleRevoExtraTarif = (event) => {
    const { checked } = event.target;
    setIntegrationForm((prevForm) => ({
      ...prevForm,
      revoExtraTraifEnabled: checked,
    }));
  };

  return (
    <div>
      <IntegrationDropdown
        onChange={onChange}
        currentIntegration={currentIntegration}
      />
      {form &&
        form.currentInputs &&
        form.currentInputs.length !== 0 &&
        Object.keys(form.currentInputs).map((name) => {
          if (name === "tarifa") {
            return (
              <>
                <HosteltactilTarifDropdown
                  name={name}
                  onChange={handleInputChange}
                  value={form.currentInputs.tarifa}
                />
              </>
            );
          } else if (name === "mixAndMatch") {
            return (
              <>
                <FrontrestMixAndMatchDropdown
                  name={name}
                  onChange={handleInputChange}
                  value={form.currentInputs.mixAndMatch}
                />
              </>
            );
          } else {
            return (
              <TextField
                key={name}
                className="mt-8 mb-16 mr-8"
                label={name}
                id="client-integration"
                name={name}
                value={form.currentInputs[name]}
                onChange={(e) => handleInputChange(name, e)}
                variant="outlined"
                multiline
                fullWidth
                disabled={isSalesPerson}
              ></TextField>
            );
          }
        })}
      {
        <FormControlLabel
          onChange={(e) => handleCocacolaCheck(e)}
          checked={form.isCocacola ? form.isCocacola : ""}
          control={<Checkbox></Checkbox>}
          label={languageStrings["COCA_COLA_MENU"]}
          disabled={isSalesPerson}
        />
      }
      {selectCocacola && (
        <TextField
          className="mt-8 mb-16 mr-8"
          label={"cocacolaTerminalId"}
          id="cocacolaTerminalId"
          name={"cocacolaTerminalId"}
          value={form.cocacolaTerminalId}
          onChange={(e) => handleCocacolaInputChange(e)}
          variant="outlined"
          multiline
          fullWidth
        ></TextField>
      )}

      <FormControlLabel
        onChange={(e) => onChangeTablesCheck(e)}
        checked={form.checkTables ? form.checkTables : ""}
        control={<Checkbox />}
        label={languageStrings["CHECK_TABLES"]}
        disabled={posName === "none"}
      />

      {clientDetail.settings && clientDetail.settings.integrationType === "revo" && form.integrationType === "revo" && (
        <FormControlLabel
          onChange={handleRevoExtraTarif}
          checked={integrationForm.revoExtraTraifEnabled}
          control={<Checkbox />}
          label={languageStrings["EXTRATARRIF"]}
          disabled={posName === "none"}
        />
      )}
    </div>
  );
};

export default withReducer("PosReducer", reducer)(ClientIntegration);
