import React from "react";
import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

export const MenuTypeDropdown = ({ value, onChange }) => {
  const { languageStrings } = useLanguageHelper();
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center `}
      style={{ marginTop: -7 }}
    >
      <InputLabel className={`mt-14`} htmlFor="outlined-venue-native-simple">
        {languageStrings["MENU_TYPE"]}
      </InputLabel>
      <Select
        disabled={isSalesPerson}
        value={value}
        native
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{ paddingBottom: 1 }}
        input={
          <OutlinedInput
            name="Menu Type"
            labelWidth={100}
            id="outlined-venue-native-simple"
          />
        }
      >
        <option value={"1"}>{languageStrings["URL_MENU"]}</option>
        <option value={"2"}>{languageStrings["CHECK_MENU"]}</option>
      </Select>
    </FormControl>
  );
};
