import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { MainConfig } from "app/MainConfig";
import { LoginPageConfig } from "app/main/login/LoginPageConfig";
import { RegisterPageConfig } from "app/main/register/RegisterPageConfig";
import { ClientListConfig } from "app/main/client/ClientListConfig";
import { OrderConfig } from "app/main/orders/OrderConfig";
import { clientSettings } from "config";
import { CollaboratorListConfig } from "app/main/collaborator/CollaboratorListConfig";
import { StaffListConfig } from "app/main/staff/StaffListConfig";
import { ChangePasswordConfig } from "app/main/changePassword/ChangePasswordConfig";
import { DuplicarMenuConfig } from "app/main/duplicarMenu/DuplicarMenuConfig";
import { RatingConfig } from "app/main/ratings/RatingConfig";
import { FailedPaymentConfig } from "app/main/failedPayment/failedPaymentConfig";
import {
  DASHBOARD_ROLES,
  isAdmin,
  isCollaborator,
} from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

const isSuperAdmin = getUserRole() === DASHBOARD_ROLES.ADMINISTRATOR;
const isTechnician = getUserRole() === DASHBOARD_ROLES.TECHNICIAN;

const routeConfigs = [
  LoginPageConfig,
  ClientListConfig,
  OrderConfig,
  RatingConfig,
  ChangePasswordConfig,
];
if (clientSettings.showRegistration) routeConfigs.push(RegisterPageConfig);
if (clientSettings.showHomeTab) routeConfigs.push(MainConfig);
if (isAdmin) routeConfigs.push(CollaboratorListConfig);
if (!isTechnician && isSuperAdmin)
  routeConfigs.push(
    StaffListConfig,
    DuplicarMenuConfig,
    CollaboratorListConfig,
    FailedPaymentConfig
  );
else if (isTechnician) routeConfigs.push(DuplicarMenuConfig);
else if (isCollaborator)
  routeConfigs.push(StaffListConfig, CollaboratorListConfig);

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/login" />,
  },
];
export default routes;
