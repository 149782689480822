import { CollaboratorUserActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  collaboratorUsers: [],
  collaboratorUserDetail: {},
  searchText: "",
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const CollaboratorUserReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case CollaboratorUserActionTypes.COLLABORATOR_USER_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_START: {
      return { ...state, loading: true };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_SUCCESS: {
      return {
        ...state,
        collaboratorUsers: action.payload,
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_START: {
      return { ...state, loading: true };
    }
    case CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.CREATE_COLLABORATOR_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_START: {
      return { ...state, loading: true };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        collaboratorUserDetail: {
          ...action.payload,
          group: action.payload.collaboratorGroupId || "",
        },
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.COLLABORATOR_USER_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_START: {
      return { ...state, loading: true };
    }
    case CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_SUCCESS: {
      return {
        ...state,
        collaboratorUserDetail: {},
        loading: false,
      };
    }
    case CollaboratorUserActionTypes.EDIT_COLLABORATOR_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default CollaboratorUserReducer;
