import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

import {
  Button,
  Popover,
  MenuItem,
  ListItemIcon,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export interface headerProps {
  id?: string;
  title?: string;
}
export interface ExportFilesProps {
  header: Array<headerProps>;
  fileName: String;
  showXlsx?: boolean;
  onXLSXClick?: () => {};
}

export const ExportFiles = (props: ExportFilesProps) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <div>
      <Button
        onClick={props.onXLSXClick}
        className={"whitespace-no-wrap"}
        variant="contained"
      >
        <span> {languageStrings["GENERAL.EXPORT_XLS"]}</span>
      </Button>
    </div>
  );
};
