const clientSettings = {
  showRegistration: false,
  language: "es",
  showSubscriptionsDropdown: false,
  showGoals: false,
  showReferido: false,
  showSubscription: false,
  showAnalytics: false,
  showOrderTypes: false,
  metaTitle: "Check",
  metaDesc: "Tu carta digital para pedidos desde la mesa",
  showUserDetails: false,
  showHomeTab: false,
  showCollaborator: true,

  showReferralsGoals: false,
  showReferralsCreatedManually: false,
  showReferralsSubscription: false,
  showReferralsAccessClient: true,
  showVisitWeb: false,
};
export { clientSettings };
