import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import VenueListHeader from "../common/List/VenueListHeader";
import InputDropDown from "./components/InputDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getClientsList } from "app/store/client/ClientActions";
import { getMenuV2List, copyMenuV2 } from "app/store/copyMenu/CopyMenuActions";
import { Button } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";

export const DuplicarMenu = () => {
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.banzzu.client.clientsList);
  const menuList = useSelector((state) => state.banzzu.menuV2.menuList);
  const loading = useSelector((state) => state.banzzu.menuV2.loading);
  const loading2 = useSelector((state) => state.banzzu.client.loading);
  const { languageStrings } = useLanguageHelper();
  useEffect(() => {
    dispatch(
      getClientsList({
        page: 0,
        limit: 10,
        searchText: "",
        order: {},
        isClientFilter: true,
      })
    );
  }, [dispatch]);

  const [selectedClient, setSelectedClient] = useState("");
  const [selectedsecondClient, setSelectedSecondClient] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const onClientSelected = (event) => {
    setSelectedClient(event.target.value);
    setSelectedMenu("");
    dispatch(getMenuV2List(event.target.value));
  };
  const onMenuSelected = (event) => {
    setSelectedMenu(event.target.value);
  };

  const sortedClientList = () => {
    const sortedList = clientList.sort(function (a, b) {
      let x = a.venueName.toLowerCase();
      let y = b.venueName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    return sortedList;
  };
  const sortedMenuList = () => {
    const sortedList = menuList.sort(function (a, b) {
      let x = a.title.toLowerCase();
      let y = b.title.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    return sortedList;
  };
  return (
    <LoadingOverlay
      active={loading || loading2}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: false ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <div
        style={{
          flex: 1,
          filter: false ? "blur(3px)" : "none",
          marginTop: 30,
        }}
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-128 h-128 sm:h-136 sm:min-h-88",
          }}
          header={
            <div
              className={"flex w-full items-center flex-row justify-between"}
            >
              <VenueListHeader
                title={`${languageStrings["DUPLICATE_BTN"]} ${languageStrings["SIDEBAR_MENUE.MENUE_TITLE"]}`}
                searchType={"venues"}
              />
              <Button
                onClick={() => {
                  dispatch(
                    copyMenuV2(
                      selectedMenu,
                      selectedClient,
                      selectedsecondClient,
                      languageStrings
                    )
                  );
                }}
                disabled={selectedsecondClient && selectedClient ? false : true}
                className={"whitespace-no-wrap"}
                variant="contained"
              >
                <span>{languageStrings["DUPLICATE_BTN"]}</span>
              </Button>
            </div>
          }
          content={
            <div
              className={
                "mt-60 w-full flex flex-col justify-between mx-10 md:flex-row"
              }
            >
              {clientList && clientList.length ? (
                <InputDropDown
                  label={languageStrings["ORIGINAL_CLIENT"]}
                  handleChange={onClientSelected}
                  clientsList={sortedClientList()}
                  selectedValue={selectedClient}
                  keyPropFn={(option) => option._id}
                  valuePropFn={(option) => option.venueName}
                />
              ) : null}
              <InputDropDown
                label={languageStrings["MENU"]}
                handleChange={onMenuSelected}
                clientsList={sortedMenuList()}
                selectedValue={selectedMenu}
                keyPropFn={(option) => option._id}
                valuePropFn={(option) => option.title}
              />

              {clientList && clientList.length ? (
                <InputDropDown
                  label={languageStrings["DESTINATION_CLIENT"]}
                  handleChange={(event) => {
                    setSelectedSecondClient(event.target.value);
                  }}
                  clientsList={sortedClientList()}
                  selectedValue={selectedsecondClient}
                  keyPropFn={(option) => option._id}
                  valuePropFn={(option) => option.venueName}
                />
              ) : null}
            </div>
          }
          innerScroll
        />
      </div>
    </LoadingOverlay>
  );
};
