import React from 'react'
import { TableCell } from '@material-ui/core'

import { useLanguageHelper } from 'app/helpers/LanguageHelper'

export default function CreatedTypeCell({ createdByCompany, name }) {
  const { languageStrings } = useLanguageHelper()
  const text = createdByCompany ? name : 'Banzzu'
  return <TableCell>{text}</TableCell>
}
