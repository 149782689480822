import React, { useState } from "react";
import {
    TableCell,
    IconButton,
    Icon,
    Menu,
    MenuList,
    MenuItem,
    ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    actionsButtonWrapper: {
    }
}));
const ActionsCell = ({ actions, n }) => {
    const classes = useStyles();
    const [selectedProductsMenu, setSelectedProductsMenu] = useState(null);
    function openSelectedProductsMenu(event) {
        setSelectedProductsMenu(event.currentTarget);
    }

    function closeSelectedProductsMenu() {
        setSelectedProductsMenu(null);
    }
    return (
        <TableCell>
            <div
                className={clsx(
                    classes.actionsButtonWrapper
                )}
            >
                <IconButton
                    aria-owns={
                        selectedProductsMenu ? "selectedProductsMenu" : null
                    }
                    aria-haspopup="true"
                    onClick={openSelectedProductsMenu}
                >
                    <Icon>more_horiz</Icon>
                </IconButton>
                <Menu
                    id="selectedProductsMenu"
                    anchorEl={selectedProductsMenu}
                    open={Boolean(selectedProductsMenu)}
                    onClose={() => {
                        closeSelectedProductsMenu(false);
                    }}
                >
                    <MenuList>
                        {actions.map(action => {
                            return (
                                <MenuItem
                                >

                                    <ListItemText onClick={() => { action.onclick(n) }}>
                                        {action.text}
                                    </ListItemText>
                                </MenuItem>)
                        })
                        }
                    </MenuList>
                </Menu>
            </div>
        </TableCell>
    );
};

export default ActionsCell;
