/**
 * 0 = today
 * 1 = tomorrow
 * 2 = today, tomorrow, and next date from tomorrow
 */
export const DEFAULT_NUMBER_OF_FUTURE_DAYS = 1;
export const MAX_NUMBER_FUTURE_DAYS = 14;
export const MIN_NUMBER_FUTURE_DAYS = 0;

export const SMS_NOTIFICATION_PATTERNS = {
  VENUE_NAME: "[VENUE_NAME]",
  ID: "[ID]",
};
export const DEFAULT_SMS_NOTIFICATION_MESSAGES = {
  SPANISH: {
    NOTIFY_ORDER_MESSAGE: `NOTIFICACIÓN PEDIDO "${SMS_NOTIFICATION_PATTERNS.ID}" - ${SMS_NOTIFICATION_PATTERNS.VENUE_NAME}. Su pedido se está preparando. Le notificaremos cuando esté listo para poder pasar a recogerlo.`,
    PARTIAL_DELIVERY_MESSAGE: `NOTIFICACIÓN PEDIDO "${SMS_NOTIFICATION_PATTERNS.ID}" - ${SMS_NOTIFICATION_PATTERNS.VENUE_NAME}. Parte de su pedido está listo y puede pasar a recogerlo.`,
    FULL_DELIVERY_MESSAGE: `NOTIFICACIÓN PEDIDO "${SMS_NOTIFICATION_PATTERNS.ID}" - ${SMS_NOTIFICATION_PATTERNS.VENUE_NAME}. Su pedido ya está listo y puede pasar a recogerlo.`,
  },
};
