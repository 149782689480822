const navigationConfig = [
  {
    id: "Menu",
    title: "SIDEBAR_MENUE.TITLE",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "client",
        title: "SIDEBAR_MENUE.CLIENT",
        type: "collapse",
        icon: "people",
        url: "/clientsList",
        group: "client",
        children: [
          {
            id: "Client",
            title: "SIDEBAR_MENUE.CLIENT_LIST",
            type: "item",
            icon: "people",
            url: "/clientsList",
            group: "client",
          },
          {
            id: "addClient",
            title: "SIDEBAR_MENUE.ADD_CLIENT",
            type: "item",
            icon: "groupAdd",
            url: "/add-client",
            group: "create_client",
          },
        ],
      },
      {
        id: "staff",
        title: "STAFF",
        type: "collapse",
        icon: "people",
        url: "/staff-list",
        group: "staff",
        children: [
          {
            id: "staff",
            title: "STAFF.LIST",
            type: "item",
            icon: "people",
            url: "/staff-list",
            group: "staff",
          },
          {
            id: "addStaff",
            title: "STAFF.CREATE",
            type: "item",
            icon: "groupAdd",
            url: "/add-staff/new",
            group: "staff",
          },
        ],
      },
      {
        id: "collaborator",
        title: "COLLABORATOR",
        type: "collapse",
        icon: "people",
        url: "/collaboratorsList",
        group: "collaborator",
        children: [
          {
            id: "Collaborator",
            title: "COLLABORATOR.LIST",
            type: "item",
            icon: "people",
            url: "/collaboratorsList",
            group: "collaborator",
          },
          {
            id: "CollaboratorGroup",
            title: "COLLABORATOR.GROUP",
            type: "item",
            icon: "people",
            url: "/collaborator-group",
            group: "collaborator-group",
          },
          {
            id: "AddCollaborator",
            title: "COLLABORATOR.CREATE",
            type: "item",
            icon: "groupAdd",
            url: "/add-collaborator/new",
            group: "add-collaborator",
          },
        ],
      },
      {
        id: "OrdersList",
        title: "SIDEBAR_MENUE.ORDERS",
        type: "item",
        icon: "shopping_cart",
        url: "/orders",
        group: "orders",
      },
      {
        id: "FailedPayments",
        title: "SIDEBAR_MENUE.FAILED_PAYMENTS",
        type: "item",
        icon: "shopping_cart",
        url: "/failed_payments",
        group: "failed_payments",
      },
      {
        id: "DuplicarMenu",
        title: "DUPLICAR_MENU",
        type: "item",
        icon: "shopping_cart",
        url: "/DuplicarMenu",
        group: "menu",
      },
      {
        id: "RATINGS",
        title: "SIDEBAR_MENUE.RATINGS",
        type: "item",
        icon: "grade",
        group: "rating",
        url: "/rating",
      },
    ],
  },
];

export default navigationConfig;
