import React from "react";
import { TableCell } from "@material-ui/core";

const AccessLevelCell = ({ n }) => {
  const accessLevels = [
    {
      id: "1",
      name: "Gratis",
      color: "#FFF",
      bgColor: "red",
    },
    {
      id: "2",
      name: "Advanced",
      color: "#000",
      bgColor: "yellow",
    },
    {
      id: "3",
      name: "Premium",
      color: "#FFF",
      bgColor: "green",
    },
  ];

  const accessLevel = accessLevels.find((l) => l.id == n);
  return (
    <TableCell>
      {n && (
        <div
          style={{
            display: "inline",
            padding: " 0.4rem",
            backgroundColor: accessLevel.bgColor,
            color: accessLevel.color,
          }}
          className="text-12 rounded truncate"
        >
          {accessLevel.name}
        </div>
      )}
    </TableCell>
  );
};

export default AccessLevelCell;
