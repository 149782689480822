import React, { useCallback, useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import ListTablePaginated from "../common/List/ListTablePaginated";
import {
  getFailedPaymentList,
  paymentRefund,
  setFailedPaymentSearchText,
} from "app/store/failedPayments/FailedPaymentsActions";
import _ from "lodash";
import OrderFilter from "../orders/components/OrderFilter";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";

const _start = new Date();
_start.setMonth(_start.getMonth() - 1);

const FailedPaymentList = () => {
  const { searchText, failedPayments, loading, totalFailedPayments } =
    useSelector(({ banzzu }) => banzzu.failedPayment);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    const params = {
      limit: 10,
      page: 0,
    };
    dispatch(getFailedPaymentList(params));
  }, []);

  const list = failedPayments && failedPayments.length ? failedPayments : [];
  const debounceSearch = useCallback(
    _.debounce((searchText, rowsPerPage, pageNo, endDate, startDate) => {
      const params = {
        limit: rowsPerPage,
        page: pageNo,
        searchText,
        initialDate: startDate,
        finalDate: endDate,
      };
      dispatch(getFailedPaymentList(params));
    }, 600),
    [dispatch, rowsPerPage, pageNo]
  );

  const handleClick = (item) => {};
  const { languageStrings } = useLanguageHelper();
  const setSearchText = (ev) => {
    const _searchText =
      ev && ev.target && ev.target.value ? ev.target.value : "";
    dispatch(setFailedPaymentSearchText(ev));
    debounceSearch(_searchText, rowsPerPage, pageNo, endDate, startDate);
  };
  const handleRowsPerPageChange = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(rowPerPage);
    const params = {
      limit: rowsPerPage,
      page: pageNo,
      initialDate: startDate,
      finalDate: endDate,
      searchText,
    };
    dispatch(getFailedPaymentList(params));
  };
  const handlePageChange = (event, page) => {
    setPageNo(page);
    const params = {
      limit: rowsPerPage,
      page: pageNo,
      initialDate: startDate,
      finalDate: endDate,
      searchText,
    };
    dispatch(getFailedPaymentList(params));
  };
  const onFilterByDate = () => {
    setShowModal(true);
  };
  const onRefund = (data, event) => {
    event.stopPropagation();
    dispatch(
      Actions.openDialog({
        children: (
          <AlertDialog
            onSuccess={() => {
              const params = {
                limit: rowsPerPage,
                page: pageNo,
                searchText,
                initialDate: startDate,
                finalDate: endDate,
              };
              dispatch(
                paymentRefund({
                  _id: data._id,
                  params,
                  successMessage: languageStrings["REFOUND_SUCCESS"],
                })
              );
            }}
            title={languageStrings["STRIPE_REMOVE_CONFIRMATION"]}
            message={languageStrings["STRIPE_REMOVE_CONFIRMATION_MESSAGE"]}
          />
        ),
      })
    );
  };
  const headerRows = {
    data: [
      {
        id: "paymentIntentId",
        align: "left",
        disablePadding: true,
        label: languageStrings["PAYMENT_ID"],
        sort: true,
        type: "text",
      },
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE"],
        sort: true,
        type: "time",
      },

      {
        id: "amount",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "text",
      },
      {
        id: [""],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ACTIONS"],
        actions: {
          text: languageStrings["REFOUND"],
          onclick: onRefund,
        },
        sort: true,
        type: "singleBtn",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
    searchBy: ["paymentIntentId"],
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  // date={getDateSelectedtext()}
                  title={languageStrings["SIDEBAR_MENUE.FAILED_PAYMENTS"]}
                  setSearchText={setSearchText}
                  searchType={"failedPayment"}
                  CustomFilterButtonPress={onFilterByDate}
                  buttonText={languageStrings["FILTER_BY_DATE"]}
                />
              </div>
            }
            content={
              <ListTablePaginated
                handleDelete={() => {}}
                handleClick={handleClick}
                headerRows={headerRows}
                data={list}
                totalData={totalFailedPayments}
                order={{}}
                handleRequestSort={() => {}}
                searchText={searchText}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <OrderFilter
        onCloseModal={() => {
          setShowModal(false);
        }}
        onFilter={(startDate, endDate) => {
          const params = {
            limit: rowsPerPage,
            page: pageNo,
            searchText,
            initialDate: startDate,
            finalDate: endDate,
          };
          setStartDate(startDate);
          setEndDate(endDate);
          dispatch(getFailedPaymentList(params));
        }}
        btnText={languageStrings["FILTER_BY_DATE"]}
        open={showModal}
      />
    </>
  );
};

export default FailedPaymentList;
