import { AnalyticsActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: AnalyticsState = {
  analytics: [],
  loading: false,
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
  searchText: string;
}

const AnalyticsReducer = (
  state: AnalyticsState = INITIAL_STATE,
  action: Action
): AnalyticsState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_ANALYTICS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case AnalyticsActionTypes.GET_ANALYTICS_LIST_START:
      return updateObject(state, { loading: true, analytics: [] });

    case AnalyticsActionTypes.GET_ANALYTICS_LIST_SUCCESS:
      return updateObject(state, {
        analytics: action.payload.data,
        loading: false,
      });
    case AnalyticsActionTypes.GET_ANALYTICS_LIST_FAIL:
      return updateObject(state, { loading: false });

    default: {
      return state;
    }
  }
};

export default AnalyticsReducer;
