import { SMS_NOTIFICATION_PATTERNS } from "app/helpers/constants";

export default {
  "HOME_PAGE.RESTAURANT": "Restaurante",
  "HOME_PAGE.YOUR_CODE": "Tu código QR",

  "ERRORS.IMAGE_IS_REQUIRED": "Imagen es obligatoria",
  "ERRORS.FIELD_IS_REQUIRED": "Field Required",
  "ERRORS.INVALID_EMAIL": "Email incorrecto",
  "ERRORS.INVALID_PHONE": "Teléfono incorrecto",
  "ERRORS.INVALID_URL": "Url incorrecto",
  "ERRORS.INVALID_LOGIN": "Login incorrecto. Por favor intente de nuevo",
  "ERRORS.MINIMUM_10_CHAR": "Tamaño mínimo 10 caracteres",
  "ERRORS.MINIMUM_3_CHAR": "Tamaño mínimo 3 caracteres",
  "ERRORS.MINIMUM_1_CHAR": "Tamaño mínimo 1 caracter",
  "ERRORS.MINIMUM_4_CHAR": "Tamaño mínimo 4 caracteres",
  "ERRORS.MINIMUM_6_CHAR": "Tamaño mínimo 6 caracteres",
  "ERRORS.EMAIL_TAKEN": "El email introducido ya existe",
  "ERRORS.MUST_BE_NUMBER": "Debe ser un número",

  "GENERAL.CITY": "Ciudad",
  "GENERAL.CREATE": "Crear",
  "GENERAL.ADD_BUTTON": "Añadir",
  "GENERAL.BASIC_INFO": "Info básica",
  "GENERAL.CANCEL_BUTTON": "Cancelar",
  "GENERAL.CLIENT": "Cliente",
  "GENERAL.CONFIRM_BUTTON": "Confirmar",
  "GENERAL.CONTACT_INFO": "Información de Contacto",
  "GENERAL.DATE": "Fecha",
  "GENERAL.DELETE": "Eliminar",
  "GENERAL.DENY_BUTTON": "Cancelar",
  "GENERAL.DESCRIPTION": "Descripción",
  "GENERAL.DETAIL": "Detalle",
  "GENERAL.EMAIL": "Email",
  "GENERAL.IMAGE": "Imagen",
  "GENERAL.NAME": "Nombre",

  "GENERAL.PASSWORD": "Contraseña",
  "GENERAL.PHONE": "Teléfono",
  "GENERAL.POINTS": "Puntos",
  "GENERAL.POSTCODE": "Código Postal",
  "GENERAL.PRICE": "Importe",
  "GENERAL.ROWS_PER_PAGE": "Registros por página",
  "GENERAL.SAVE": "Guardar",
  "GENERAL.SAVE_PREVIEW": "Guardar y previsualizar",
  "GENERAL.SAVE_BUTTON": "Guardar",
  "GENERAL.SEARCH": "Buscar",
  "GENERAL.SEND": "Enviar",
  "GENERAL.TIME": "Hora",
  "GENERAL.TITLE": "Título",
  "GENERAL.TYPE": "Tipo",
  "GENERAL.USER_LIST": "Lista usuarios",
  "GENERAL.VENUE": "Local",
  "GENERAL.STATUS": "Estado",
  "GENERAL.WEBSITE": "Página web",
  "GENERAL.CATEGORY": "Categoría",
  "GENERAL.ITEMS": "Items",
  "GENERAL.ACTIONS": "Acciones",
  "GENERAL.REF": "Ref #",
  "GENERAL.ALERT_TITLE": "Confirmación",
  "GENERAL.LATITUDE": "Latitud",
  "GENERAL.LONGITUDE": "Longitud",
  "HOME_PAGE.PENDING_ORDERS": "Pedidos Pendientes",
  "GENERAL.ALERT_DESC":
    "¿Estás seguro quieres retroceder sin guardar los cambios?",
  "GENERAL.REMOVE": "Eliminar",
  "GENERAL.PLATFORM": "Plataforma",
  "GENERAL.TO": "Hasta",
  "GENERAL.YES": "Si",
  "GENERAL.NO": "No",
  "GENERAL.VISIT_WEB": "Visitar Web",
  "GENERAL.ACCESS_CLIENT": "Cliente de acceso",
  "GENERAL.YOUR_CODE": "Tu codigo es",
  "GENERAL.COPY_CODE": "Copiar codigo",
  "GENERAL.ORDER_TYPE": "Tipo Pedido",
  "GENERAL.FIRST_NAME": "Nombre",
  "GENERAL.LAST_NAME": "Apellido",
  "GENERAL.RESTAURANT_NAME": "Nombre del restaurante",
  "GENERAL.RESTAURANT_PHONE": "Telefono del restaurante",
  "GENERAL.POST_CODE": "Codigo postal",
  "GENERAL.ADDRESS": "Direccion",
  "GENERAL.COMMENTS": "Comentarios",
  "GENERAL.EXPORT": "Exportar",
  "GENERAL.EXPORT_CSV": "Descarga csv",
  "GENERAL.EXPORT_XLS": "Descarga xls",
  "GENERAL.COMMISSIONS": "commissions",
  "GENERAL.RESTAURANT": "Restaurante",
  "GENERAL.TABLE": "Mesa",
  "GENERAL.DATE_TIME": "Fecha y hora",
  "GENERAL.ROLE": "Role",

  "LOGIN_PAGE.LOGIN_BUTTON": "LOGIN",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "LOGIN EN TU CUENTA",
  "LOGIN_PAGE.REGISTER_LINK": "¿No tienes una cuenta? Regístrate",
  "LOGIN_PAGE.EMAIL": "Email",
  "LOGIN_PAGE.PASSWORD": "Contraseña",

  "SIDEBAR_MENUE.HOME": "Inicio",
  "SIDEBAR_MENUE.MENUE_LIST": "Lista Menu",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menu",
  "SIDEBAR_MENUE.TITLE": "Menú",
  "SIDEBAR_MENUE.TRANSACTION": "Lista Transacciones",
  "SIDEBAR_MENUE.USER_LIST": "Usuarios",
  "SIDEBAR_MENUE.CLIENT_LIST": "Lista de clientes",
  "SIDEBAR_MENUE.CLIENT": "Cliente",
  "SIDEBAR_MENUE.ADD_CLIENT": "Crear cliente",
  "SIDEBAR_MENUE.REFERRALS": "Referidos",
  "SIDEBAR_MENUE.ORDERS": "Pedidos",
  "SIDEBAR_MENUE.FAILED_PAYMENTS": "Pagos Fallidos",
  "SIDEBAR_MENUE.ORDERS_LIST": "Lista Pedidos",
  "SIDEBAR_MENUE.ANALYTICS": "Analíticas",

  "USER_LIST_PAGE.TITLE": "Lista Usuarios",
  "USER_DETAIL.BACK_LIST": "Lista de Usuarios",

  "REGISTER_PAGE.TITLE": "Empezar nunca había sido tan fácil",
  "REGISTER_PAGE.FIRST_NAME": "Nombre",
  "REGISTER_PAGE.LAST_NAME": "Apellido",
  "REGISTER_PAGE.EMAIL": "Email",
  "REGISTER_PAGE.TELEPHONE": "Teléfono",
  "REGISTER_PAGE.PASSWORD": "Contraseña",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Términos y Condiciones",
  "REGISTER_PAGE.PRIVACY_POLICY": "Política de privacidad",
  "REGISTER_PAGE.ACCEPT": "Aceptar",
  "REGISTER_PAGE.BUTTON_TEXT": "Continuar",
  "REGISTER_PAGE.LOGIN_LINK": "¿Ya tienes una cuenta?",
  "REGISTER_PAGE.LOGIN": "Login",
  "REGISTER_PAGE.NEW_IN_BANZZU": "¿Nuevo en Banzzu?",
  "REGISTER_PAGE.SUB_TITLE_1": "Conquista el universo digital de tu local ",
  "REGISTER_PAGE.SUB_TITLE_2": "y descubre todas tus posibilidades",

  "CLIENT.REGISTRATION_DATE": "Fecha Registro",
  "CLIENT.SUBSCRIPTION": "Subscripción",
  "CLIENT.CLIENT_LIST": "Lista de clientes",
  "CLIENT.CLIENT_DETAILS": "Cliente Detalle",
  "CLIENT.GOALS": "Goals",
  "CLIENT.ACCESS_LEVEL_PLACEHOLDER": "Nivel de acceso",
  "CLIENT.All": "All",
  "CLIENT.REFERRED_BY": "Referido  por",
  "CLIENT.ACTIONS": "Comportamiento",
  "CLIENT.LAST_INTERACTION": "Última interacción",
  "CLIENT.ADD_CLIENT": "Crear cliente",
  "CLIENT.CREATED_TYPE": "Creado Manual",

  "ORDER_LIST_PAGE.TITLE": "Pedidos",
  "ORDER_LIST_PAGE.ALL_STATUS": "Todos los estados",
  "ORDER_LIST_PAGE.ALL_TYPES": "Todos los tipos",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Selecciona estado",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Selecciona tipo",
  "ORDER_PAGE.CUSTOMER": "Customer",
  "ORDER_PAGE.EMAIL": "Email",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Nombre",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Aceptar Pedidos",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Cancelar",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "Listo para Recoger",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "Pedido Recogido",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Preparando",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Comenzar Envío",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Completada",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Pedido",
  "ORDER_PAGE.ORDERS": "Pedidos",
  "ORDER_PAGE.ORDER_DETAIL": "Detalle Pedido",
  "ORDER_PAGE.PHONE": "Teléfono",
  "ORDER_PAGE.PRODUCTS": "Productos",
  "ORDER_PAGE.QUANTITY": "Cantidad",
  "ORDER_PAGE.DELIVERY": "Dirección Envío",
  "ORDER_PAGE.COMMENTS": "Comentarios",

  "ORDER_TYPE.DELIVERY": "A Domicilio",
  "ORDER_TYPE.COLLECT": "Recogida Local",
  "ORDER_TYPE.FUTURE_ORDER": "Pedido Futuro",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Pedir desde la mesa",

  "ORDER_STATUS.ORDER_NUMBER": "Referencia Pedido",
  "ORDER_STATUS.UPDATED_AT": "Fecha Creación",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Pagar en Efectivo",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Pago Aceptado",
  "ORDER_STATUS.ORDER_ACCEPTED": "Pedido Aceptado",
  "ORDER_STATUS.PREPARING": "Preparando Pedido",
  "ORDER_STATUS.ON_DELIVERY": "En Envío",
  "ORDER_STATUS.DELIVERED": "Completado",
  "ORDER_STATUS.CANCELED": "Cancelado",
  "ORDER_STATUS.COLLECT": "Listo para Recoger",
  "ORDER_STATUS.COLLECTED": "Pedido Recogido",
  "ORDER_STATUS.ORDER_TIME": "Pedido Hora",
  "ORDER_STATUS.ORDER_TABLE": "Pedido Mesa",

  "REFERRAL.REFERRAL_LIST": "Lista de Referidos",
  "HELP_MENU.CHANGE_PASSWORD": "Cambiar contraseña",
  "HELP_MENU.LOGOUT": "Salir",

  "ANALYTICS.WEEK_NUMBER": "N.Semana",
  "ANALYTICS.FIDELITY": "Fidelidad",
  "ANALYTICS.ANALYTICS": "Analytics",
  "ANALYTICS.ENGAGEMENT": "Engagement",
  "ANALYTICS.ACTIVE_FREE_USERS": "Usuarios Activos Gratis",
  "ANALYTICS.ACTIVE_PAID_USERS": "Usuarios Activos Paid",

  COLLABORATOR: "Colaboradores",
  "COLLABORATOR.LIST": "Lista Colaboradores",
  "COLLABORATOR.GROUP": "Grupo de colaboradores",
  "COLLABORATOR.CREATE": "Crear Colaborador",
  "GENERAL.SURNAME": "Apellido",

  LOCALE: "Idioma",
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  NEW_PASSWORD: "Nueva Contraseña",
  STRIPE_CONNECTED_ACCOUNT: "Cuenta Stripe Connect",
  STRIPE_REMOVE_CONFIRMATION: "Confirmar Eliminación",
  STRIPE_REMOVE_CONFIRMATION_MESSAGE:
    "¿Estás seguro de que quieres eliminarlo?",

  "VENUE_LIST.VENUE_LIST": "Lista de Locales",
  "VENUE_LIST.CLIENT_NAME": "Nombre Cliente",
  "VENUE_LIST.VENUE_NAME": "Nombre Local",
  "VENUE_LIST.STATUS": "Status",
  "VENUE_LIST.MARK_AS_REAL": "Marcar como Real",
  "VENUE_LIST.MARK_AS_TEST": "Marcar como Test",
  "VENUE_STATUS.REAL": "Real",
  "VENUE_STATUS.TEST": "Test",
  "VENUE.SELECT_VENUE_STATUS": "Select Venue Statue",
  "VENUE_STATUS.NOT_COMPUTE": "No Computa",
  "VENUE_STATUS.CLIENT_TESTING": "En Pruebas",
  "VENUE_LIST.CHANGE_DESCRIPTION": "Cambiar Descripción",
  "VENUE_LIST.DESCRIPTION": "Descripción",
  PARTIAL_DELIVERY: "Entrega Parcial",
  IN_PROGRESS: "En Progreso",
  DUPLICAR_MENU: "Duplicar Menu",
  ORIGINAL_CLIENT: "Cliente de origen",
  MENU: "Menu",
  DESTINATION_CLIENT: "Cliente de destino",
  TWILIO_MESSAGES: "Mensajes Twilio",
  MENU_DUPLICATE_SUCCESS: "Menú duplicado con éxito",
  DUPLICATE_BTN: "Duplicar",
  MONTHS: "Meses",
  MESSAGES: "Mensajes",
  DATE: "Fecha",
  VENUE_DETAILS: "Locales",
  RESTAURANT_CHARGES: "Coste por transacción",
  ROZAN_SOCIAL: "Razon social",
  CIF: "CIF",
  AUTO_SERVICES: "Autoservicio",
  AUTO_SERVICES_SCREEN: "Pantalla Autoservicio",
  Virtual_Basket: "Carrito Virtual",
  PAY_AT_TABLE: "Pago en mesa",
  SERVICE: "Servicio Extra",
  TAX: "Impuesto",
  TICKET_NUMBER: "Ticket #",
  ORDER: "Pide ",
  ORDER_AND_PAY: "Pide y paga",
  PENDIGN_ORDERS: "Pendiente de Pago",
  TIP: "Propina",
  EMAIL: "Correo Electónico",
  WHATSAPP: "Whatsapp",
  SMS: "SMS",
  NOTIFICATION_METHOD: "Vías de notificar",
  ENV_SWITCH: "Pagos Prueba",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "Fecha fin debe ser superior a la fecha de inicio",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "Fecha de inicio debe ser inferior a fecha fin",
  POPUP_SWITCH: "Habilitar Pop-up",

  INTEGRATIONS: "Integraciones",
  NO_INTEGRATION_DEFAULT: "Sin integración",
  WRONG_MESSAGE: "Algo salió mal",
  UPDATE_SUCCESS: "Integración actualizada con éxito",
  MENU_TYPE: "Tipo de menú",
  URL_MENU: "URL",
  CHECK_MENU: "Menú Check",
  COCA_COLA_MENU: "Menú Coca Cola",
  SINQRO_ORDER_TYPE: "Tipo de orden",
  GENERAL_YES: "Sí",
  GENERAL_NO: "No",
  CASH_PAY: "Pago en efectivo",
  ENABLE_RATING: "Habilitar reviews",
  RATING_URL: "Calificación URL",
  LOYALTY_PROGRAM: "Programa de fidelización",
  INVOICE_EXTRA_TEXT: "Mensaje adicional del recibo",
  "SIDEBAR_MENUE.RATINGS": "Reviews",
  "GENERAL.RATING": "Reviews",
  AVERAGE_RATING: "Valoración media",
  RATING_RANGE: "Gama de valores",
  SCHEDULE_ORDERS: "Programar órdenes",
  TAKE_AWAY_ORDERS: "Órdenes Take Away",
  NUMBER_OF_FUTURE_DAYS: "Días disponibles para take away",
  STAFF: "Personal",
  "STAFF.LIST": "Lista De Personal",
  "STAFF.CREATE": "Crear Personal",
  "STAFF.UPDATE": "Editar personal",
  REVO_REFERENCE: "Referencia Revo",
  CLIENT_INFO: "Información",
  MODIFY_CLIENT_DETAILS: "Modificar formulario",
  INTEGRATION: "Integraciones",
  SALES_PERSON: "Comercial",
  FUTURE_ORDERS: "Programado",
  "CREATE_COLLABORATOR.GROUP": "Crear Grupo Colaborador",
  CREATE_GROUP: "Crea un grupo",
  NO_GROUP: "Ningún grupo",
  ENABLE_CATALOGUE: "Habilitar catálogo",
  ENABLE_MENU_V2: "Activar Menú V2",
  ADMIN: "Administrador",
  TECHNICIAN: "Técnico",
  QR_MENU: "Carta QR",
  REQUIRE_NAME: "Requerir nombre",
  ASSIGN_COLLABORATOR: "Asignar colaborador",
  COllABORATED_CREATED: "Usuario colaborador creado exitosamente",
  COllABORATED_EDITED: "Usuario colaborador editado exitosamente",
  COllABORATED_REMOVED: "Usuario colaborador eliminado correctamente",
  CONNECTION_ERROR: "Hubo un error de conexión",
  COLLABORATOR_ASSIGNED: "Colaborador asignado correctamente",
  COLLABORATOR_GROUP_UN_ASSIGNED: "Colaborador no asignado correctamente",
  FOR_FREE: "Gratis",
  CHECK_TABLES: "Mesas Check",
  EXTRATARRIF: "Tarifa Extra",
  ENABLE_STOCK: "Activar Stock",
  "NOTIFICATION.CREATE_ORDER_MESSAGE": "Mensaje para la creación del pedido",
  "NOTIFICATION.TAKE_PARTIALLY_MESSAGE": "Mensaje para las entregas parciales",
  "NOTIFICATION.TAKE_FULLY_MESSAGE": "Mensaje para las entregas completas",
  SELECT_PAYMENT_PROVIDER: "Seleccionar proveedor de pago",
  "NOTIFICATION.ERROR_STRING_MESSAGE": `Debe contener "${SMS_NOTIFICATION_PATTERNS.ID}" obligatorio`,
  "NOTIFICATION.SENDER_ID": "Sender ID",
  "NOTIFICATION.CLAVE_API": "Clave Api",
  HIDE_ORDER_BUTTON: "Ocultar Botón De Pedido",
  ENABLE_KIOSK: "Kiosk Mode",
  SELECT_TICKET_BAI: "Select Ticket Bai",
  TICKET_BAI: "Ticket Bai",
  LANGUAGES_MODAL: "Idiomas modales",
  REQUIRE_ADDRESS: "Se requiere dirección",
  PAYMENT_ID: "Id. de pago",
  FILTER_BY_DATE: "Filtrar Por Fecha",
  REFOUND: "Reembolso",
  REFOUND_SUCCESS: "Reembolso correcto",
  "GENERAL.CURRENCY_TYPE": "Tipo de Moneda",
  ALLOW_ORDER_WITH_ZERO_AMOUNT: "Permitir órdenes con importe 0",
  EURO: "Euro",
  MEXICAN_PESO: "Peso mexicano",
};
