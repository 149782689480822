import { axiosInstance } from "app/api/axios";
import { venuePopupActionTypes } from "../redux/actionTypes";
import { updateVenuePopupUrl, getVenuePopupUrl } from "app/api/Endpoint";

export const updateVenuePopup = (popupData, clientId) => {
  return (dispatch) => {
    dispatch({
      type: venuePopupActionTypes.UPDATE_VENUE_POPUP,
    });

    const url = updateVenuePopupUrl();

    const formData = new FormData();
    formData.append("clientId", clientId); 
    formData.append("venueId", popupData.venueId);
    formData.append("popupActive", popupData.venuePopupActive);
    formData.append("imageFile", popupData.popupImageFile);
  
    axiosInstance
      .post(url, formData)
      .then((res) => {
        let { data } = res.data;
        updateVenuePopupSuccess(dispatch, data);
      })
      .catch((e) => {
        updateVenuePopupFail(dispatch, "There was an error connection");
      });
  };
};

const updateVenuePopupSuccess = (dispatch, data) => {
  dispatch({
    type: venuePopupActionTypes.UPDATE_VENUE_POPUP_SUCCESS,
    payload: data,
  });
};

const updateVenuePopupFail = (dispatch, errorMessage) => {
  dispatch({
    type: venuePopupActionTypes.UPDATE_VENUE_POPUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const getVenuePopup = (venueId) => {
  return (dispatch) => {
    dispatch({
      type: venuePopupActionTypes.GET_VENUE_POPUP,
    });
    const url = getVenuePopupUrl(venueId);
    const params = venueId;

    axiosInstance
      .get(url, { params })
      .then((res) => {
        let { data } = res.data;
        getVenuePopupSuccess(dispatch, data);
      })
      .catch((e) => {
        getVenuePopupFail(dispatch, "There was an error connection");
      });
  };
};

const getVenuePopupSuccess = (dispatch, data) => {
  dispatch({
    type: venuePopupActionTypes.GET_VENUE_POPUP_SUCCESS,
    payload: data,
  });
};

const getVenuePopupFail = (dispatch, errorMessage) => {
  dispatch({
    type: venuePopupActionTypes.GET_VENUE_POPUP_FAIL,
    payload: {
      errorMessage,
    },
  });
};
