import { CollaboratorGroupActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  collaboratorGroups: [],
  collaboratorGroupDetail: {},
  searchText: "",
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const CollaboratorGroupReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        collaboratorGroups: action.payload,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.CREATE_COLLABORATOR_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.REMOVE_COLLABORATOR_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.UN_ASSIGN_COLLABORATOR_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.EDIT_COLLABORATOR_GROUP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_DETAIL_START: {
      return { ...state, loading: true };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_DETAIL_SUCCESS: {
      return {
        ...state,
        collaboratorGroupDetail: action.payload,
        loading: false,
      };
    }
    case CollaboratorGroupActionTypes.COLLABORATOR_GROUP_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default CollaboratorGroupReducer;
