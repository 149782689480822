import React from "react";
import { TableCell, Button } from "@material-ui/core";

const SingleBtn = ({ actions, n }) => {
  return (
    <TableCell>
      {!(n && n.isRefunded) ? (
        <Button
          component="a"
          rel="noreferrer noopener"
          role="button"
          className="normal-case"
          variant="contained"
          color="primary"
          onClick={(ev) => {
            actions.onclick(n, ev);
          }}
        >
          {actions.text}
        </Button>
      ) : null}
    </TableCell>
  );
};

export default SingleBtn;
