import StaffList from "./StaffList";
import StaffDetailPage from "./StaffDetailPage";
import CreateStaffPage from "./CreateStaffPage";

export const StaffListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/staff-list",
      component: StaffList,
    },
    {
      path: "/staff/:staffId",
      component: StaffDetailPage,
    },
    {
      path: "/add-staff/:staffId",
      component: CreateStaffPage,
    },
  ],
};
