import history from "@history";
import { setDefaultSettings } from "app/store/actions/fuse";
import _ from "@lodash";
import store from "app/store";
import * as Actions from "app/store/actions";
import auth0Service from "app/services/auth0Service";
import jwtService from "app/services/jwtService";
import { clientSettings } from "config";
import { STORAGE_KEY, removeStorageItem } from "app/helpers/storageHelper";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData) {
  const user = {
    role: ["admin"],
    from: "auth0",
    data: {
      displayName: tokenData.username,
      photoURL: tokenData.picture,
      email: tokenData.email,
      settings:
        tokenData.user_metadata && tokenData.user_metadata.settings
          ? tokenData.user_metadata.settings
          : {},
      shortcuts:
        tokenData.user_metadata && tokenData.user_metadata.shortcuts
          ? tokenData.user_metadata.shortcuts
          : [],
    },
  };

  return setUserData(user);
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch) => {
    /*
        Set User Settings
         */
    dispatch(setDefaultSettings(user.data.settings));

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user);

    return dispatch(setUserData(user));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    updateUserData(newUser);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    removeStorageItem(STORAGE_KEY.USER_ID);
    history.replace({
      pathname: `/login/${clientSettings.language}`,
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

/**
 * Update User Data
 */
function updateUserData(user) {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  switch (user.from) {
    case "auth0": {
      auth0Service
        .updateUserData({
          settings: user.data.settings,
          shortcuts: user.data.shortcuts,
        })
        .then(() => {
          store.dispatch(
            Actions.showMessage({ message: "User data saved to auth0" })
          );
        })
        .catch((error) => {
          store.dispatch(Actions.showMessage({ message: error.message }));
        });
      break;
    }
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          store.dispatch(
            Actions.showMessage({ message: "User data saved with api" })
          );
        })
        .catch((error) => {
          store.dispatch(Actions.showMessage({ message: error.message }));
        });
      break;
    }
  }
}
