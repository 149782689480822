import { axiosInstance } from "app/api/axios";

import { ClientActionTypes } from "../redux/actionTypes";
import {
  getClientsListUrl,
  getAccessClientUrl,
  getClientDetailsUrl,
  createClientUrl,
  checkEmaillUrl,
  updateClientUrl,
  updateCheckClientUrl,
  getVenueDetail,
  GET_TAXES,
  GET_CURRENCIES,
} from "app/api/Endpoint";
import { errorAlert, successAlert } from "app/helpers/alerts";
import History from "@history";

export function setClientSearchText(event) {
  return {
    type: ClientActionTypes.SET_CLIENT_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getClientsList = ({
  searchText,
  page,
  limit,
  order,
  isClientFilter = false,
}) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.CLIENT_LIST_START,
    });
    const url = getClientsListUrl();
    const _limit = isClientFilter ? "" : limit;
    axiosInstance
      .get(
        url +
          `?searchText=${searchText}&page=${page}&limit=${_limit}&order=${JSON.stringify(
            order
          )}`
      )
      .then((res) => {
        getClientsListSuccess(dispatch, res.data.data);
      })
      .catch((e) => {
        getClientsListFail(dispatch, "There was an error connection");
      });
  };
};

const getClientsListFail = (dispatch, errorMessage) => {
  dispatch({
    type: ClientActionTypes.CLIENT_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getClientsListSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.CLIENT_LIST_SUCCESS,
    payload: data,
  });
};

export const accessClient = (id) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.ACCESS_CLIENT_START,
    });

    const url = getAccessClientUrl();

    axiosInstance
      .post(url, { id })
      .then((res) => {
        let { data } = res.data;
        accessClientSuccess(dispatch, data);
      })
      .catch((e) => {
        accessClientFail(dispatch, "There was an error connection");
      });
  };
};

const accessClientFail = (dispatch, errorMessage) => {
  dispatch({
    type: ClientActionTypes.ACCESS_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const accessClientSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.ACCESS_CLIENT_SUCCESS,
    payload: data,
  });
  const url = process.env.REACT_APP_DASHBOARD_ACCESS_LINK;
  window.open(
    `${url}/${data.accessToken}/${data._id}/${data.masterId}`,
    "_blank"
  );
};

export const getClientDetails = (id) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.CLIENT_DETAILS_START,
    });

    const url = getClientDetailsUrl(id);
    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        getClientDetailsSuccess(dispatch, data);
      })
      .catch((e) => {
        getClientDetailsFail(dispatch, "There was an error connection");
      });
  };
};

const getClientDetailsFail = (dispatch, errorMessage) => {
  dispatch({
    type: ClientActionTypes.CLIENT_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  });
  History.push({
    pathname: `/clientsList`,
  });
};

const getClientDetailsSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.CLIENT_DETAILS_SUCCESS,
    payload: data,
  });
};

export const createClient = (clientData) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.CREATE_CLIENT_START,
    });

    const url = createClientUrl(
      clientData.referralCode && clientData.referralCode.length > 0
    );

    axiosInstance
      .post(url, clientData)
      .then((res) => {
        createClientSuccess(dispatch, res);
      })
      .catch((e) => {
        createClientFail(dispatch, "There was an error connection");
      });
  };
};

const createClientFail = (dispatch, errorMessage) => {
  dispatch({
    type: ClientActionTypes.CREATE_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createClientSuccess = (dispatch, response) => {
  const { data, message } = response.data;
  successAlert(dispatch, message);
  dispatch({
    type: ClientActionTypes.CREATE_CLIENT_SUCCESS,
    payload: data,
  });
};

export const updateClient = (clientData) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.UPDATE_CLIENT_START,
    });

    const url = updateClientUrl();

    axiosInstance
      .put(url, clientData)
      .then((res) => {
        updateClientSuccess(dispatch, res);
      })
      .catch((e) => {
        updateClientFail(dispatch, "There was an error connection");
      });
  };
};

const updateClientFail = (dispatch, errorMessage) => {
  dispatch({
    type: ClientActionTypes.UPDATE_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateClientSuccess = (dispatch, response) => {
  const { data, message } = response.data;
  // successAlert(dispatch,message)
  dispatch({
    type: ClientActionTypes.UPDATE_CLIENT_SUCCESS,
    payload: data,
  });
};
export const checkEmail = (email: string, showLoader = true) => {
  return (dispatch) => {
    showLoader &&
      dispatch({
        type: ClientActionTypes.CHECK_EMAIL_START,
      });

    const request = {
      email,
    };
    const url = checkEmaillUrl();

    axiosInstance
      .post(url, request)
      .then((res) => {
        checkEmailSuccess(dispatch, res.data);
      })
      .catch(() => {
        checkEmailFail(dispatch, "There was an error connection");
      });
  };
};

const checkEmailFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: ClientActionTypes.CHECK_EMAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const checkEmailSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.CHECK_EMAIL_SUCCESS,
    payload: data.data,
  });
};

export const updateCheckClient = (clientData, productId) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.UPDATE_CHECK_CLIENT_START,
    });

    const url = updateCheckClientUrl();

    axiosInstance
      .put(url, clientData)
      .then((res) => {
        updateCheckClientSuccess(dispatch, res);
        dispatch(getClientDetails(productId));
      })
      .catch((e) => {
        updateCheckClientFail(dispatch, "There was an error connection");
      });
  };
};

const updateCheckClientFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, "Something went wrong");
  dispatch({
    type: ClientActionTypes.UPDATE_CHECK_CLIENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateCheckClientSuccess = (dispatch, response) => {
  const { data, message } = response.data;
  successAlert(dispatch, message);
  dispatch({
    type: ClientActionTypes.UPDATE_CHECK_CLIENT_SUCCESS,
    payload: data,
  });
};
export const deleteClients = (clientIds: string[]) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.CLIENT_DELETE_START,
    });

    const url = getClientsListUrl();

    axiosInstance
      .delete(url, { data: { clientIds } })
      .then((res) => {
        dispatch({
          type: ClientActionTypes.CLIENT_DELETE_SUCCESS,
        });
        dispatch(
          getClientsList({
            searchText: "",
            page: 0,
            limit: 10,
            order: {},
          })
        );
      })
      .catch(() => {
        dispatch({
          type: ClientActionTypes.CLIENT_DELETE_FAIL,
        });
      });
  };
};

export const getClientVenueDetail = (venueId) => {
  return (dispatch) => {
    dispatch({
      type: ClientActionTypes.GET_CLIENT_VENUE_DETAIL_START,
    });

    axiosInstance
      .get(`${getVenueDetail}/${venueId}`)
      .then((res) => {
        getClientVenueDetailSuccess(dispatch, res);
      })
      .catch((e) => {
        getClientVenueDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getClientVenueDetailFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, "Something went wrong");
  dispatch({
    type: ClientActionTypes.GET_CLIENT_VENUE_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getClientVenueDetailSuccess = (dispatch, response) => {
  const { data } = response.data;
  dispatch({
    type: ClientActionTypes.GET_CLIENT_VENUE_DETAIL_SUCCESS,
    payload: data,
  });
};

export const getTaxes = () => {
  return (dispatch) => {
    dispatch({ type: ClientActionTypes.GET_TAXES_START });
    axiosInstance
      .get(GET_TAXES)
      .then((res) => {
        if (res && res.data) {
          getTaxesSuccess(dispatch, res.data);
        }
      })
      .catch((error) => {
        getTaxesFail(dispatch, error);
      });
  };
};

export const getTaxesSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.GET_TAXES_SUCCESS,
    payload: data.data,
  });
};
export const getTaxesFail = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.GET_TAXES_SUCCESS,
    payload: data,
  });
};

export const updateVenue = (venueId, data) => {
  return (dispatch) => {
    dispatch({ type: ClientActionTypes.UPDATE_VENUE_START });
    axiosInstance
      .put(`${getVenueDetail}/${venueId}`, data)
      .then((res) => {
        if (res && res.data) {
          updateVenueSuccess(dispatch, res.data);
        }
      })
      .catch((error) => {
        updateVenueFail(dispatch, error);
      });
  };
};

export const updateVenueSuccess = (dispatch, data) => {
  const { message = "" } = data;
  successAlert(dispatch, message);
  dispatch({
    type: ClientActionTypes.UPDATE_VENUE_SUCCESS,
    payload: data.data,
  });
};
export const updateVenueFail = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.UPDATE_VENUE_FAIL,
    payload: data,
  });
};
export const getCurrencies = () => {
  return (dispatch) => {
    dispatch({ type: ClientActionTypes.GET_CURRENCIES_START });
    axiosInstance
      .get(GET_CURRENCIES)
      .then((res) => {
        if (res && res.data) {
          getCurrenciesSuccess(dispatch, res.data);
        }
      })
      .catch((error) => {
        getCurrenciesFail(dispatch, error);
      });
  };
};

export const getCurrenciesSuccess = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.GET_CURRENCIES_SUCCESS,
    payload: data.data,
  });
};
export const getCurrenciesFail = (dispatch, data) => {
  dispatch({
    type: ClientActionTypes.GET_CURRENCIES_SUCCESS,
    payload: data,
  });
};
