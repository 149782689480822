import React, { useState } from "react";
import { Table, TableBody, TablePagination } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import _ from "@lodash";
import ListTableHead from "./ListTableHead";
import RowComponent from "./components/RowComponent";

function ListTablePaginated(props) {
  const [selected, setSelected] = useState([]);

  function handleSelectAllClick(event) {
    if (selected.length === props.data.length) {
      setSelected([]);
    } else {
      setSelected(props.data.map((n) => n._id));
    }
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleDelete() {
    props.handleDelete(selected);
  }

  return (
    <div className="w-full flex flex-col">
      <FuseScrollbars className="flex-grow overflow-x-auto">
        <Table className="min-w-xl" aria-labelledby="tableTitle">
          <ListTableHead
            numSelected={selected.length}
            order={props.order}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={props.handleRequestSort}
            handleDelete={handleDelete}
            headerRows={props.headerRows}
            rowCount={props.totalData}
          />
          <TableBody>
            {props.data &&
              props.data.map((n) => {
                const isSelected = selected.indexOf(n._id) !== -1;
                return (
                  <RowComponent
                    headerRows={props.headerRows}
                    isSelected={isSelected}
                    handleClick={props.handleClick}
                    handleCheck={handleCheck}
                    n={n}
                  />
                );
              })}
          </TableBody>
        </Table>
      </FuseScrollbars>
      <TablePagination
        component="div"
        count={props.totalData}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        labelRowsPerPage={"Filas por página"}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={props.handlePageChange}
        onChangeRowsPerPage={props.handleRowsPerPageChange}
      />
    </div>
  );
}

export default ListTablePaginated;
