import { DublicarMenuActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  menuList: [],
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const DublicarMenuReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case DublicarMenuActionTypes.GET_DUBLICAR_MENU_LIST_START: {
      return { ...state, loading: true };
    }
    case DublicarMenuActionTypes.GET_DUBLICAR_MENU_LIST_SUCCESS: {
      return {
        ...state,
        menuList: action.payload,
        loading: false,
      };
    }
    case DublicarMenuActionTypes.GET_DUBLICAR_MENU_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case DublicarMenuActionTypes.SET_DUBLICAR_MENU_LIST_START: {
      return { ...state, loading: true };
    }
    case DublicarMenuActionTypes.SET_DUBLICAR_MENU_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DublicarMenuActionTypes.SET_DUBLICAR_MENU_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default DublicarMenuReducer;
