import React from "react";

import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

export const IntegrationDropdown = ({ onChange, currentIntegration }) => {
  const { languageStrings } = useLanguageHelper();
  const integrationList =
    currentIntegration &&
    currentIntegration.integrationList &&
    currentIntegration.integrationList.length
      ? currentIntegration.integrationList
      : [];
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center mb-16`}
    >
      <InputLabel htmlFor="integration-type">
        {languageStrings["INTEGRATIONS"]}
      </InputLabel>
      <Select
        disabled={isSalesPerson}
        native
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{ paddingBottom: 1 }}
        input={
          <OutlinedInput
            name="Integration"
            labelWidth={100}
            id="integration-type"
          />
        }
        value={
          currentIntegration && currentIntegration.integrationType
            ? currentIntegration.integrationType
            : "none"
        }
      >
        <option value={"none"}>
          {languageStrings["NO_INTEGRATION_DEFAULT"]}
        </option>
        {integrationList.map((item) => (
          <option value={item}>{item}</option>
        ))}
      </Select>
    </FormControl>
  );
};
