import FailedPaymentList from "./failedPaymentList";

export const FailedPaymentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/failed_payments",
      component: FailedPaymentList,
    },
  ],
};
