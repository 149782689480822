import React, { useEffect, useState } from "react";
import { Table, TableBody, TablePagination } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import _ from "@lodash";
import ListTableHead from "./ListTableHead";
import RowComponent from "./components/RowComponent";

function ListTable(props) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(props.data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });

  useEffect(() => {
    let { searchText, headerRows, data } = props;
    let { searchBy } = headerRows;

    let newData =
      searchText.length === 0
        ? data
        : _.filter(data, (item) => {
            let exists = false;
            for (var i = 0; i < searchBy.length; i++) {
              if (
                item[searchBy[i]] &&
                item[searchBy[i]]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                exists = true;
                break;
              }
            }
            if (exists) {
              return item;
            }
          });

    setData(newData);
  }, [props.data, props.searchText]);
  function handleRequestSort(event, property) {
    const id = property;
    let direction = "asc";

    if (order.id === property && order.direction === "asc") {
      direction = "desc";
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(props.data.map((n) => n._id));
      return;
    }
    setSelected([]);
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  function handleChangeRowsPerPage(event) {
    let _page = page;
    if (data && data.length) {
      const limit = Number(event.target.value);
      const totalItems = Number(data.length);
      if (limit * page > totalItems) {
        setPage(Math.ceil(totalItems / limit - 1));
        _page = Math.ceil(totalItems / limit - 1);
      }
    }
    setRowsPerPage(event.target.value);
  }

  function handleDelete() {
    props.handleDelete(selected);
  }
  function onCustomFieldPress() {
    props.onCustomFieldPress(selected);
  }

  return (
    <div className="w-full flex flex-col">
      <FuseScrollbars className="flex-grow overflow-x-auto">
        <Table className="min-w-xl" aria-labelledby="tableTitle">
          <ListTableHead
            numSelected={selected.length}
            order={order}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            handleDelete={handleDelete}
            headerRows={props.headerRows}
            rowCount={props.data && props.data.length}
            showCustomItem={props.showCustomItem}
            customItemTitle={props.customItemTitle}
            customItemIcon={props.customItemIcon}
            onCustomFieldPress={onCustomFieldPress}
          />
          <TableBody>
            {data &&
              _.orderBy(data, [(o) => o[order.id]], [order.direction])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isSelected = selected.indexOf(n._id) !== -1;

                  return (
                    <RowComponent
                      headerRows={props.headerRows}
                      isSelected={isSelected}
                      handleClick={props.handleClick}
                      handleCheck={handleCheck}
                      n={n}
                    />
                  );
                })}
          </TableBody>
        </Table>
      </FuseScrollbars>
      <TablePagination
        component="div"
        count={props.data && props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={"Filas por página"}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default ListTable;
