import React from "react";
import {
    Button,
    Card,
    CardContent,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate } from "@fuse";
import { useForm } from "@fuse/hooks";
import clsx from "clsx";
import { useDispatch, connect, useSelector } from "react-redux";
import { registerUser } from "app/store/auth/AuthActions";
import history from "@history";
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';




const useStyles = makeStyles((theme) => ({
    root: {
        background: "",
        color: theme.palette.primary.contrastText,
    },
}));

function RegisterPage() {
    const dispatch = useDispatch();
    const classes = useStyles({});
    const success = useSelector(({ banzzu }) => banzzu.auth.success);
  
    const { form, handleChange } = useForm({
        email: "",
        password: "",
        name: "",
        address: "",
        phone: "",
        cif: ""
    });

    function isFormValid() {
        return form.email.length > 5 && form.password.length > 5 && form.name.length > 3 && form.address.length > 3;
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        dispatch(registerUser(form))

    }
    return (
        <div
            className={clsx(
                classes.root,
                "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32"
            )}
            style={{ backgroundColor: "#5663F5" }}
        >
            <div className="flex flex-col items-center justify-center w-full">
                <FuseAnimate animation="transition.expandIn">
                    <Card className="flex">
                        <CardContent className="flex flex-col items-center justify-center p-32">
                            <Typography variant="h6" className="mt-16 mb-32">
                                {"Regístrate"}
                            </Typography>
                            <form
                                name="signupForm"
                                noValidate
                                className="flex flex-col justify-center w-full"
                                onSubmit={handleSubmit}
                            >
                                <div className="flex">
                                    <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Email"
                                        autoFocus
                                        type="email"
                                        name="email"
                                        value={form.email}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                    <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Contraseña"
                                        type="password"
                                        name="password"
                                        value={form.password}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Nombre de la compañía"
                                        autoFocus
                                        type="text"
                                        name="name"
                                        value={form.name}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                   
                                     <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Cif"
                                        autoFocus
                                        type="text"
                                        name="cif"
                                        value={form.cif}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Teléfono"
                                        autoFocus
                                        type="text"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    
                                </div>
                                <div className="flex">
                                <TextField
                                        className="mt-8 mb-16 mr-8"
                                        label="Dirección"
                                        type="text"
                                        name="address"
                                        value={form.address}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        rows={6}
                                        multiline
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="w-224 mx-auto mt-16"
                                    aria-label="LOG IN"
                                    disabled={!isFormValid()}
                                    type="submit"
                                >
                                    {"Regístrate"}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = {
    registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
