import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../common/List/ListHeader";
import {
  deleteCollaboratorGroup,
  getCollaboratorGroupList,
  setSearchText,
} from "app/store/collaboratorGroup/CollaboratorGroupActions";
import CreateCollaboratorGroupModal from "./components/CreateCollaboratorGroupModal";

const CollaboratorGroupList = () => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const { collaboratorGroups, loading, searchText } = useSelector(
    (state) => state.banzzu.CollaboratorGroup
  );
  const [modalData, setModalData] = useState({
    show: false,
    _id: "",
    name: "",
    isEditing: false,
  });
  useEffect(() => {
    dispatch(getCollaboratorGroupList());
  }, []);

  const searchMessagesList = (event) => {
    dispatch(setSearchText(event));
  };
  const onHandleDelete = (selected) => {
    dispatch(deleteCollaboratorGroup(selected));
  };
  const handleClick = (item) => {
    setModalData({
      show: true,
      _id: item._id,
      name: item.name,
      isEditing: true,
    });
  };
  const headerRows = {
    data: [
      {
        id: ["name"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: ["createdAt"],
        align: "left",
        disablePadding: false,
        label: languageStrings["DATE"],
        sort: true,
        type: "date",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["name"],
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div
          style={{
            flex: 1,
            filter: false ? "blur(3px)" : "none",
            marginTop: 30,
          }}
        >
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["COLLABORATOR.GROUP"]}
                  setSearchText={searchMessagesList}
                  searchType={"CollaboratorGroup"}
                  CustomButtonPress={() => {
                    setModalData({
                      _id: "",
                      isEditing: false,
                      name: "",
                      show: true,
                    });
                  }}
                />
              </div>
            }
            content={
              <ListTable
                handleDelete={onHandleDelete}
                headerRows={headerRows}
                data={collaboratorGroups}
                searchText={searchText}
                handleClick={handleClick}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <CreateCollaboratorGroupModal
        open={modalData.show}
        isEditing={modalData.isEditing}
        onClose={() => {
          setModalData({
            show: false,
            _id: "",
            isEditing: false,
            name: "",
          });
        }}
        _id={modalData._id}
        name={modalData.name}
        setName={(val) => {
          setModalData({
            ...modalData,
            name: val,
          });
        }}
      />
    </>
  );
};

export default CollaboratorGroupList;
