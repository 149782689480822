import React, { useEffect, useState } from "react";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { Button, makeStyles, Icon } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../common/List/ListHeader";
import {
  deleteCollaboratorUsers,
  getCollaboratorUserList,
  setSearchText,
} from "app/store/collaboratorUser/CollaboratorUserActions";
import CreateGroupModal from "./components/CreateGroupModal";
import history from "@history";
import { isCollaborator } from "app/helpers/utilsHelper";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const CollaboratorList = () => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const { collaboratorUsers, loading, searchText } = useSelector(
    (state) => state.banzzu.CollaboratorUser
  );
  const [group, setGroup] = useState({
    selectedUsers: [],
    showModal: false,
  });
  useEffect(() => {
    dispatch(getCollaboratorUserList());
  }, []);

  const searchMessagesList = (event) => {
    dispatch(setSearchText(event));
  };
  const onHandleDelete = (selected) => {
    dispatch(deleteCollaboratorUsers(selected, languageStrings));
  };
  const headerRows = {
    data: [
      {
        id: ["email"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "text",
      },
      {
        id: ["name"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: ["roleValue"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ROLE"],
        sort: true,
        type: "text",
      },
      {
        id: ["groupName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["COLLABORATOR.GROUP"],
        sort: true,
        type: "text",
      },
      {
        id: ["createdAt"],
        align: "left",
        disablePadding: false,
        label: languageStrings["DATE"],
        sort: true,
        type: "date",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["email", "name", "roleValue", "groupName"],
  };
  const handleClick = (item) => {
    history.push(`/add-collaborator/${item._id}`);
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div
          style={{
            flex: 1,
            filter: false ? "blur(3px)" : "none",
            marginTop: 30,
          }}
        >
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["COLLABORATOR.LIST"]}
                  setSearchText={searchMessagesList}
                  searchType={"CollaboratorUser"}
                />
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    onClick={() => {
                      history.push(`/add-collaborator/new`);
                    }}
                  >
                    {languageStrings["COLLABORATOR.CREATE"]}
                  </Button>
                </FuseAnimate>
              </div>
            }
            content={
              <ListTable
                handleClick={handleClick}
                handleDelete={onHandleDelete}
                headerRows={headerRows}
                data={collaboratorUsers}
                searchText={searchText}
                onCustomFieldPress={(selectedUsers) => {
                  setGroup({
                    selectedUsers,
                    showModal: true,
                  });
                }}
                customItemTitle={"Add to Group"}
                showCustomItem={!isCollaborator}
                customItemIcon={"people"}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <CreateGroupModal
        selectedCollaborators={group.selectedUsers}
        open={group.showModal}
        onClose={() => {
          setGroup({
            selectedUsers: [],
            showModal: false,
          });
        }}
      />
    </>
  );
};

export default CollaboratorList;
