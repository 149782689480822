import { SMS_NOTIFICATION_PATTERNS } from "app/helpers/constants";

export default {
  "HOME_PAGE.YOUR_CODE": "Your QR code",
  "HOME_PAGE.RESTAURANT": "Restaurant",

  "ERRORS.INVALID_EMAIL": "This email is invalid",
  "ERRORS.EMAIL_TAKEN": "Email already taken",
  "ERRORS.INVALID_PHONE": "This phone number is invalid",
  "ERRORS.INVALID_LOGIN": "Invalid Login. Please try again",
  "ERRORS.INVALID_URL": "Invalid URL",
  "ERRORS.MINIMUM_10_CHAR": "Minimum size 10 characters",
  "ERRORS.MINIMUM_3_CHAR": "Minimum size 3 characters",
  "ERRORS.MINIMUM_1_CHAR": "Minimum size 1 character",
  "ERRORS.MINIMUM_4_CHAR": "Minimum size 4 characters",
  "ERRORS.MINIMUM_6_CHAR": "Minimum size 6 characters",
  "ERRORS.MUST_BE_NUMBER": "Must be a number",
  "HOME_PAGE.PENDING_ORDERS": "Pending Orders",

  "GENERAL.CREATE": "Create",
  "GENERAL.ADDRESS": "Address",
  "GENERAL.ADD_BUTTON": "Add",
  "GENERAL.BASIC_INFO": "Basic Info",
  "GENERAL.CANCEL_BUTTON": "Cancel",
  "GENERAL.CLIENT": "Client",
  "GENERAL.CONFIRM_BUTTON": "Confirm",
  "GENERAL.CONTACT_INFO": "Contact Info",
  "GENERAL.DATE": "Date",
  "GENERAL.DELETE": "Delete",
  "GENERAL.DENY_BUTTON": "Deny",
  "GENERAL.DESCRIPTION": "Description",
  "GENERAL.DETAIL": "Detail",
  "GENERAL.EMAIL": "Email",
  "GENERAL.IMAGE": "Image",
  "GENERAL.NAME": "Name",
  "GENERAL.PASSWORD": "Password",
  "GENERAL.PHONE": "Phone",
  "GENERAL.POINTS": "Points",
  "GENERAL.POSTCODE": "Postcode",
  "GENERAL.PRICE": "Price",
  "GENERAL.ROWS_PER_PAGE": "Rows per page",
  "GENERAL.SAVE": "Save",
  "GENERAL.SAVE_PREVIEW": "Save & Preview",
  "GENERAL.SAVE_BUTTON": "Save",
  "GENERAL.SEARCH": "Search",
  "GENERAL.SEND": "Send",

  "GENERAL.TIME": "Time",
  "GENERAL.TITLE": "Title",
  "GENERAL.TYPE": "Type",
  "GENERAL.USER_LIST": "User List",
  "GENERAL.VENUE": "Title",
  "GENERAL.STATUS": "Status",
  "GENERAL.WEBSITE": "Website",
  "GENERAL.CATEGORY": "Category",
  "GENERAL.ITEMS": "Items",
  "GENERAL.ACTIONS": "Actions",
  "GENERAL.REF": "Ref #",
  "GENERAL.ALERT_TITLE": "Redirection Confirmation",
  "GENERAL.ALERT_DESC":
    "Are you sure you want to go back without saving changes?",
  "GENERAL.LATITUDE": "Latitude",
  "GENERAL.LONGITUDE": "Longitude",
  "GENERAL.REMOVE": "Remove",
  "GENERAL.PLATFORM": "Platform",
  "GENERAL.TO": "To",
  "GENERAL.YES": "Yes",
  "GENERAL.NO": "No",
  "GENERAL.VISIT_WEB": "Visit Web",
  "GENERAL.ACCESS_CLIENT": "Access Client",
  "GENERAL.YOUR_CODE": "Your code is",
  "GENERAL.COPY_CODE": "Copy code",
  "GENERAL.ORDER_TYPE": "Order Type",
  "GENERAL.FIRST_NAME": "First Name",
  "GENERAL.LAST_NAME": "Last Name",
  "GENERAL.RESTAURANT_NAME": "Restaurant Name",
  "GENERAL.RESTAURANT_PHONE": "Restaurant Phone",
  "GENERAL.CITY": "City",
  "GENERAL.POST_CODE": "Post code",
  "GENERAL.COMMENTS": "Comments",
  "GENERAL.COMMISSIONS": "commissions",
  "GENERAL.EXPORT": "Export",
  "GENERAL.EXPORT_CSV": "Export CSV",
  "GENERAL.EXPORT_XLS": "Export XLS",
  "GENERAL.RESTAURANT": "Restaurant",
  "GENERAL.TABLE": "Table",
  "GENERAL.DATE_TIME": "Date and time",
  "GENERAL.ROLE": "Role",
  "GENERAL.CURRENCY_TYPE": "Currency Type",

  "LOGIN_PAGE.LOGIN_BUTTON": "LOGIN",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "LOGIN TO YOUR ACCOUNT",
  "LOGIN_PAGE.REGISTER_LINK": "Don't have an account? Register",
  "LOGIN_PAGE.EMAIL": "Email",
  "LOGIN_PAGE.PASSWORD": "Password",

  "SIDEBAR_MENUE.HOME": "Home",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menu",
  "SIDEBAR_MENUE.TITLE": "Menu",
  "SIDEBAR_MENUE.TRANSACTION": "Transactions List",
  "SIDEBAR_MENUE.USER_LIST": "User",
  "SIDEBAR_MENUE.CLIENT": "Client",
  "SIDEBAR_MENUE.CLIENT_LIST": "Client list",
  "SIDEBAR_MENUE.ADD_CLIENT": "Create Client",
  "SIDEBAR_MENUE.REFERRALS": "Referrals",
  "SIDEBAR_MENUE.ORDERS": "Orders",
  "SIDEBAR_MENUE.FAILED_PAYMENTS": "Failed Payments",
  "SIDEBAR_MENUE.ORDERS_LIST": "Orders List",
  "SIDEBAR_MENUE.ANALYTICS": "Analytics",

  "USER_DETAIL.BACK_LIST": "User List",

  "REGISTER_PAGE.TITLE": "Getting started has never been easier",
  "REGISTER_PAGE.FIRST_NAME": "First Name",
  "REGISTER_PAGE.LAST_NAME": "Last Name",
  "REGISTER_PAGE.EMAIL": "Email",
  "REGISTER_PAGE.TELEPHONE": "Phone Number",
  "REGISTER_PAGE.PASSWORD": "Password",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Terms and Conditions",
  "REGISTER_PAGE.PRIVACY_POLICY": "Privacy Policy",
  "REGISTER_PAGE.ACCEPT": "Accept",
  "REGISTER_PAGE.BUTTON_TEXT": "Continue",
  "REGISTER_PAGE.LOGIN_LINK": "Already have an account?",
  "REGISTER_PAGE.LOGIN": "Login",
  "REGISTER_PAGE.NEW_IN_BANZZU": "New in Banzzu?",
  "REGISTER_PAGE.SUB_TITLE_1":
    "Conquer the digital universe of your restaurant,",
  "REGISTER_PAGE.SUB_TITLE_2": "and discover all your possibilities",

  "CLIENT.REGISTRATION_DATE": "Registration Date",
  "CLIENT.SUBSCRIPTION": "Subscription",
  "CLIENT.CLIENT_LIST": "Clients list",
  "CLIENT.CLIENT_DETAILS": "Client details",
  "CLIENT.GOALS": "Goals",
  "CLIENT.ACCESS_LEVEL_PLACEHOLDER": "Access level",
  "CLIENT.All": "All",
  "CLIENT.ACTIONS": "Actions",
  "CLIENT.LAST_INTERACTION": "Last interactions",
  "CLIENT.ADD_CLIENT": "Create Client",
  "CLIENT.REFERRED_BY": "Referred By",
  "CLIENT.CREATED_TYPE": "Created Manually",

  "ORDER_LIST_PAGE.TITLE": "Orders",
  "ORDER_LIST_PAGE.ALL_STATUS": "All statuses",
  "ORDER_LIST_PAGE.ALL_TYPES": "All types",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Select Order Status",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Select Order Type",
  "ORDER_PAGE.CUSTOMER": "Customer",
  "ORDER_PAGE.EMAIL": "Email",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Name",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Confirm Order",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Cancel",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Preparing",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "COLLECT",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "COLLECTED",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Start Delivery",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Delivered",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Order",
  "ORDER_PAGE.ORDERS": "Orders",
  "ORDER_PAGE.ORDER_DETAIL": "Order Detail",
  "ORDER_PAGE.PHONE": "Phone",
  "ORDER_PAGE.DELIVERY": "Delivery Address",
  "ORDER_PAGE.COMMENTS": "Comments",
  "ORDER_PAGE.PRODUCTS": "Products",
  "ORDER_PAGE.QUANTITY": "Quantity",

  "ORDER_TYPE.DELIVERY": "Delivery",
  "ORDER_TYPE.COLLECT": "Collect",
  "ORDER_TYPE.FUTURE_ORDER": "Future Order",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Order From Table",

  "ORDER_STATUS.UPDATED_AT": "Updated on",
  "ORDER_STATUS.ORDER_NUMBER": "Order Number",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Pay by Cash",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Payment accepted",
  "ORDER_STATUS.ORDER_ACCEPTED": "Order accepted",
  "ORDER_STATUS.PREPARING": "Preparing the order",
  "ORDER_STATUS.ON_DELIVERY": "On Delivery",
  "ORDER_STATUS.DELIVERED": "Delivered",
  "ORDER_STATUS.CANCELED": "Cancel",
  "ORDER_STATUS.COLLECT": "Ready To Collect",
  "ORDER_STATUS.COLLECTED": "Collected",
  "ORDER_STATUS.ORDER_TIME": "Order Time",
  "ORDER_STATUS.ORDER_TABLE": "Order Table",

  "REFERRAL.REFERRAL_LIST": "Referrals List",

  "HELP_MENU.LOGOUT": "Logout",
  "HELP_MENU.CHANGE_PASSWORD": "Change password",

  "ANALYTICS.WEEK_NUMBER": "Week No",
  "ANALYTICS.FIDELITY": "Fidelity",
  "ANALYTICS.ANALYTICS": "Analytics",
  "ANALYTICS.ENGAGEMENT": "Engagement",
  "ANALYTICS.ACTIVE_FREE_USERS": "Active Free Users",
  "ANALYTICS.ACTIVE_PAID_USERS": "Active Paid Users",
  COLLABORATOR: "Collaborator",
  "COLLABORATOR.LIST": "Collaborators List",
  "COLLABORATOR.GROUP": "Collaborators Group",
  "COLLABORATOR.CREATE": "Create Collaborator",
  "COLLABORATOR.DETAIL": "Collaborator Detail",

  "GENERAL.SURNAME": "Surname",
  LOCALE: "Locale",
  CONFIRM_PASSWORD: "Confirm Password",
  NEW_PASSWORD: "New Password",
  STRIPE_CONNECTED_ACCOUNT: "Stripe Connected Account",
  STRIPE_REMOVE_CONFIRMATION: "Remove confirmation",
  STRIPE_REMOVE_CONFIRMATION_MESSAGE:
    "Are you sure you want to remove this ítem?",

  "VENUE_LIST.VENUE_LIST": "Venue List",
  "VENUE_LIST.CLIENT_NAME": "Client Name",
  "VENUE_LIST.VENUE_NAME": "Venue Name",
  "VENUE_LIST.STATUS": "Status",
  "VENUE_LIST.MARK_AS_REAL": "Mark as Real",
  "VENUE_LIST.MARK_AS_TEST": "Mark as Test",
  "VENUE_STATUS.REAL": "Real",
  "VENUE_STATUS.TEST": "Test",
  "VENUE.SELECT_VENUE_STATUS": "Select Venue Statue",
  "VENUE_STATUS.NOT_COMPUTE": "Not Computed",
  "VENUE_STATUS.CLIENT_TESTING": "Client Testing",
  "VENUE_LIST.CHANGE_DESCRIPTION": "Change Description",
  "VENUE_LIST.DESCRIPTION": "Description",
  PARTIAL_DELIVERY: "Partial Delivery",
  IN_PROGRESS: "In Progress",

  DUPLICAR_MENU: "Duplicar Menu",

  ORIGINAL_CLIENT: "Original client",
  MENU: "Menu",
  DESTINATION_CLIENT: "Destination client",
  TWILIO_MESSAGES: "Twilio Messages",
  MENU_DUPLICATE_SUCCESS: "Menu duplicated successfully",
  DUPLICATE_BTN: "Duplicate",
  MONTHS: "Months",
  MESSAGES: "Messages",
  DATE: "Date",
  VENUE_DETAILS: "Venues",
  RESTAURANT_CHARGES: "Cost per transaction",
  ROZAN_SOCIAL: "Legal Name",
  CIF: "Company Registration Number",
  AUTO_SERVICES: "Autoservice",
  AUTO_SERVICES_SCREEN: "Autoservice Screen",
  Virtual_Basket: "Virtual Basket",
  PAY_AT_TABLE: "Pay at Table",
  SERVICE: "Extra service",
  TAX: "Tax",
  TICKET_NUMBER: "Ticket #",
  ORDER: "Order",
  ORDER_AND_PAY: "Order and Pay",
  PENDIGN_ORDERS: "Pending payment",
  TIP: "Tip",
  EMAIL: "Email",
  WHATSAPP: "Whatsapp",
  SMS: "SMS",
  NOTIFICATION_METHOD: "Methods of notification",
  ENV_SWITCH: "Test Payments",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "End date should be greater then start date",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "Start date should be smaller then end date",
  POPUP_SWITCH: "Enable Pop-up Image",

  INTEGRATIONS: "Integrations",
  NO_INTEGRATION_DEFAULT: "No integration",
  WRONG_MESSAGE: "Something went wrong",
  UPDATE_SUCCESS: "Integration Updated Successfully",
  MENU_TYPE: "Menu Type",
  URL_MENU: "URL",
  CHECK_MENU: "Check Menu",
  COCA_COLA_MENU: "Coca Cola Menu",
  SINQRO_ORDER_TYPE: "Order Type",
  GENERAL_YES: "Yes",
  GENERAL_NO: "No",
  CASH_PAY: "Pay with cash",
  ENABLE_RATING: "Enable Rating",
  RATING_URL: "Rating URL",
  LOYALTY_PROGRAM: "Loyalty Program",
  INVOICE_EXTRA_TEXT: "Receipt extra message",
  "SIDEBAR_MENUE.RATINGS": "Ratings",
  "GENERAL.RATING": "Reviews",
  AVERAGE_RATING: "Average Rating",
  RATING_RANGE: "Rating Range",
  SCHEDULE_ORDERS: "Schedule Orders",
  TAKE_AWAY_ORDERS: "Take Away Orders",
  NUMBER_OF_FUTURE_DAYS: "Days available for take away",
  STAFF: "Staff",
  "STAFF.LIST": "Staff List",
  "STAFF.CREATE": "Create Staff",
  "STAFF.UPDATE": "Edit Staff",
  REVO_REFERENCE: "Revo Reference",
  CLIENT_INFO: "Information",
  MODIFY_CLIENT_DETAILS: "Modify Form",
  INTEGRATION: "Integration",
  SALES_PERSON: "Salesperson",
  FUTURE_ORDERS: "Programado",
  "CREATE_COLLABORATOR.GROUP": "Create Collaborators Group",
  CREATE_GROUP: "Create Group",
  NO_GROUP: "No Group",
  ENABLE_CATALOGUE: "Enable Catalogue",
  ENABLE_MENU_V2: "Enable Menu V2",
  ADMIN: "Admin",
  TECHNICIAN: "Technician",
  QR_MENU: "QR Menu",
  REQUIRE_NAME: "Require Name",
  ASSIGN_COLLABORATOR: "Assign collaborator",
  COllABORATED_CREATED: "Collaborator User Created Successfully",
  COllABORATED_EDITED: "Collaborator User Edited Successfully",
  COllABORATED_REMOVED: "Collaborator User Removed Successfully",
  CONNECTION_ERROR: "There was an error connection",
  COLLABORATOR_ASSIGNED: "Collaborator Assigned Successfully",
  COLLABORATOR_GROUP_UN_ASSIGNED: "Collaborator Un Assigned Successfully",
  FOR_FREE: "for Free",
  CHECK_TABLES: "Check Tables",
  EXTRATARRIF: "Extra Tarif",
  ENABLE_STOCK: "Enable Stock",
  "NOTIFICATION.CREATE_ORDER_MESSAGE": "Message for order creation",
  "NOTIFICATION.TAKE_PARTIALLY_MESSAGE": "Message for partial deliveries",
  "NOTIFICATION.TAKE_FULLY_MESSAGE": "Message for full deliveries",
  SELECT_PAYMENT_PROVIDER: "Select Payment Provider",
  "NOTIFICATION.ERROR_STRING_MESSAGE": `Input data must contain "${SMS_NOTIFICATION_PATTERNS.ID}" mandatory`,
  "NOTIFICATION.SENDER_ID": "Sender ID",
  "NOTIFICATION.CLAVE_API": "Clave Api",
  HIDE_ORDER_BUTTON: "Hide Order Button",
  ENABLE_KIOSK: "Modo Kiosko",
  SELECT_TICKET_BAI: "Select Ticket Bai",
  TICKET_BAI: "Ticket Bai",
  LANGUAGES_MODAL: "Languages Modal",
  REQUIRE_ADDRESS: "Require Address",
  PAYMENT_ID: "Payment Id",
  FILTER_BY_DATE: "Filter By Date",
  REFOUND: "Refund",
  REFOUND_SUCCESS: "Refund Successfully",
  ALLOW_ORDER_WITH_ZERO_AMOUNT: "Allow orders with amount 0",
  EURO: "Euro",
  MEXICAN_PESO: "Mexican peso",
};
