export default {
  "HOME_PAGE.RESTAURANT": "餐厅",
  "HOME_PAGE.YOUR_CODE": "你的二维码",
  "ERRORS.IMAGE_IS_REQUIRED": "图片是必须项",
  "ERRORS.FIELD_IS_REQUIRED": "必填字段",
  "ERRORS.INVALID_EMAIL": "邮箱不正确",
  "ERRORS.INVALID_PHONE": "电话不正确",
  "ERRORS.INVALID_URL": "网址不正确",
  "ERRORS.INVALID_LOGIN": "登陆失败。 请再次尝试",
  "ERRORS.MINIMUM_10_CHAR": "最少10个字符",
  "ERRORS.MINIMUM_3_CHAR": "最少3个字符",
  "ERRORS.MINIMUM_1_CHAR": "最少1个字符",
  "ERRORS.MINIMUM_4_CHAR": "最少4个字符",
  "ERRORS.MINIMUM_6_CHAR": "最少6个字符",
  "ERRORS.EMAIL_TAKEN": "输入的邮箱已存在",
  "ERRORS.MUST_BE_NUMBER": "必须是一个数字",
  "GENERAL.CITY": "城市",
  "GENERAL.CREATE": "创建",
  "GENERAL.ADD_BUTTON": "添加",
  "GENERAL.BASIC_INFO": "基本信息",
  "GENERAL.CANCEL_BUTTON": "取消",
  "GENERAL.CLIENT": "客户",
  "GENERAL.CONFIRM_BUTTON": "确认",
  "GENERAL.CONTACT_INFO": "联系人信息",
  "GENERAL.DATE": "日期",
  "GENERAL.DELETE": "删除",
  "GENERAL.DENY_BUTTON": "取消",
  "GENERAL.DESCRIPTION": "描述",
  "GENERAL.DETAIL": "详情信息",
  "GENERAL.EMAIL": "邮箱",
  "GENERAL.IMAGE": "图片",
  "GENERAL.NAME": "名字",
  "GENERAL.PASSWORD": "密码",
  "GENERAL.PHONE": "电话",
  "GENERAL.POINTS": "积分",
  "GENERAL.POSTCODE": "邮编",
  "GENERAL.PRICE": "金额",
  "GENERAL.ROWS_PER_PAGE": "每页记录",
  "GENERAL.SAVE": "保存",
  "GENERAL.SAVE_PREVIEW": "保存并预览",
  "GENERAL.SAVE_BUTTON": "保存",
  "GENERAL.SEARCH": "搜索",
  "GENERAL.SEND": "发送",
  "GENERAL.TIME": "时间",
  "GENERAL.TITLE": "标题",
  "GENERAL.TYPE": "类型",
  "GENERAL.USER_LIST": "用户列表",
  "GENERAL.VENUE": "门店",
  "GENERAL.STATUS": "状态",
  "GENERAL.WEBSITE": "网站",
  "GENERAL.CATEGORY": "类别",
  "GENERAL.ITEMS": "项目",
  "GENERAL.ACTIONS": "操作",
  "GENERAL.REF": "Ref #",
  "GENERAL.ALERT_TITLE": "确认",
  "GENERAL.LATITUDE": "纬度",
  "GENERAL.LONGITUDE": "经度",
  "HOME_PAGE.PENDING_ORDERS": "待处理订单",
  "GENERAL.ALERT_DESC": "¿您确定要不保存更改就退出吗?",
  "GENERAL.REMOVE": "删除",
  "GENERAL.PLATFORM": "平台",
  "GENERAL.TO": "至",
  "GENERAL.YES": "是",
  "GENERAL.NO": "否",
  "GENERAL.VISIT_WEB": "访问网站",
  "GENERAL.ACCESS_CLIENT": "进入客户主页",
  "GENERAL.YOUR_CODE": "Tu codigo es",
  "GENERAL.COPY_CODE": "Copiar codigo",
  "GENERAL.ORDER_TYPE": "订单类型",
  "GENERAL.FIRST_NAME": "名字",
  "GENERAL.LAST_NAME": "姓氏",
  "GENERAL.RESTAURANT_NAME": "餐厅名称",
  "GENERAL.RESTAURANT_PHONE": "餐厅电话",
  "GENERAL.POST_CODE": "邮编",
  "GENERAL.ADDRESS": "地址",
  "GENERAL.COMMENTS": "评论",
  "GENERAL.EXPORT": "导出",
  "GENERAL.EXPORT_CSV": "下载 csv",
  "GENERAL.EXPORT_XLS": "下载 xls",
  "GENERAL.COMMISSIONS": "佣金",
  "GENERAL.RESTAURANT": "餐厅",
  "GENERAL.TABLE": "餐桌",
  "GENERAL.DATE_TIME": "日期和时间",
  "GENERAL.ROLE": "角色",
  "LOGIN_PAGE.LOGIN_BUTTON": "登录",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "登录到您的账户",
  "LOGIN_PAGE.REGISTER_LINK": "¿还没有账户？请注册",
  "LOGIN_PAGE.EMAIL": "邮箱",
  "LOGIN_PAGE.PASSWORD": "密码",
  "SIDEBAR_MENUE.HOME": "首页",
  "SIDEBAR_MENUE.MENUE_LIST": "菜单列表",
  "SIDEBAR_MENUE.MENUE_TITLE": "菜单",
  "SIDEBAR_MENUE.TITLE": "菜单",
  "SIDEBAR_MENUE.TRANSACTION": "交易列表",
  "SIDEBAR_MENUE.USER_LIST": "用户",
  "SIDEBAR_MENUE.CLIENT_LIST": "客户列表",
  "SIDEBAR_MENUE.CLIENT": "客户",
  "SIDEBAR_MENUE.ADD_CLIENT": "创建客户",
  "SIDEBAR_MENUE.REFERRALS": "Referidos",
  "SIDEBAR_MENUE.ORDERS": "订单",
  "SIDEBAR_MENUE.FAILED_PAYMENTS": "支付失敗",
  "SIDEBAR_MENUE.ORDERS_LIST": "订单列表",
  "SIDEBAR_MENUE.ANALYTICS": "数据分析",
  "USER_LIST_PAGE.TITLE": "用户列表",
  "USER_DETAIL.BACK_LIST": "用户列表",
  "REGISTER_PAGE.TITLE": "开始从未如此简单过",
  "REGISTER_PAGE.FIRST_NAME": "名字",
  "REGISTER_PAGE.LAST_NAME": "姓氏",
  "REGISTER_PAGE.EMAIL": "邮箱",
  "REGISTER_PAGE.TELEPHONE": "电话",
  "REGISTER_PAGE.PASSWORD": "密码",
  "REGISTER_PAGE.TERMS_CONDITIONS": "条款和条件",
  "REGISTER_PAGE.PRIVACY_POLICY": "隐私政策",
  "REGISTER_PAGE.ACCEPT": "接受",
  "REGISTER_PAGE.BUTTON_TEXT": "继续",
  "REGISTER_PAGE.LOGIN_LINK": "¿已有账户?",
  "REGISTER_PAGE.LOGIN": "登录",
  "REGISTER_PAGE.NEW_IN_BANZZU": "¿Banzzu的新用户?",
  "REGISTER_PAGE.SUB_TITLE_1": "征服您餐厅的数字宇宙 ",
  "REGISTER_PAGE.SUB_TITLE_2": "以及探索所有可能 ",
  "CLIENT.REGISTRATION_DATE": "注册日期",
  "CLIENT.SUBSCRIPTION": "订阅",
  "CLIENT.CLIENT_LIST": "客户列表",
  "CLIENT.CLIENT_DETAILS": "客户详情",
  "CLIENT.GOALS": "Goals",
  "CLIENT.ACCESS_LEVEL_PLACEHOLDER": "访问级别",
  "CLIENT.All": "所有",
  "CLIENT.REFERRED_BY": "Referido  por",
  "CLIENT.ACTIONS": "操作",
  "CLIENT.LAST_INTERACTION": "最后的更改",
  "CLIENT.ADD_CLIENT": "创建客户",
  "CLIENT.CREATED_TYPE": "手动创捷",
  "ORDER_LIST_PAGE.TITLE": "订单",
  "ORDER_LIST_PAGE.ALL_STATUS": "所有状态",
  "ORDER_LIST_PAGE.ALL_TYPES": "所有类型",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "选择状态",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "选择类型 ",
  "ORDER_PAGE.CUSTOMER": "客户",
  "ORDER_PAGE.EMAIL": "邮箱",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "名称",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "接受订单",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "取消",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "订单代取",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "订单已取",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "准备中",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "开始发货",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "完成",
  "ORDER_PAGE.EXTRAS": "其他",
  "ORDER_PAGE.ORDER": "订单",
  "ORDER_PAGE.ORDERS": "订单",
  "ORDER_PAGE.ORDER_DETAIL": "订单详情",
  "ORDER_PAGE.PHONE": "电话",
  "ORDER_PAGE.PRODUCTS": "商品",
  "ORDER_PAGE.QUANTITY": "数量",
  "ORDER_PAGE.DELIVERY": "送货地址",
  "ORDER_PAGE.COMMENTS": "评论",
  "ORDER_TYPE.DELIVERY": "送货上门",
  "ORDER_TYPE.COLLECT": "自提",
  "ORDER_TYPE.FUTURE_ORDER": "未来订单",
  "ORDER_TYPE.ORDER_FROM_TABLE": "餐桌点单",
  "ORDER_STATUS.ORDER_NUMBER": "订单编号",
  "ORDER_STATUS.UPDATED_AT": "创建日期",
  "ORDER_STATUS.PAYMENT_BY_CASH": "现金支付",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "支付成功",
  "ORDER_STATUS.ORDER_ACCEPTED": "下单成功",
  "ORDER_STATUS.PREPARING": "订单准备中",
  "ORDER_STATUS.ON_DELIVERY": "送货中",
  "ORDER_STATUS.DELIVERED": "已完成",
  "ORDER_STATUS.CANCELED": "已取消",
  "ORDER_STATUS.COLLECT": "订单代取",
  "ORDER_STATUS.COLLECTED": "订单已取",
  "ORDER_STATUS.ORDER_TIME": "订单时间",
  "ORDER_STATUS.ORDER_TABLE": "餐桌订单",
  "REFERRAL.REFERRAL_LIST": "Lista de Referidos",
  "HELP_MENU.CHANGE_PASSWORD": "更改密码",
  "HELP_MENU.LOGOUT": "退出",
  "ANALYTICS.WEEK_NUMBER": "周数",
  "ANALYTICS.FIDELITY": "会员计划",
  "ANALYTICS.ANALYTICS": "数据分析",
  "ANALYTICS.ENGAGEMENT": "Engagement",
  "ANALYTICS.ACTIVE_FREE_USERS": "免费活跃用户",
  "ANALYTICS.ACTIVE_PAID_USERS": "付费活跃用户",
  COLLABORATOR: "合伙人",
  "COLLABORATOR.LIST": "合伙人列表",
  "COLLABORATOR.GROUP": "合伙人群组",
  "COLLABORATOR.CREATE": "创建合伙人",
  "GENERAL.SURNAME": "姓氏",
  LOCALE: "语言",
  CONFIRM_PASSWORD: "确认密码",
  NEW_PASSWORD: "新密码",
  STRIPE_CONNECTED_ACCOUNT: "链接Stripe账户",
  STRIPE_REMOVE_CONFIRMATION: "确认删除",
  STRIPE_REMOVE_CONFIRMATION_MESSAGE: "¿确定要删除吗?",
  "VENUE_LIST.VENUE_LIST": "门店列表",
  "VENUE_LIST.CLIENT_NAME": "客户名称",
  "VENUE_LIST.VENUE_NAME": "门店名称",
  "VENUE_LIST.STATUS": "状态",
  "VENUE_LIST.MARK_AS_REAL": "标记为真实",
  "VENUE_LIST.MARK_AS_TEST": "标记为测试",
  "VENUE_STATUS.REAL": "真实",
  "VENUE_STATUS.TEST": "测试",
  "VENUE.SELECT_VENUE_STATUS": "Select Venue Statue",
  "VENUE_STATUS.NOT_COMPUTE": "不计算",
  "VENUE_STATUS.CLIENT_TESTING": "测试中",
  "VENUE_LIST.CHANGE_DESCRIPTION": "更改描述",
  "VENUE_LIST.DESCRIPTION": "描述",
  PARTIAL_DELIVERY: "部分已送",
  IN_PROGRESS: "制作中",
  DUPLICAR_MENU: "复制菜单",
  ORIGINAL_CLIENT: "起始客户",
  MENU: "菜单",
  DESTINATION_CLIENT: "目标客户",
  TWILIO_MESSAGES: "Twilio信息",
  MENU_DUPLICATE_SUCCESS: "菜单复制成功",
  DUPLICATE_BTN: "复制",
  MONTHS: "月份",
  MESSAGES: "信息",
  DATE: "日期",
  VENUE_DETAILS: "门店",
  RESTAURANT_CHARGES: "每笔交易的费用",
  ROZAN_SOCIAL: "公司名称",
  CIF: "CIF",
  AUTO_SERVICES: "上门自取服务",
  AUTO_SERVICES_SCREEN: "上门自取的屏幕",
  Virtual_Basket: "虚拟购物车",
  PAY_AT_TABLE: "餐桌支付",
  SERVICE: "其他服务",
  TAX: "赋税",
  TICKET_NUMBER: "票号",
  ORDER: "点单 ",
  ORDER_AND_PAY: "点单并支付",
  PENDIGN_ORDERS: "待支付",
  TIP: "小费",
  EMAIL: "邮箱",
  WHATSAPP: "Whatsapp",
  SMS: "短信",
  NOTIFICATION_METHOD: "通知方式",
  ENV_SWITCH: "支付测试",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER": "结束日期应晚于开始日期",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS": "开始日期应早于结束日期",
  POPUP_SWITCH: "启用弹出窗口",
  INTEGRATIONS: "嵌入点餐系统",
  NO_INTEGRATION_DEFAULT: "不嵌入点餐系统",
  WRONG_MESSAGE: "出来了点问题",
  UPDATE_SUCCESS: "嵌入更新成功",
  MENU_TYPE: "菜单类型",
  URL_MENU: "URL",
  CHECK_MENU: "Check 菜单",
  COCA_COLA_MENU: "可口可乐菜单",
  SINQRO_ORDER_TYPE: "订单类型",
  GENERAL_YES: "是",
  GENERAL_NO: "否",
  CASH_PAY: "现金支付",
  ENABLE_RATING: "启用评论",
  RATING_URL: "URL评级",
  LOYALTY_PROGRAM: "会员计划",
  INVOICE_EXTRA_TEXT: "收据附加信息",
  "SIDEBAR_MENUE.RATINGS": "评论",
  "GENERAL.RATING": "评论",
  AVERAGE_RATING: "平均评分",
  RATING_RANGE: "数值范围",
  SCHEDULE_ORDERS: "安排订单",
  TAKE_AWAY_ORDERS: "外带订单",
  NUMBER_OF_FUTURE_DAYS: "可外卖的天数",
  STAFF: "人员",
  "STAFF.LIST": "人员列表",
  "STAFF.CREATE": "创建人员",
  "STAFF.UPDATE": "编辑",
  REVO_REFERENCE: "Revo编号",
  CLIENT_INFO: "信息",
  MODIFY_CLIENT_DETAILS: "修改表单",
  INTEGRATION: "嵌入点餐系统",
  SALES_PERSON: "销售人员",
  FUTURE_ORDERS: "已计划",
  "CREATE_COLLABORATOR.GROUP": "创建合伙人群组",
  CREATE_GROUP: "创建一个组",
  NO_GROUP: "没有任何组",
  ENABLE_CATALOGUE: "启用目录",
  ENABLE_MENU_V2: "开启菜单V2",
  ADMIN: "管理员",
  TECHNICIAN: "技术员",
  QR_MENU: "二维码菜单",
  REQUIRE_NAME: "要求姓名",
  ASSIGN_COLLABORATOR: "指定合伙人",
  COllABORATED_CREATED: "合伙人创建成功",
  COllABORATED_EDITED: "合伙人编辑成功",
  COllABORATED_REMOVED: "合伙人删除成功",
  CONNECTION_ERROR: "网络连接错误",
  COLLABORATOR_ASSIGNED: "合伙人指定成功",
  COLLABORATOR_GROUP_UN_ASSIGNED: "合伙人没有指定成功",
  FOR_FREE: "免费",
  CHECK_TABLES: "Check餐桌",
  EXTRATARRIF: "额外费用",
  ENABLE_STOCK: "开启库存",
  "NOTIFICATION.CREATE_ORDER_MESSAGE": "创建订单的消息",
  "NOTIFICATION.TAKE_PARTIALLY_MESSAGE": "部分交货的信息",
  "NOTIFICATION.TAKE_FULLY_MESSAGE": "交货成功的消息",
  "NOTIFICATION.ERROR_STRING_MESSAGE": "Debe contener  obligatorio",
  HIDE_ORDER_BUTTON: "隐藏订单按钮",
  ENABLE_KIOSK: "启用自助服务模式",
  LANGUAGES_MODAL: "语言模式",
  REQUIRE_ADDRESS: "需要地址",
  SELECT_TICKET_BAI: "选择票白",
  TICKET_BAI: "票白",
  PAYMENT_ID: "付款 ID",
  FILTER_BY_DATE: "依日期篩選",
  REFOUND: "退款",
  REFOUND_SUCCESS: "退款成功",
  "GENERAL.CURRENCY_TYPE": "货币类型",
  ALLOW_ORDER_WITH_ZERO_AMOUNT: "允许金额为 0 的订单",
  EURO: "Euro",
  MEXICAN_PESO: "墨西哥比索",
};
