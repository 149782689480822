import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Switch,
  Icon,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import {
  createNotificationData,
  getLabelWith,
  getUpdatedNotificationList,
  removeNotifications,
  formatImageUrl,
  DASHBOARD_ROLES,
  orderTypeValue,
} from "app/helpers/utilsHelper";
import React, { useState, useEffect } from "react";
import { OrderTypeDropdown } from "../common/OrderTypeDropdown";
import { MenuTypeDropdown } from "../common/MenuTypeDropdown";
import { PayCashDropdown } from "../common/PayCashDropdown";
import { ScheduleOrderDropdown } from "../common/ScheduleOrderDropdown";
import { ByPassPaymentDropDown } from "../common/ByPassPaymentDropDown";
import { YesOrNoDropdown } from "../common/YesOrNoDropdown";
import TaxPicker from "../common/TaxPicker";
import { useDispatch, useSelector } from "react-redux";
import { FuseUtils } from "@fuse";
import { getVenuePopup } from "app/store/venuePopup/VenuePopupActions";
import { getUserRole } from "app/helpers/storageHelper";
import {
  DEFAULT_NUMBER_OF_FUTURE_DAYS,
  MAX_NUMBER_FUTURE_DAYS,
  MIN_NUMBER_FUTURE_DAYS,
} from "app/helpers/constants";
import NotificationMessagesInputs from "./components/NotificationMessages";

const allowedCashIntegrationTypes = [
  "none",
  "frontrest",
  "sinqroPos",
  "oracle",
  "revo",
  "agora",
  "hosteltactil",
];
const allowedCashOrderTypes = ["2", "3"];
const notificationsList = [
  {
    id: "1",
    name: "Whatsapp",
    language_key: "WHATSAPP",
  },
  {
    id: "2",
    name: "Email",
    language_key: "EMAIL",
  },
  {
    id: "3",
    name: "SMS",
    language_key: "SMS",
  },
];

export const VenueDetails = (props) => {
  const {
    selectedVenue,
    venueForm,
    hadleVenueChange,
    hadleNotificationChange = () => {},
    setForm,
    error = false,
    productId,
    tabValue,
  } = props;
  const dispatch = useDispatch();
  const client = useSelector(({ banzzu }) => banzzu.client.clientDetails);
  const { languageStrings } = useLanguageHelper();
  const {
    _id = "",
    restaurantCharges = "",
    cif = "",
    razonSocial = "",
    connectedAccountId = "",
    taxId = "",
    hasVirtualBasket = false,
    autoServices = false,
    orderType = "1",
    notifications = ["1", "2"],
    locationId = {},
    menuType = "",
    payCash = false,
    isRatingEnabled = false,
    ratingUrl = "",
    extraMessage = "",
    isLoyaltyEnabled = false,
    isScheduleOrdersEnabled = false,
    isRevoRefEnabled = false,
    isNameRequired = false,
    hideOrderButton = false,
    numberOfFutureDays = DEFAULT_NUMBER_OF_FUTURE_DAYS,
    paymentProvider = "STRIPE",
    isTicketBaiEnabled = false,
    ticketBai = "",
    isAddressRequired = false,
    byPassPayment = false,
  } = venueForm && venueForm.venueDetail ? venueForm.venueDetail : {};

  const venueOrderType = orderTypeValue(
    orderType,
    hasVirtualBasket,
    autoServices
  );
  const isOrderTypeAutoService = venueOrderType === "3";
  const isNotificationViaSMSSelected = notifications.includes("3");

  const [menuTypeValue, setMenuTypeValue] = useState(menuType);

  const { selectedNotifications = [] } = createNotificationData(
    notificationsList.filter((item) => {
      if (!isOrderTypeAutoService && item.id === "3") {
        return false;
      }
      return true;
    }),
    notifications
  );

  useEffect(() => {
    venueForm.venueDetail._id &&
      dispatch(getVenuePopup(venueForm.venueDetail._id));
  }, [dispatch, venueForm.venueDetail._id]);

  const venuePopup = useSelector((state) => state.banzzu.VenuePopupReducer);
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  useEffect(() => {
    venuePopup &&
      venueForm.venueDetail._id &&
      setForm((prevForm) => ({
        ...prevForm,
        popupData: {
          ...prevForm.popupData,
          venueId: venueForm.venueDetail._id,
          venuePopupActive: venuePopup.venuePopupActive,
          popupImage: venuePopup.popupImage,
          visitedUser: venuePopup.visitedUser,
        },
      }));
  }, [venuePopup, venueForm.venueDetail._id]);

  const handleValueChange = (key, value) => {
    hadleVenueChange(key, value);
  };
  function handleTaxChange(value, _tax) {
    handleValueChange("taxId", _tax);
  }

  function handleOrderTypeChange(value) {
    orderTypeValue(orderType, hasVirtualBasket, autoServices);
    hadleVenueChange("orderType", value);
  }
  function handleMenuTypeChange(value) {
    setMenuTypeValue(value);
    setForm((prevForm) => ({
      ...prevForm,
      venueDetail: {
        ...prevForm.venueDetail,
        menuType: value,
      },
    }));
  }
  const setNotification = (updatedList = []) => {
    hadleNotificationChange("notifications", updatedList);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const { updatedList } = getUpdatedNotificationList(value);
    setNotification(updatedList);
  };
  const onDelete = (value) => {
    const { updatedList } = removeNotifications(
      value.id,
      selectedNotifications
    );
    setNotification(updatedList);
  };

  const handlePopupChange = (event) => {
    const { checked } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      popupData: { ...prevForm.popupData, venuePopupActive: checked },
    }));
  };

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        setForm((prevForm) => ({
          ...prevForm,
          popupData: {
            ...prevForm.popupData,
            popupImage: `data:${file.type};base64,${btoa(reader.result)}`,
            popupImageFile: file,
          },
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  const handleAutoServiceFeature = (event) => {
    const { checked } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      autoServiceFeatureEnabled: checked,
    }));
  };

  return (
    <div>
      {selectedVenue && _id && (
        <>
          <div className="flex w-full justify-between items-center mb-12">
            <div className="flex items-center">
              <div className="flex items-center">
                <Typography>{languageStrings["ENABLE_RATING"]}</Typography>
                <Switch
                  checked={isRatingEnabled}
                  value={isRatingEnabled}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handleValueChange("isRatingEnabled", checked);
                  }}
                  size="small"
                  disabled={isSalesPerson}
                />
              </div>
              <div className="flex items-center ml-8">
                <Typography>{languageStrings["LOYALTY_PROGRAM"]}</Typography>
                <Switch
                  checked={isLoyaltyEnabled}
                  value={isLoyaltyEnabled}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handleValueChange("isLoyaltyEnabled", checked);
                  }}
                  size="small"
                  disabled={isSalesPerson}
                />
              </div>
              <div className="flex items-center ml-8">
                <Typography>{languageStrings["TICKET_BAI"]}</Typography>
                <Switch
                  checked={isTicketBaiEnabled}
                  value={isTicketBaiEnabled}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handleValueChange("isTicketBaiEnabled", checked);
                  }}
                  size="small"
                  disabled={isSalesPerson}
                />
              </div>
              {venueOrderType === "3" && JSON.parse(isScheduleOrdersEnabled) ? (
                <div className="flex items-center ml-8">
                  <Typography>
                    {languageStrings["HIDE_ORDER_BUTTON"]}
                  </Typography>
                  <Switch
                    checked={hideOrderButton}
                    value={hideOrderButton}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      handleValueChange("hideOrderButton", checked);
                    }}
                    size="small"
                    disabled={isSalesPerson}
                  />
                </div>
              ) : null}
            </div>
            <div className="flex items-center">
              <Typography>{languageStrings["POPUP_SWITCH"]}</Typography>
              {venuePopup && (
                <Switch
                  checked={venueForm.popupData.venuePopupActive}
                  value={venueForm.popupData.venuePopupActive}
                  onChange={(event) => handlePopupChange(event)}
                  size="small"
                  disabled={isSalesPerson}
                />
              )}
            </div>
          </div>
          <div className="flex">
            <TextField
              className="mt-8 mb-16 mr-8"
              label={languageStrings["CIF"]}
              id="cif"
              name="cif"
              value={cif}
              onChange={(event) => {
                handleValueChange("cif", event.target.value);
              }}
              inputProps={{
                maxLength: 100,
              }}
              variant="outlined"
              fullWidth
              disabled={isSalesPerson}
            />
            <TextField
              className="mt-8 mb-16 ml-8"
              label={languageStrings["ROZAN_SOCIAL"]}
              id="razonSocial"
              name="razonSocial"
              value={razonSocial}
              onChange={(event) => {
                handleValueChange("razonSocial", event.target.value);
              }}
              variant="outlined"
              fullWidth
              disabled={isSalesPerson}
            />
          </div>

          <div className="flex">
            <FormControl
              fullWidth
              variant="outlined"
              className={`flex justify-center mb-12 mr-8`}
            >
              <InputLabel
                className={"mr-12"}
                htmlFor="outlined-venue-native-simple"
              >
                {languageStrings["SELECT_PAYMENT_PROVIDER"]}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label={languageStrings["SELECT_PAYMENT_PROVIDER"]}
                id="demo-multiple-checkbox"
                value={paymentProvider}
                onChange={(e) =>
                  hadleVenueChange("paymentProvider", e.target.value)
                }
                input={
                  <OutlinedInput
                    name="paymentProvider"
                    labelWidth={
                      getLabelWith(
                        languageStrings["SELECT_PAYMENT_PROVIDER"],
                        6.8
                      ).labelWith
                    }
                    id="outlined-venue-native-simple"
                  />
                }
              >
                <MenuItem value="STRIPE">Stripe</MenuItem>
                <MenuItem value="SQUARE">Square</MenuItem>
              </Select>
            </FormControl>
            {isTicketBaiEnabled && (
              <FormControl
                fullWidth
                variant="outlined"
                className={`flex justify-center mb-12 ml-8`}
              >
                <InputLabel
                  className={"mr-12"}
                  htmlFor="outlined-venue-native-simple"
                >
                  {languageStrings["SELECT_TICKET_BAI"]}
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  label={languageStrings["SELECT_TICKET_BAI"]}
                  id="demo-multiple-checkbox"
                  value={ticketBai}
                  onChange={(e) =>
                    hadleVenueChange("ticketBai", e.target.value)
                  }
                  input={
                    <OutlinedInput
                      name="ticketBai"
                      labelWidth={
                        getLabelWith(languageStrings["SELECT_TICKET_BAI"], 6.8)
                          .labelWith
                      }
                      id="outlined-venue-native-simple"
                    />
                  }
                >
                  <MenuItem value="ALAVA">Alava</MenuItem>
                  <MenuItem value="BIZKAIA">Bizkaia</MenuItem>
                  <MenuItem value="GIPUZKOA">Gipuzkoa</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div className="flex">
            <TextField
              className="mt-8 mb-16 mr-8"
              label={`${languageStrings["RESTAURANT_CHARGES"]}`}
              id="restaurantCharges"
              name="restaurantCharges"
              value={restaurantCharges}
              onChange={(event) => {
                const val =
                  event && event.target && event.target.value
                    ? event.target.value
                    : "";
                handleValueChange("restaurantCharges", val);
              }}
              variant="outlined"
              multiline
              fullWidth
              disabled={isSalesPerson}
            />
            {paymentProvider === "STRIPE" ? (
              <TextField
                className="mt-8 mb-16 ml-8"
                required
                label={languageStrings["STRIPE_CONNECTED_ACCOUNT"]}
                id="connectedAccountId"
                name="connectedAccountId"
                value={connectedAccountId || ""}
                onChange={(event) => {
                  handleValueChange("connectedAccountId", event.target.value);
                }}
                variant="outlined"
                fullWidth
                disabled={isSalesPerson}
              />
            ) : null}
          </div>
          <div className="flex mt-8 mb-16">
            <TaxPicker value={taxId} onChange={handleTaxChange} />
            <div className=" ml-16" />
            <OrderTypeDropdown
              value={orderTypeValue(orderType, hasVirtualBasket, autoServices)}
              onChange={handleOrderTypeChange}
            />
          </div>
          {client && venueOrderType === "1" ? (
            <div className="flex mt-8 mb-16">
              <ByPassPaymentDropDown
                value={byPassPayment}
                onChange={handleValueChange}
              />
            </div>
          ) : null}
          <div className="flex mt-8 mb-16">
            {client &&
              allowedCashOrderTypes.includes(venueOrderType) &&
              allowedCashIntegrationTypes.includes(client.integrationType) && (
                <>
                  <PayCashDropdown
                    value={payCash}
                    onChange={handleValueChange}
                  />
                  <div className=" ml-16" />
                </>
              )}
            {client && venueOrderType === "3" ? (
              <ScheduleOrderDropdown
                value={isScheduleOrdersEnabled}
                onChange={handleValueChange}
              />
            ) : null}
          </div>
          {JSON.parse(isScheduleOrdersEnabled) ? (
            <div className="flex my-20">
              <TextField
                className="mt-8 mb-16"
                type="number"
                required
                label={languageStrings["NUMBER_OF_FUTURE_DAYS"]}
                id="numberOfFutureDays"
                name="numberOfFutureDays"
                value={
                  numberOfFutureDays !== null &&
                  numberOfFutureDays !== undefined
                    ? numberOfFutureDays
                    : DEFAULT_NUMBER_OF_FUTURE_DAYS
                }
                onChange={(event) => {
                  handleValueChange("numberOfFutureDays", event.target.value);
                }}
                variant="outlined"
                fullWidth
                inputProps={{
                  min: MIN_NUMBER_FUTURE_DAYS,
                  max: MAX_NUMBER_FUTURE_DAYS,
                }}
              />
            </div>
          ) : (
            ""
          )}
          {client && venueOrderType === "3" ? (
            <div className="-mt-12">
              <YesOrNoDropdown
                value={isNameRequired}
                onChange={handleValueChange}
                style={{ marginBottom: 24 }}
                label={languageStrings["REQUIRE_NAME"]}
                name={"isNameRequired"}
                propertyValue={"isNameRequired"}
                labelWidth={108}
              />
            </div>
          ) : null}
          {client && venueOrderType === "3" ? (
            <div className="-mt-12">
              <YesOrNoDropdown
                value={isAddressRequired}
                onChange={handleValueChange}
                style={{ marginBottom: 24 }}
                label={languageStrings["REQUIRE_ADDRESS"]}
                name={"isAddressRequired"}
                propertyValue={"isAddressRequired"}
                labelWidth={108}
              />
            </div>
          ) : null}

          {venueOrderType === "3" && client.integrationType === "revo" && (
            <div>
              <YesOrNoDropdown
                value={isRevoRefEnabled}
                onChange={handleValueChange}
                style={{ marginBottom: 24 }}
                label={languageStrings["REVO_REFERENCE"]}
                name={"isRevoRefEnabled"}
                propertyValue={"isRevoRefEnabled"}
                labelWidth={112}
              />
            </div>
          )}
          <div className="flex pb-10 px-5">
            {venueOrderType === "3" && (
              <div className="flex items-center">
                <Typography>
                  {languageStrings["AUTO_SERVICES_SCREEN"]}
                </Typography>
                {venuePopup && (
                  <Switch
                    checked={venueForm.autoServiceFeatureEnabled}
                    value={venueForm.autoServiceFeatureEnabled}
                    onChange={(event) => handleAutoServiceFeature(event)}
                    size="small"
                    disabled={isSalesPerson}
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col items-stretch">
            {venueOrderType === "3" ? (
              <>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={`flex justify-center `}
                  style={{ marginTop: -7 }}
                >
                  <InputLabel htmlFor="outlined-venue-native-simple">
                    {languageStrings["NOTIFICATION_METHOD"]}
                  </InputLabel>
                  <Select
                    disabled={isSalesPerson}
                    error={error}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedNotifications}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        name={languageStrings["NOTIFICATION_METHOD"]}
                        labelWidth={
                          getLabelWith(
                            languageStrings["NOTIFICATION_METHOD"],
                            6.8
                          ).labelWith
                        }
                        id="outlined-venue-native-simple"
                      />
                    }
                    renderValue={(selected) =>
                      selected.map((x) => (
                        <Chip
                          className="ml-4"
                          label={languageStrings[x.language_key]}
                          variant="outlined"
                          onDelete={() => {
                            if (isSalesPerson) {
                              return;
                            }
                            onDelete(x);
                          }}
                        />
                      ))
                    }
                  >
                    {notificationsList
                      .filter((item) => {
                        if (!isOrderTypeAutoService && item.id === "3") {
                          return false;
                        }
                        return true;
                      })
                      .map((variant) => (
                        <MenuItem key={variant.id} value={variant}>
                          <Checkbox
                            checked={
                              selectedNotifications.findIndex(
                                (item) => item.id === variant.id
                              ) >= 0
                            }
                          />
                          <ListItemText
                            primary={languageStrings[variant.language_key]}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                  {error ? (
                    <FormHelperText className="text-red">
                      {languageStrings["ERRORS.INVALID_EMAIL"]}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                {isNotificationViaSMSSelected && (
                  <NotificationMessagesInputs
                    handleChange={handleValueChange}
                    venueDetails={
                      venueForm && venueForm.venueDetail
                        ? venueForm.venueDetail
                        : {}
                    }
                    languageStrings={languageStrings}
                    selectedVenue={
                      client &&
                      client.venues &&
                      client.venues.find(
                        (item) => item._id === venueForm.venueDetail._id
                      )
                    }
                    error={error}
                  />
                )}
              </>
            ) : null}
            {client && client.integrationType === "deliverect" ? (
              <TextField
                className="mt-8 mb-16 mr-8"
                label={"Location Id"}
                id="locationId"
                name="Location Id"
                value={locationId}
                onChange={(event) => {
                  handleValueChange("locationId", event.target.value);
                }}
                inputProps={{
                  maxLength: 100,
                }}
                variant="outlined"
                fullWidth
                disabled={isSalesPerson}
              />
            ) : null}
          </div>
          <div className="flex">
            {venueOrderType === "5" && (
              <MenuTypeDropdown
                value={menuType ? menuType : "2"}
                onChange={handleMenuTypeChange}
              />
            )}
          </div>
          {isRatingEnabled ? (
            <div className="flex mt-16">
              <TextField
                className="mt-8 mb-16"
                label={`${languageStrings["RATING_URL"]}`}
                id="ratingUrl"
                name="ratingUrl"
                value={ratingUrl}
                onChange={(event) => {
                  const val =
                    event && event.target && event.target.value
                      ? event.target.value
                      : "";
                  handleValueChange("ratingUrl", val);
                }}
                variant="outlined"
                multiline
                fullWidth
                disabled={isSalesPerson}
              />
            </div>
          ) : null}
          <div className="flex">
            <TextField
              className="mt-8 mb-16"
              label={`${languageStrings["INVOICE_EXTRA_TEXT"]}`}
              id="extraMessage"
              name="extraMessage"
              value={extraMessage}
              onChange={(event) => {
                const val =
                  event && event.target && event.target.value
                    ? event.target.value
                    : "";
                handleValueChange("extraMessage", val);
              }}
              variant="outlined"
              multiline
              fullWidth
              disabled={isSalesPerson}
            />
          </div>

          {venueForm.popupData.venuePopupActive && (
            <div>
              <input
                accept="image/jpg, image/jpeg, image/png, image/gif"
                className="hidden"
                id="button-file"
                type="file"
                onChange={(e) => handleUploadChange(e)}
                disabled={isSalesPerson}
              />
              <div className="flex justify-center sm:justify-start flex-row mt-24">
                <label
                  htmlFor="button-file"
                  className={
                    "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
                  }
                >
                  <Icon fontSize="large" color="action">
                    cloud_upload
                  </Icon>
                </label>
                <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                  <img
                    className="max-w-none w-auto h-full"
                    alt="popup"
                    src={
                      venueForm.popupData.popupImage
                        ? formatImageUrl(venueForm.popupData.popupImage)
                        : "assets/images/avatars/noimage.png"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
