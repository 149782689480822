import React, { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import { getCollaboratorGroupList } from "app/store/collaboratorGroup/CollaboratorGroupActions";

function CreateGroupDropDown({
  value,
  handleChange,
  showNoOption = false,
  style = {
    minWidth: "50%",
    maxWidth: "50%",
  },
}) {
  const { collaboratorGroups } = useSelector(
    (state) => state.banzzu.CollaboratorGroup
  );
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaboratorGroupList());
  }, []);

  const _collaboratorGroups =
    collaboratorGroups && collaboratorGroups.length ? collaboratorGroups : [];

  return (
    <FormControl className="ml-8 mt-8 mb-16" style={style}>
      <InputLabel className="ml-12 -mt-8" id="demo-simple-select-label">
        {languageStrings["COLLABORATOR.GROUP"]}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={value}
        name="group"
        id="group"
        label={languageStrings["COLLABORATOR.GROUP"]}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={languageStrings["COLLABORATOR.GROUP"]}
            labelWidth={180}
          />
        }
      >
        {showNoOption ? (
          <MenuItem style={{ color: "grey" }} value={""}>
            {languageStrings["NO_GROUP"]}
          </MenuItem>
        ) : null}
        {_collaboratorGroups.map((item) => (
          <MenuItem value={item._id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CreateGroupDropDown;
