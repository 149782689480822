import React, { useEffect, useState } from "react";
import {
  Icon,
  Tab,
  Tabs,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Link } from "react-router-dom";
import withReducer from "app/store/withReducer";
import LoadingOverlay from "react-loading-overlay";
import clsx from "clsx";

import reducer from "app/store/client/ClientReducer";
import { getClientDetails, updateClient } from "app/store/client/ClientActions";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import VenueListCard from "./components/VenuesListCard";
import CollaboratorDetailCard from "./components/StaffDetailCard";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));
const CollaboratorDetailPage = (props) => {
  const classes = useStyles("");
  const { languageStrings } = useLanguageHelper();
  const { productId } = props.match.params;
  const dispatch = useDispatch();
  const client = useSelector(({ client }) => client.clientDetails);

  const loading = useSelector(({ client }) => client.loading);
  const [tabValue, setTabValue] = useState(0);
  const [comments, setComment] = useState(client.comments);

  useEffect(() => {
    dispatch(getClientDetails(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    setComment(client.comments);
  }, [client]);
  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full">
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Typography
                  className="normal-case flex items-center sm:mb-12"
                  component={Link}
                  role="button"
                  to={"/collaboratorsList"}
                  color="inherit"
                >
                  <Icon className="mr-4 text-20">arrow_back</Icon>
                  {languageStrings["COLLABORATOR.LIST"]}
                </Typography>
              </FuseAnimate>
            </div>
            <div className="flex flex-col min-w-0 items-center sm:items-start">
              {getStorageItem(STORAGE_KEY.IS_ADMIN) == "true" && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    onClick={() =>
                      dispatch(
                        updateClient({
                          id: client._id,
                          comments,
                        })
                      )
                    }
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              )}
            </div>
          </div>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["COLLABORATOR.DETAIL"]}
            />
          </Tabs>
        }
        content={
          <div className="p-16 sm:p-24 w-full">
            {tabValue === 0 && (
              <div>
                {client && (
                  <>
                    <div className="flex flex-col">
                      <CollaboratorDetailCard clientDetails={client} />

                      <VenueListCard venues={client.venues} />

                      {getStorageItem(STORAGE_KEY.IS_ADMIN) == "true" && (
                        <TextField
                          className=""
                          required
                          label={languageStrings["GENERAL.COMMENTS"]}
                          id="comments"
                          name="comments"
                          value={comments || ""}
                          onChange={(e) => setComment(e.currentTarget.value)}
                          variant="outlined"
                          multiline
                          rows={4}
                          fullWidth
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("ClientReducer", reducer)(CollaboratorDetailPage);
