import { PosIntegrationActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: PosState = {
  loading: false,
  integrationType: "none",
  currentInputs: [],
  integrationList: [],
};

const PosReducer = (state: PosState = INITIAL_STATE, action) => {
  switch (action.type) {
    case PosIntegrationActionTypes.GET_POS_INPUTS_START: {
      return {
        ...state,
        loading: true,
        currentInputs: [],
        integrationType: "none",
      };
    }
    case PosIntegrationActionTypes.GET_POS_INPUTS_SUCCESS: {
      return {
        ...state,
        integrationType: action.payload.posType,
        currentInputs: action.payload.inputs,
        integrationList: action.payload.integrationList,
        loading: false,
      };
    }
    case PosIntegrationActionTypes.GET_POS_INPUTS_FAIL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export default PosReducer;
