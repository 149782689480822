import RatingList from "./RatingList";

export const RatingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/rating",
      component: RatingList,
    },
  ],
};
