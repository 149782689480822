import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "app/store/auth/AuthActions";
import History from "@history";
import { useLanguageHelper } from "../../helpers/LanguageHelper/index";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { locale, email = "" } = useSelector(({ banzzu }) => banzzu.auth.user);
  const { changeLanguage, languageStrings } = useLanguageHelper();
  const userEmail = getStorageItem(STORAGE_KEY.USER_EMAIL);
  const [userMenu, setUserMenu] = useState(null);
  const [languageMenu, setLanguageMenu] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("es");

  useEffect(() => {
    if (locale) {
      setSelectedLanguage(locale);
    }
  }, [locale]);
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };
  const languageSelectorClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const changeUserLanguage = (language) => {
    changeLanguage(language);
    setSelectedLanguage(language);
    languageMenuClose();
  };

  const languageMenuClose = () => {
    setLanguageMenu(null);
  };
  return (
    <React.Fragment>
      <Button className="h-64" onClick={userMenuClick}>
        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {userEmail}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          <MenuItem
            onClick={() => {
              History.push({
                pathname: `/changePassword`,
              });
              userMenuClose();
            }}
          >
            <ListItemIcon className="min-w-40">
              <Icon>lock</Icon>
            </ListItemIcon>
            <ListItemText
              className="pl-0"
              primary={languageStrings["HELP_MENU.CHANGE_PASSWORD"]}
            />
          </MenuItem>
        </React.Fragment>
        <React.Fragment>
          <MenuItem
            onClick={() => {
              dispatch(userLogout());
              userMenuClose();
            }}
          >
            <ListItemIcon className="min-w-40">
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText
              className="pl-0"
              primary={languageStrings["HELP_MENU.LOGOUT"]}
            />
          </MenuItem>
        </React.Fragment>
      </Popover>
      <Button className="h-64" onClick={languageSelectorClick}>
        <img src={`assets/images/flags/${selectedLanguage}.png`} />
        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography
            component="span"
            className="normal-case font-600 flex text-upercase"
          >
            {selectedLanguage}
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(languageMenu)}
        anchorEl={languageMenu}
        onClose={languageMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          <MenuItem onClick={() => changeUserLanguage("en")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/en.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="English" />
          </MenuItem>

          <MenuItem onClick={() => changeUserLanguage("es")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/es.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="Spanish" />
          </MenuItem>
          <MenuItem onClick={() => changeUserLanguage("fr")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/fr.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="French" />
          </MenuItem>
          <MenuItem onClick={() => changeUserLanguage("zh")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/zh.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="Chinese" />
          </MenuItem>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
