export const AuthActionTypes = {
  USER_INFO_START: "USER_INFO_START",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
  USER_INFO_FAIL: "USER_INFO_FAIL",

  CHANGE_LANGUAGE_START: "CHANGE_LANGUAGE_START",
  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_FAIL: "CHANGE_LANGUAGE_FAIL",

  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  LOGOUT_USER_START: "LOGOUT_USER_START",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAIL: "LOGOUT_USER_FAIL",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  REGISTER_USER_START: "REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",

  SET_REFERRALS_SEARCH_TEXT: "SET_REFERRALS_SEARCH_TEXT",

  REFERRALS_LIST_START: "REFERRALS_LIST_START",
  REFERRALS_LIST_SUCCESS: "REFERRALS_LIST_SUCCESS",
  REFERRALS_LIST_FAIL: "REFERRALS_LIST_FAIL",
};

export const ClientActionTypes = {
  CLIENT_LIST_START: "CLIENT_LIST_START",
  CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
  CLIENT_LIST_FAIL: "CLIENT_LIST_FAIL",

  CLIENT_DETAILS_START: "CLIENT_DETAILS_START",
  CLIENT_DETAILS_SUCCESS: "CLIENT_DETAILS_SUCCESS",
  CLIENT_DETAILS_FAIL: "CLIENT_DETAILS_FAIL",

  UPDATE_CLIENT_START: "UPDATE_CLIENT_START",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAIL: "UPDATE_CLIENT_FAIL",

  UPDATE_CHECK_CLIENT_START: "UPDATE_CHECK_CLIENT_START",
  UPDATE_CHECK_CLIENT_SUCCESS: "UPDATE_CHECK_CLIENT_SUCCESS",
  UPDATE_CHECK_CLIENT_FAIL: "UPDATE_CHECK_CLIENT_FAIL",

  CREATE_CLIENT_START: "CREATE_CLIENT_START",
  CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAIL: "CREATE_CLIENT_FAIL",

  ACCESS_CLIENT_START: "ACCESS_CLIENT_START",
  ACCESS_CLIENT_SUCCESS: "ACCESS_CLIENT_SUCCESS",
  ACCESS_CLIENT_FAIL: "ACCESS_CLIENT_FAIL",

  SET_CLIENT_SEARCH_TEXT: "SET_CLIENT_SEARCH_TEXT",

  CHECK_EMAIL_START: "CHECK_EMAIL_START",
  CHECK_EMAIL_FAIL: "CHECK_EMAIL_FAIL",
  CHECK_EMAIL_SUCCESS: "CHECK_EMAIL_SUCCESS",

  CLIENT_DELETE_START: "CLIENT_DELETE_START",
  CLIENT_DELETE_SUCCESS: "CLIENT_DELETE_SUCCESS",
  CLIENT_DELETE_FAIL: "CLIENT_DELETE_FAIL",

  GET_CLIENT_VENUE_DETAIL_START: "GET_CLIENT_VENUE_DETAIL_START",
  GET_CLIENT_VENUE_DETAIL_SUCCESS: "GET_CLIENT_VENUE_DETAIL_SUCCESS",
  GET_CLIENT_VENUE_DETAIL_FAIL: "GET_CLIENT_VENUE_DETAIL_FAIL",

  GET_TAXES_START: "GET_TAXES_START",
  GET_TAXES_SUCCESS: "GET_TAXES_SUCCESS",
  GET_TAXES_FAIL: "GET_TAXES_FAIL",

  UPDATE_VENUE_START: "UPDATE_VENUE_START",
  UPDATE_VENUE_SUCCESS: "UPDATE_VENUE_SUCCESS",
  UPDATE_VENUE_FAIL: "UPDATE_VENUE_FAIL",

  GET_CURRENCIES_START: "GET_CURRENCIES_START",
  GET_CURRENCIES_SUCCESS: "GET_CURRENCIES_SUCCESS",
  GET_CURRENCIES_FAIL: "GET_CURRENCIES_FAIL",
};

export const OrderActionTypes = {
  ORDERS_LIST_START: "ORDERS_LIST_START",
  ORDERS_LIST_SUCCESS: "ORDERS_LIST_SUCCESS",
  ORDERS_LIST_FAIL: "ORDERS_LIST_FAIL",

  EXPORT_ORDERS_LIST_START: "EXPORT_ORDERS_LIST_START",
  EXPORT_ORDERS_LIST_SUCCESS: "EXPORT_ORDERS_LIST_SUCCESS",
  EXPORT_ORDERS_LIST_FAIL: "EXPORT_ORDERS_LIST_FAIL",

  SET_ORDER_SEARCH_TEXT: "SET_ORDER_SEARCH_TEXT",

  ORDER_DETAIL_START: "ORDER_DETAIL_START",
  ORDER_DETAIL_SUCCESS: "ORDER_DETAIL_SUCCESS",
  ORDER_DETAIL_FAIL: "ORDER_DETAIL_FAIL",

  DELETE_ORDERS_START: "DELETE_ORDERS_START",
  DELETE_ORDERS_SUCCESS: "DELETE_ORDERS_SUCCESS",
  DELETE_ORDER_FAIL: "DELETE_ORDER_FAIL",
};

export const AnalyticsActionTypes = {
  GET_ANALYTICS_LIST_START: "GET_ANALYTICS_LIST_START",
  GET_ANALYTICS_LIST_SUCCESS: "GET_ANALYTICS_LIST_SUCCESS",
  GET_ANALYTICS_LIST_FAIL: "GET_ANALYTICS_LIST_FAIL",

  SET_ANALYTICS_SEARCH_TEXT: "SET_ANALYTICS_SEARCH_TEXT",
};

export const CollaboraterActionTypes = {
  GET_COLLABORATOR_LIST_START: "GET_COLLABORATOR_LIST_START",
  GET_COLLABORATOR_LIST_SUCCESS: "GET_COLLABORATOR_LIST_SUCCESS",
  GET_COLLABORATOR_LIST_FAIL: "GET_COLLABORATOR_LIST_FAIL",

  SET_COLLABORATOR_LIST_START: "SET_COLLABORATOR_LIST_START",
  SET_COLLABORATOR_LIST_SUCCESS: "SET_COLLABORATOR_LIST_SUCCESS",
  SET_COLLABORATOR_LIST_FAIL: "SET_COLLABORATOR_LIST_FAIL",
};

export const VenuesActionTypes = {
  SET_VENUE_SEARCH_TEXT: "SET_VENUE_SEARCH_TEXT",

  GET_VENUE_LIST_START: "GET_VENUE_LIST_START",
  GET_VENUE_LIST_SUCCESS: "GET_VENUE_LIST_SUCCESS",
  GET_VENUE_LIST_FAIL: "GET_VENUE_LIST_FAIL",

  UPDATE_VENUE_STATUS_START: "UPDATE_VENUE_STATUS_START",
  UPDATE_VENUE_STATUS_SUCCESS: "UPDATE_VENUE_STATUS_SUCCESS",
  UPDATE_VENUE_STATUS_FAIL: "UPDATE_VENUE_STATUS_FAIL",

  GET_VENUE_DETAIL_START: "GET_VENUE_DETAIL_START",
  GET_VENUE_DETAIL_SUCCESS: "GET_VENUE_DETAIL_SUCCESS",
  GET_VENUE_DETAIL_FAIL: "GET_VENUE_DETAIL_FAIL",
};

export const DublicarMenuActionTypes = {
  GET_DUBLICAR_MENU_LIST_START: "GET_DUBLICAR_MENU_LIST_START",
  GET_DUBLICAR_MENU_LIST_SUCCESS: "GET_DUBLICAR_MENU_LIST_SUCCESS",
  GET_DUBLICAR_MENU_LIST_FAIL: "GET_DUBLICAR_MENU_LIST_FAIL",

  SET_DUBLICAR_MENU_LIST_START: "SET_DUBLICAR_MENU_LIST_START",
  SET_DUBLICAR_MENU_LIST_SUCCESS: "SET_DUBLICAR_MENU_LIST_SUCCESS",
  SET_DUBLICAR_MENU_LIST_FAIL: "SET_DUBLICAR_MENU_LIST_FAIL",
};

export const TwilioMessagesActionTypes = {
  GET_TWILIO_MESSAGES_LIST_START: "GET_TWILIO_MESSAGES_LIST_START",
  GET_TWILIO_MESSAGES_LIST_SUCCESS: "GET_TWILIO_MESSAGES_LIST_SUCCESS",
  GET_TWILIO_MESSAGES_LIST_FAIL: "GET_TWILIO_MESSAGES_LIST_FAIL",

  SET_TWILIO_MESSAGES_SEARCH_TEXT: "SET_TWILIO_MESSAGES_SEARCH_TEXT",
};

export const PosIntegrationActionTypes = {
  GET_POS_INPUTS: "GET_POS_INPUTS",
  GET_POS_INPUTS_START: "GET_POS_LIST_START",
  GET_POS_INPUTS_SUCCESS: "GET_POS_INPUTS_SUCCESS",
  GET_POS_INPUTS_FAIL: "GET_POS_INPUTS_FAIL",
  UPDATE_POS_VALUES: "UPDATE_POS_VALUES",
  UPDATE_POS: "UPDATE_POS",
  GET_CLIENT_INTEGRATION: "GET_CLIENT_INTEGRATION",
};

export const venuePopupActionTypes = {
  UPDATE_VENUE_POPUP: "UPDATE_VENUE_POPUP",
  UPDATE_VENUE_POPUP_SUCCESS: "UPDATE_VENUE_POPUP_SUCCESS",
  UPDATE_VENUE_POPUP_FAIL: "UPDATE_VENUE_POPUP_FAIL",
  GET_VENUE_POPUP: "GET_VENUE_POPUP",
  GET_VENUE_POPUP_SUCCESS: "GET_VENUE_POPUP_SUCCESS",
  GET_VENUE_POPUP_FAIL: "GET_VENUE_POPUP_FAIL",
};

export const paginationActionTypes = {
  SET_LIST_LIMIT_PAGE: "SET_LIST_LIMIT_PAGE",
};
export const RatingActionTypes = {
  RATINGS_LIST_START: "RATINGS_LIST_START",
  RATINGS_LIST_SUCCESS: "RATINGS_LIST_SUCCESS",
  RATINGS_LIST_FAIL: "RATINGS_LIST_FAIL",

  SET_RATINGS_SEARCH_TEXT: "SET_RATINGS_SEARCH_TEXT",

  DELETE_RATINGS_START: "DELETE_RATINGS_START",
  DELETE_RATINGS_SUCCESS: "DELETE_RATINGS_SUCCESS",
  DELETE_RATINGS_FAIL: "DELETE_RATINGS_FAIL",
};
export const FailedPaymentsActionTypes = {
  FAILED_PAYMENTS_START: "FAILED_PAYMENTS_START",
  FAILED_PAYMENTS_SUCCESS: "FAILED_PAYMENTS_SUCCESS",
  FAILED_PAYMENTS_FAIL: "FAILED_PAYMENTS_FAIL",

  SET_FAILED_PAYMENTS_SEARCH_TEXT: "SET_FAILED_PAYMENTS_SEARCH_TEXT",

  FAILED_PAYMENTS_REFUND_START: "FAILED_PAYMENTS_REFUND_START",
  FAILED_PAYMENTS_REFUND_SUCCESS: "FAILED_PAYMENTS_REFUND_SUCCESS",
  FAILED_PAYMENTS_REFUND_FAIL: "FAILED_PAYMENTS_FAIL",
};
export const StaffActionTypes = {
  STAFF_SEARCH_TEXT: "STAFF_SEARCH_TEXT",

  STAFF_LIST_START: "STAFF_LIST_START",
  STAFF_LIST_SUCCESS: "STAFF_LIST_SUCCESS",
  STAFF_LIST_FAIL: "STAFF_LIST_FAIL",

  STAFF_DETAIL_START: "STAFF_DETAIL_START",
  STAFF_DETAIL_SUCCESS: "STAFF_DETAIL_SUCCESS",
  STAFF_DETAIL_FAIL: "STAFF_DETAIL_FAIL",

  CREATE_STAFF_START: "CREATE_STAFF_START",
  CREATE_STAFF_SUCCESS: "CREATE_STAFF_SUCCESS",
  CREATE_STAFF_FAIL: "CREATE_STAFF_FAIL",

  ROLES_LIST_START: "ROLES_LIST_START",
  ROLES_LIST_SUCCESS: "ROLES_LIST_SUCCESS",
  ROLES_LIST_FAIL: "ROLES_LIST_FAIL",

  GET_STAFF_BY_ROLE_START: "GET_STAFF_BY_ROLE_START",
  GET_STAFF_BY_ROLE_SUCCESS: "GET_STAFF_BY_ROLE_SUCCESS",
  GET_STAFF_BY_ROLE_FAIL: "GET_STAFF_BY_ROLE_FAIL",

  DELETE_STAFF_START: "DELETE_STAFF_START",
  DELETE_STAFF_SUCCESS: "DELETE_STAFF_SUCCESS",
  DELETE_STAFF_FAIL: "DELETE_STAFF_FAIL",

  EDIT_STAFF_START: "EDIT_STAFF_START",
  EDIT_STAFF_SUCCESS: "EDIT_STAFF_SUCCESS",
  EDIT_STAFF_FAIL: "EDIT_STAFF_FAIL",
};

export const CollaboratorGroupActionTypes = {
  COLLABORATOR_GROUP_SEARCH_TEXT: "COLLABORATOR_GROUP_SEARCH_TEXT",

  COLLABORATOR_GROUP_LIST_START: "COLLABORATOR_GROUP_LIST_START",
  COLLABORATOR_GROUP_LIST_SUCCESS: "COLLABORATOR_GROUP_LIST_SUCCESS",
  COLLABORATOR_GROUP_LIST_FAIL: "COLLABORATOR_GROUP_LIST_FAIL",

  COLLABORATOR_GROUP_DETAIL_START: "COLLABORATOR_GROUP_DETAIL_START",
  COLLABORATOR_GROUP_DETAIL_SUCCESS: "COLLABORATOR_GROUP_DETAIL_SUCCESS",
  COLLABORATOR_GROUP_DETAIL_FAIL: "COLLABORATOR_GROUP_DETAIL_FAIL",

  CREATE_COLLABORATOR_GROUP_START: "CREATE_COLLABORATOR_GROUP_START",
  CREATE_COLLABORATOR_GROUP_SUCCESS: "CREATE_COLLABORATOR_GROUP_SUCCESS",
  CREATE_COLLABORATOR_GROUP_FAIL: "CREATE_COLLABORATOR_GROUP_FAIL",

  EDIT_COLLABORATOR_GROUP_START: "EDIT_COLLABORATOR_GROUP_START",
  EDIT_COLLABORATOR_GROUP_SUCCESS: "EDIT_COLLABORATOR_GROUP_SUCCESS",
  EDIT_COLLABORATOR_GROUP_FAIL: "EDIT_COLLABORATOR_GROUP_FAIL",

  REMOVE_COLLABORATOR_GROUP_START: "REMOVE_COLLABORATOR_GROUP_START",
  REMOVE_COLLABORATOR_GROUP_SUCCESS: "REMOVE_COLLABORATOR_GROUP_SUCCESS",
  REMOVE_COLLABORATOR_GROUP_FAIL: "REMOVE_COLLABORATOR_GROUP_FAIL",

  UN_ASSIGN_COLLABORATOR_GROUP_START: "UN_ASSIGN_COLLABORATOR_GROUP_START",
  UN_ASSIGN_COLLABORATOR_GROUP_SUCCESS: "UN_ASSIGN_COLLABORATOR_GROUP_SUCCESS",
  UN_ASSIGN_COLLABORATOR_GROUP_FAIL: "UN_ASSIGN_COLLABORATOR_GROUP_FAIL",
};

export const CollaboratorUserActionTypes = {
  COLLABORATOR_USER_SEARCH_TEXT: "COLLABORATOR_USER_SEARCH_TEXT",

  COLLABORATOR_USER_LIST_START: "COLLABORATOR_USER_LIST_START",
  COLLABORATOR_USER_LIST_SUCCESS: "COLLABORATOR_USER_LIST_SUCCESS",
  COLLABORATOR_USER_LIST_FAIL: "COLLABORATOR_USER_LIST_FAIL",

  CREATE_COLLABORATOR_USER_START: "CREATE_COLLABORATOR_USER_START",
  CREATE_COLLABORATOR_USER_SUCCESS: "CREATE_COLLABORATOR_USER_SUCCESS",
  CREATE_COLLABORATOR_USER_FAIL: "CREATE_COLLABORATOR_USER_FAIL",

  CREATE_COLLABORATOR_USER_GROUP_START: "CREATE_COLLABORATOR_USER_GROUP_START",
  CREATE_COLLABORATOR_USER_GROUP_SUCCESS:
    "CREATE_COLLABORATOR_USER_GROUP_SUCCESS",
  CREATE_COLLABORATOR_USER_GROUP_FAIL: "CREATE_COLLABORATOR_USER_GROUP_FAIL",

  REMOVE_COLLABORATOR_USER_START: "REMOVE_COLLABORATOR_USER_START",
  REMOVE_COLLABORATOR_USER_SUCCESS: "REMOVE_COLLABORATOR_USER_SUCCESS",
  REMOVE_COLLABORATOR_USER_FAIL: "REMOVE_COLLABORATOR_USER_FAIL",

  COLLABORATOR_USER_DETAIL_START: "COLLABORATOR_USER_DETAIL_START",
  COLLABORATOR_USER_DETAIL_SUCCESS: "COLLABORATOR_USER_DETAIL_SUCCESS",
  COLLABORATOR_USER_DETAIL_FAIL: "REMOVE_COLLABORATOR_USER_DETAIL_FAIL",

  EDIT_COLLABORATOR_USER_START: "EDIT_COLLABORATOR_USER_START",
  EDIT_COLLABORATOR_USER_SUCCESS: "EDIT_COLLABORATOR_USER_SUCCESS",
  EDIT_COLLABORATOR_USER_FAIL: "EDIT_COLLABORATOR_USER_FAIL",
};

export const CollaboratorClientActionTypes = {
  CREATE_COLLABORATOR_CLIENT_START: "CREATE_COLLABORATOR_CLIENT_START",
  CREATE_COLLABORATOR_CLIENT_SUCCESS: "CREATE_COLLABORATOR_CLIENT_SUCCESS",
  CREATE_COLLABORATOR_CLIENT_FAIL: "CREATE_COLLABORATOR_CLIENT_FAIL",

  COLLABORATOR_CLIENT_DETAIL_START: "COLLABORATOR_CLIENT_DETAIL_START",
  COLLABORATOR_CLIENT_DETAIL_SUCCESS: "COLLABORATOR_CLIENT_DETAIL_SUCCESS",
  COLLABORATOR_CLIENT_DETAIL_FAIL: "COLLABORATOR_CLIENT_DETAIL_FAIL",

  DELETE_COLLABORATOR_CLIENT_START: "DELETE_COLLABORATOR_CLIENT_START",
  DELETE_COLLABORATOR_CLIENT_SUCCESS: "DELETE_COLLABORATOR_CLIENT_SUCCESS",
  DELETE_COLLABORATOR_CLIENT_FAIL: "DELETE_COLLABORATOR_CLIENT_FAIL",
};

export const CopyMenuV2ActionTypes = {
  GET_MENU_LIST_START: "GET_MENU_LIST_START",
  GET_MENU_LIST_SUCCESS: "GET_MENU_LIST_SUCCESS",
  GET_MENU_LIST_FAIL: "GET_MENU_LIST_FAIL",

  SET_MENU_LIST_START: "SET_MENU_LIST_START",
  SET_MENU_LIST_SUCCESS: "SET_MENU_LIST_SUCCESS",
  SET_MENU_LIST_FAIL: "SET_MENU_LIST_FAIL",

  COPY_MENU_V2_START: "COPY_MENU_V2_START",
  COPY_MENU_V2_SUCCESS: "COPY_MENU_V2_SUCCESS",
  COPY_MENU_V2_FAIL: "COPY_MENU_V2_FAIL",
};
