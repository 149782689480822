import { CollaboratorClientActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  collaboratorClients: [],
  collaboratorClientDetail: {},
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const CollaboratorClientReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_START: {
      return { ...state, loading: true };
    }
    case CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorClientActionTypes.CREATE_COLLABORATOR_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_START: {
      return { ...state, loading: true };
    }
    case CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorClientActionTypes.DELETE_COLLABORATOR_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CollaboratorClientActionTypes.COLLABORATOR_CLIENT_DETAIL_START: {
      return { ...state, loading: true };
    }
    case CollaboratorClientActionTypes.COLLABORATOR_CLIENT_DETAIL_SUCCESS: {
      return {
        ...state,
        collaboratorClientDetail: action.payload,
        loading: false,
      };
    }
    case CollaboratorClientActionTypes.COLLABORATOR_CLIENT_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default CollaboratorClientReducer;
