import React from "react";
import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

export const FrontrestMixAndMatchDropdown = ({ value, name, onChange }) => {
  const { languageStrings } = useLanguageHelper();
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center `}
      style={{ marginTop: 5 }}
    >
      <InputLabel htmlFor="outlined-venue-native-simple">
        {"Mix and Match"}
      </InputLabel>
      <Select
        disabled={isSalesPerson}
        native
        fullWidth
        value={value}
        onChange={(e) => {
          onChange(name, e);
        }}
        style={{ paddingBottom: 1 }}
        input={
          <OutlinedInput
            name="Frontrest Mix and Match"
            labelWidth={100}
            id="outlined-venue-native-simple"
          />
        }
      >
        <option value={false}>{languageStrings["GENERAL.NO"]}</option>
        <option value={true}>{languageStrings["GENERAL.YES"]}</option>
      </Select>
    </FormControl>
  );
};
