import { ClientActionTypes, VenuesActionTypes } from "../redux/actionTypes";

const INITIAL_STATEL = {
  venuesList: [],
  searchText: "",
  loading: false,
};

const VenuesReducer = (state = INITIAL_STATEL, action) => {
  switch (action.type) {
    case VenuesActionTypes.SET_VENUE_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case VenuesActionTypes.GET_VENUE_LIST_START: {
      return { ...state, loading: true };
    }
    case VenuesActionTypes.GET_VENUE_LIST_SUCCESS: {
      return {
        ...state,
        venuesList: action.payload,
        loading: false,
      };
    }
    case VenuesActionTypes.GET_VENUE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default VenuesReducer;
