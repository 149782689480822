import { axiosInstance } from "app/api/axios";
import { PosIntegrationActionTypes } from "../redux/actionTypes";
import {
  getPosInputs as posInputsUrl,
  updatePosIntegration,
  getClientIntegrationUrl,
} from "app/api/Endpoint";
import { errorAlert, successAlert } from "app/helpers/alerts";

export const getPosInputs = (type, clientId) => {
  return (dispatch) => {
    dispatch({
      type: PosIntegrationActionTypes.GET_POS_INPUTS_START,
    });

    const url = posInputsUrl();
    const clientData = { posType: type, clientId: clientId };
    axiosInstance
      .post(url, clientData)
      .then((res) => {
        const { data } = res.data;
        getPosInputsSuccess(dispatch, data);
      })
      .catch((e) => {
        getPosInputsFail(dispatch, "Connection Error");
      });
  };
};

const getPosInputsSuccess = (dispatch, data) => {
  dispatch({
    type: PosIntegrationActionTypes.GET_POS_INPUTS_SUCCESS,
    payload: data,
  });
};

const getPosInputsFail = (dispatch, errorMessage) => {
  dispatch({
    type: PosIntegrationActionTypes.GET_POS_INPUTS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const updatePosValues = (updatedValues) => {
  return (dispatch) => {
    dispatch({
      type: PosIntegrationActionTypes.UPDATE_POS_VALUES,
    });
    getPosInputsSuccess(dispatch, updatedValues);
  };
};

export const updatePos = (currentIntegration, clientId, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: PosIntegrationActionTypes.UPDATE_POS,
    });

    const posType =
      currentIntegration.posType === "none" ? "" : currentIntegration.posType;

    const url = updatePosIntegration();
    const posData = {
      clientId: clientId,
      posType: posType,
      newData: currentIntegration.inputs,
    };

    axiosInstance
      .put(url, posData)
      .then((res) => {
        const { data } = res.data;
        if (languageStrings)
          successAlert(dispatch, languageStrings["UPDATE_SUCCESS"]);

        getPosInputsSuccess(dispatch, data);
        dispatch(getPosInputs(currentIntegration.posType, clientId));
      })
      .catch((e) => {
        if (languageStrings)
          errorAlert(dispatch, languageStrings["WRONG_MESSAGE"]);

        getPosInputsFail(dispatch, "Connection Error");
      });
  };
};

export const getClientIntegration = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: PosIntegrationActionTypes.GET_CLIENT_INTEGRATION,
    });

    const url = getClientIntegrationUrl();

    axiosInstance
      .post(url, { clientId })
      .then((res) => {
        let data = res.data;
        getPosInputsSuccess(dispatch, data);
      })
      .catch((e) => {
        getPosInputsFail(dispatch, "Connection Error");
      });
  };
};
