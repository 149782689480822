import React, { useEffect } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStaffList,
  getStaffList,
  setSearchText,
} from "app/store/staff/StaffActions";
import ListHeader from "../common/List/ListHeader";
import history from "@history";

export const StaffList = () => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const { staff, loading, searchText } = useSelector(
    (state) => state.banzzu.Staff
  );

  useEffect(() => {
    dispatch(getStaffList());
  }, []);

  const searchMessagesList = (event) => {
    dispatch(setSearchText(event));
  };
  const handleClick = (item) => {
    history.push(`/add-staff/${item._id}`);
  };

  const headerRows = {
    data: [
      {
        id: ["email"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "text",
      },
      {
        id: ["name"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: ["roleValue"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ROLE"],
        sort: true,
        type: "text",
      },
      {
        id: ["createdAt"],
        align: "left",
        disablePadding: false,
        label: languageStrings["DATE"],
        sort: true,
        type: "date",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["email", "name", "roleValue"],
  };

  const onHandleDelete = (selected) => {
    dispatch(deleteStaffList(selected));
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <div
        style={{
          flex: 1,
          filter: false ? "blur(3px)" : "none",
          marginTop: 30,
        }}
      >
        <FusePageCarded
          classes={{
            content: "flex",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <div
              className={"flex w-full items-center flex-row justify-between"}
            >
              <ListHeader
                title={languageStrings["STAFF.LIST"]}
                setSearchText={searchMessagesList}
                searchType={"Staff"}
              />
            </div>
          }
          content={
            <ListTable
              headerRows={headerRows}
              data={staff}
              handleDelete={onHandleDelete}
              searchText={searchText}
              handleClick={handleClick}
            />
          }
          innerScroll
        />
      </div>
    </LoadingOverlay>
  );
};

export default StaffList;
