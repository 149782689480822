import React, { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import { getCollaboratorUserList } from "app/store/collaboratorUser/CollaboratorUserActions";
import { COLLABORATOR_ROLES_ENUM } from "app/helpers/utilsHelper";

function AssignCollaboratorDropDown({
  value,
  handleChange,
  style = {
    minWidth: "50%",
    maxWidth: "50%",
  },
  data,
}) {
  const { collaboratorUsers } = useSelector(
    (state) => state.banzzu.CollaboratorUser
  );
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaboratorUserList());
  }, []);

  const _collaboratorUsers = data
    ? data
    : collaboratorUsers && collaboratorUsers.length
    ? collaboratorUsers.filter(
        (c) => c.role === COLLABORATOR_ROLES_ENUM.COLLABORATOR
      )
    : [];
  return (
    <FormControl className="ml-8 mt-8 mb-16" style={style}>
      <InputLabel className="ml-12 -mt-8" id="demo-simple-select-label">
        {languageStrings["ASSIGN_COLLABORATOR"]}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={value}
        name="collaborator"
        id="collaborator"
        label={languageStrings["ASSIGN_COLLABORATOR"]}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={languageStrings["ASSIGN_COLLABORATOR"]}
            labelWidth={180}
          />
        }
      >
        {_collaboratorUsers.map((item) => (
          <MenuItem value={item._id} key={item._id}>
            {item.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default AssignCollaboratorDropDown;
