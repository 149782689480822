import { FailedPaymentsActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: FailedPaymentState = {
  failedPayments: [],
  loading: false,
  searchText: "",
  totalFailedPayments: 0,
};

interface Action {
  payload: any;
  type: string;
}

const FailedPaymentReducer = (
  state: FailedPaymentState = INITIAL_STATE,
  action: Action
): FailedPaymentState => {
  switch (action.type) {
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_START: {
      return {
        ...state,
        loading: true,
        failedPayments: [],
        totalFailedPayments: 0,
      };
    }
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_SUCCESS: {
      const list =
        action.payload &&
        action.payload.data &&
        action.payload.data.failedPayments
          ? action.payload.data.failedPayments
          : [];

      const totalFailedPayments =
        action.payload &&
        action.payload.data &&
        action.payload.data.totalFailedPayments
          ? action.payload.data.totalFailedPayments
          : 0;

      return {
        ...state,
        failedPayments: list,
        loading: false,
        totalFailedPayments,
      };
    }
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case FailedPaymentsActionTypes.SET_FAILED_PAYMENTS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default FailedPaymentReducer;
