import { StaffActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  staff: [],
  staffDetail: {},
  staffByRole: [],
  roles: [],
  searchText: "",
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const StaffReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case StaffActionTypes.STAFF_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case StaffActionTypes.STAFF_LIST_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.STAFF_LIST_SUCCESS: {
      return {
        ...state,
        staff: action.payload,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.ROLES_LIST_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.ROLES_LIST_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
        loading: false,
      };
    }
    case StaffActionTypes.ROLES_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.GET_STAFF_BY_ROLE_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.GET_STAFF_BY_ROLE_SUCCESS: {
      return {
        ...state,
        staffByRole: action.payload,
        loading: false,
      };
    }
    case StaffActionTypes.GET_STAFF_BY_ROLE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.DELETE_STAFF_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.DELETE_STAFF_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.DELETE_STAFF_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.EDIT_STAFF_START: {
      return { ...state, loading: true };
    }
    case StaffActionTypes.EDIT_STAFF_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.EDIT_STAFF_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_DETAIL_START: {
      return { ...state, loading: true, staffDetail: {} };
    }
    case StaffActionTypes.STAFF_DETAIL_SUCCESS: {
      return {
        ...state,
        staffDetail: action.payload,
        loading: false,
      };
    }
    case StaffActionTypes.STAFF_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default StaffReducer;
