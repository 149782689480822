import { axiosInstance } from "app/api/axios";

import { StaffActionTypes } from "../redux/actionTypes";
import { ROLES, STAFF, STAFF_BY_ROLE, STAFF_DETAIL } from "app/api/Endpoint";
import { CreateStaffModel } from "app/models/staff";
import { toast } from "react-toastify";
import { capitalizeFirstAlphabet } from "app/helpers/utilsHelper";
import History from "@history";

export function setSearchText(event) {
  return {
    type: StaffActionTypes.STAFF_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getStaffList = () => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.STAFF_LIST_START,
    });

    const url = STAFF;

    axiosInstance
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.staff &&
          Array.isArray(res.data.data.staff)
        ) {
          getStaffListSuccess(dispatch, res.data.data.staff);
        } else {
          getStaffListFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getStaffListFail(dispatch, "There was an error connection");
      });
  };
};
const getStaffListSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.STAFF_LIST_SUCCESS,
    payload: data,
  });
};
const getStaffListFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: StaffActionTypes.STAFF_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const getRoles = () => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.ROLES_LIST_START,
    });

    const url = ROLES;

    axiosInstance
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.roles &&
          Array.isArray(res.data.data.roles)
        ) {
          getRolesSuccess(dispatch, res.data.data.roles);
        } else {
          getRolesFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getRolesFail(dispatch, "There was an error connection");
      });
  };
};
const getRolesSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.ROLES_LIST_SUCCESS,
    payload: data,
  });
};
const getRolesFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: StaffActionTypes.ROLES_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const createStaff = (payload: CreateStaffModel) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.CREATE_STAFF_START,
    });

    const url = STAFF;

    axiosInstance
      .post(url, payload)
      .then((res) => {
        if (res && res.data) {
          createStaffSuccess(dispatch);
          const role = capitalizeFirstAlphabet(payload.role);
          toast.success(`${role ? role : "Staff"} Created Successfully`);
        } else {
          createStaffFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        console.log("e.message", e.message);
        createStaffFail(dispatch, "There was an error connection");
      });
  };
};
const createStaffSuccess = (dispatch) => {
  dispatch({
    type: StaffActionTypes.CREATE_STAFF_SUCCESS,
  });
  dispatch(getStaffList());
  History.push({
    pathname: "/staff-list",
  });
};
const createStaffFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");

  dispatch({
    type: StaffActionTypes.CREATE_STAFF_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const getStaffByRole = (params: { roleType: String }) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.GET_STAFF_BY_ROLE_START,
    });

    const url = `${STAFF_BY_ROLE}`;

    axiosInstance
      .get(url, { params })
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.staff) {
          getStaffByRoleSuccess(dispatch, res.data.data.staff);
        } else {
          getStaffByRoleFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        console.log("e.message", e.message);
        getStaffByRoleFail(dispatch, "There was an error connection");
      });
  };
};
const getStaffByRoleSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.GET_STAFF_BY_ROLE_SUCCESS,
    payload: data,
  });
};
const getStaffByRoleFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);

  dispatch({
    type: StaffActionTypes.GET_STAFF_BY_ROLE_FAIL,
  });
};
export const deleteStaffList = (staffIds: string[]) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.DELETE_STAFF_START,
    });

    const url = STAFF;

    axiosInstance
      .delete(url, { data: { staffIds } })
      .then((res) => {
        deleteStaffListSuccess(dispatch);
      })
      .catch((e) => {
        deleteStaffListFail(dispatch, "There was an error connection");
      });
  };
};
const deleteStaffListSuccess = (dispatch) => {
  dispatch({
    type: StaffActionTypes.DELETE_STAFF_SUCCESS,
  });
  dispatch(getStaffList());
};
const deleteStaffListFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);
  dispatch({
    type: StaffActionTypes.DELETE_STAFF_FAIL,
    payload: {
      errorMessage,
    },
  });
};
export const editStaff = (payload: CreateStaffModel) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.EDIT_STAFF_START,
    });

    const url = STAFF;

    axiosInstance
      .put(url, payload)
      .then((res) => {
        editStaffSuccess(dispatch);
      })
      .catch((e) => {
        editStaffFail(dispatch, "There was an error connection");
      });
  };
};
const editStaffSuccess = (dispatch) => {
  dispatch({
    type: StaffActionTypes.EDIT_STAFF_SUCCESS,
  });
  dispatch(getStaffList());
  History.push({
    pathname: "/staff-list",
  });
};
const editStaffFail = (dispatch, errorMessage) => {
  toast.error(errorMessage);
  dispatch({
    type: StaffActionTypes.EDIT_STAFF_SUCCESS,
    payload: {
      errorMessage,
    },
  });
};
export const getStaffDetail = (staffId: string) => {
  return (dispatch) => {
    dispatch({
      type: StaffActionTypes.STAFF_DETAIL_START,
    });

    const url = `${STAFF_DETAIL}/${staffId}`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res && res.data && res.data.data) {
          getStaffDetailSuccess(dispatch, res.data.data);
        } else {
          getStaffDetailFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getStaffDetailFail(dispatch, "There was an error connection");
      });
  };
};
const getStaffDetailSuccess = (dispatch, data) => {
  dispatch({
    type: StaffActionTypes.STAFF_DETAIL_SUCCESS,
    payload: data,
  });
};
const getStaffDetailFail = (dispatch, errorMessage) => {
  toast.error("Something went wrong");
  dispatch({
    type: StaffActionTypes.STAFF_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};
