import React, { useEffect } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getStaffByRole } from "app/store/staff/StaffActions";
import { DASHBOARD_ROLES, sortByName } from "app/helpers/utilsHelper";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};
export const StaffDropdown = ({ staffName, handleChangeStaff }) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const staffByRole = useSelector(({ banzzu }) => banzzu.Staff.staffByRole);
  useEffect(() => {
    dispatch(getStaffByRole({ roleType: DASHBOARD_ROLES.SALESPERSON }));
  }, [dispatch]);
  const renderValue = (selected = []) => {
    const names = [];
    for (let i = 0; i < selected.length; i++) {
      const { name = "" } = selected[i];
      names.push(name);
    }
    return names.join(", ");
  };
  const _staffList =
    staffByRole && staffByRole.length ? sortByName(staffByRole) : [];
  return (
    <FormControl
      className="ml-8 mt-8 mb-16 max-w-1/2"
      style={{
        minWidth: "50%",
        maxWidth: "50%",
      }}
    >
      <InputLabel className="ml-12 -mt-8" id="demo-multiple-checkbox-label">
        {languageStrings["SALES_PERSON"]}
      </InputLabel>
      <Select
        id="demo-multiple-checkbox"
        multiple
        value={staffName}
        input={<OutlinedInput label="Tag" labelWidth={60} />}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {_staffList.map((item) => {
          const selectedStaff = staffName.find(
            (staff) => staff._id === item._id
          );
          const isChecked = !!(selectedStaff && selectedStaff._id);
          return (
            <MenuItem key={item._id} value={item}>
              <div
                className="flex w-full items-center h-full"
                onClick={() => {
                  const currentIndex = staffName.findIndex(
                    (staff) => staff._id === item._id
                  );
                  const newChecked = [...staffName];

                  if (currentIndex === -1) {
                    newChecked.push(item);
                  } else {
                    newChecked.splice(currentIndex, 1);
                  }

                  handleChangeStaff(newChecked);
                }}
              >
                <Checkbox checked={isChecked} />
                <ListItemText primary={item.name} />
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
