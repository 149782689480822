import React from "react";
import { TableCell } from "@material-ui/core";

const ImageCell = ({ src, alt }) => {
  return (
    <TableCell className="w-80" component="th" scope="row" padding="none" style={{ height: "10rem", width: "8rem", }}>
      {src ? (
        <img className="w-full block rounded" src={src} />
      ) : (
          <img
            className="w-full block rounded"
            src={"assets/images/avatars/noimage.png"}
            alt={alt}
          />
        )}
    </TableCell>
  );
};

export default ImageCell;
