import React, { useState, useEffect } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { isEmailValid } from "app/helpers/validators";
import { useForm } from "@fuse/hooks";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { checkEmail } from "app/store/client/ClientActions";
import {
  createStaff,
  editStaff,
  getRoles,
  getStaffDetail,
} from "app/store/staff/StaffActions";
import { getRolesTitle } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

const CreateStaffPage = (props) => {
  const classes = useStyles("");
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const { roles, loading } = useSelector((state) => state.banzzu.Staff);
  let staffDetail = useSelector((state) => state.banzzu.Staff.staffDetail);
  const { staffId } = props.match.params;

  const [validEmail, setValidEmail] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const roleInitialValue = roles && roles.length ? roles[0] : "";

  useEffect(() => {
    if (staffId !== "new") {
      dispatch(getStaffDetail(staffId));
    }
  }, [dispatch, staffId]);
  if (staffId === "new") {
    staffDetail = {
      _id: "0",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      role: roleInitialValue,
    };
  }
  const { form, handleChange, setForm } = useForm(null);

  const handleChangeTab = (event, tabValue) => {
    setTabValue(tabValue);
  };
  const canBeSubmitted = () => {
    if (!(form && form._id)) {
      return false;
    }
    const { firstName, lastName, email, role, password } = form;

    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      role.length > 0
    );
  };
  useEffect(() => {
    dispatch(getRoles());
  }, []);
  useEffect(() => {
    setForm((prevForm) => ({ ...prevForm, role: roleInitialValue }));
  }, [roles]);
  useEffect(() => {
    if (
      (staffDetail && !form) ||
      (staffDetail && form && staffDetail._id !== form._id)
    ) {
      setForm(staffDetail);
    }
  }, [form, staffDetail, setForm]);
  const onEmailChange = (e) => {
    handleChange(e);
    if (!isEmailValid(e.target.value)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
      dispatch(checkEmail(e.target.value));
    }
  };

  const onSubmit = () => {
    form._id !== "0" ? dispatch(editStaff(form)) : dispatch(createStaff(form));
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {
                          languageStrings[
                            staffId === "new" ? "STAFF.CREATE" : "STAFF.UPDATE"
                          ]
                        }
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={onSubmit}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24">
              <div>
                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.NAME"]}
                    id="firstName"
                    name="firstName"
                    value={form.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.SURNAME"]}
                    id="lastName"
                    name="lastName"
                    value={form.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.EMAIL"]}
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={onEmailChange}
                    variant="outlined"
                    fullWidth
                    error={!validEmail}
                    helperText={
                      !validEmail ? languageStrings["ERRORS.INVALID_EMAIL"] : ""
                    }
                  />
                  {form && form._id === "0" ? (
                    <TextField
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["GENERAL.PASSWORD"]}
                      id="password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      type="password"
                    />
                  ) : null}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      className="mt-8"
                      htmlFor="outlined-venue-native-simple"
                    >
                      {languageStrings["GENERAL.ROLE"]}
                    </InputLabel>
                    <Select
                      className="mt-8 mb-16 mr-8"
                      native
                      required
                      id="role"
                      name="role"
                      onChange={handleChange}
                      value={form.role}
                      input={
                        <OutlinedInput
                          name="province"
                          labelWidth={30}
                          id="outlined-venue-native-simple"
                        />
                      }
                    >
                      {roles.map((value) => {
                        const title = getRolesTitle(value);
                        return (
                          <option value={value}>
                            {languageStrings[title]}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default CreateStaffPage;
