import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import {
  deleteRatings,
  getRatingList,
  setRatingSearchText,
} from "app/store/rating/RatingActions";
import ListTablePaginated from "../common/List/ListTablePaginated";
import { ReviewModal } from "./components/ReviewModal";
import { DASHBOARD_ROLES, isCollaborator } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

const _start = new Date();
_start.setMonth(_start.getMonth() - 1);

function RatingList() {
  const { searchText, ratingList, loading, averageRating, totalRatings } =
    useSelector(({ banzzu }) => banzzu.rating);
  const isAdmin = getUserRole() !== DASHBOARD_ROLES.SALESPERSON;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [reviewModal, setReviewModal] = useState({
    showModal: false,
    rating: 0,
    review: "",
  });

  useEffect(() => {
    const params = {
      limit: rowsPerPage,
      page: pageNo,
      searchText,
    };
    dispatch(getRatingList(params));
  }, [searchText, pageNo, rowsPerPage]);

  const _averageRating = averageRating ? averageRating : 0;
  const _ratings = ratingList && ratingList.length ? ratingList : [];

  const handleClick = (item) => {
    if (item && item.review) {
      setReviewModal({
        showModal: true,
        rating: item && item.rating ? item.rating : 0,
        review: item && item.review ? item.review : "",
      });
    }
  };
  const { languageStrings } = useLanguageHelper();
  const setSearchText = (ev) => {
    dispatch(setRatingSearchText(ev));
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const handlePageChange = (event, page) => {
    setPageNo(page);
  };

  const headerRows = {
    data: [
      {
        id: "venueTitle",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.VENUE"],
        sort: true,
        type: "text",
      },
      {
        id: "tableTitle",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.TABLE"],
        sort: true,
        type: "text",
      },

      {
        id: "rating",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.RATING"],
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE_TIME"],
        sort: true,
        type: "time",
      },
      {
        id: "review",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.COMMENTS"],
        sort: true,
        type: "truncatedText",
      },
    ],
    checkbox: isAdmin && !isCollaborator,
    clickable: true,
    locationSearch: false,
    searchBy: ["tableTitle", "venueTitle"],
  };
  const onHandleDelete = (selected) => {
    dispatch(deleteRatings(selected));
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  // date={getDateSelectedtext()}
                  title={languageStrings["SIDEBAR_MENUE.RATINGS"]}
                  setSearchText={setSearchText}
                  searchType={"rating"}
                  // ratingButton={true}
                  onTipPress={() => {
                    setShowModal(true);
                  }}
                  averageRating={_averageRating}
                />
              </div>
            }
            content={
              <ListTablePaginated
                handleDelete={onHandleDelete}
                handleClick={handleClick}
                headerRows={headerRows}
                data={_ratings}
                totalData={totalRatings}
                order={{}}
                handleRequestSort={() => {}}
                searchText={searchText}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <ReviewModal
        onCloseModal={() => {
          setReviewModal({
            showModal: false,
            review: "",
            rating: 0,
          });
        }}
        {...reviewModal}
      />
    </>
  );
}

export default RatingList;
