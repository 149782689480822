import React from "react";
import { TableCell, Icon, Button } from "@material-ui/core";

const ButtonCell = ({ text, onButtonClick, _id }) => {
  return (
    <TableCell>
      <Button
        component="a"
        rel="noreferrer noopener"
        role="button"
        className="normal-case items-center justify-center"
        variant="contained"
        color="primary"
        style={{ height: "3rem", width: "13rem", fontSize: "12px" }}
        onClick={() => onButtonClick(_id)}
      >
        {text}
      </Button>
    </TableCell>
  );
};

export default ButtonCell;
