export const clientHeaders = [
  {
    id: "venueName",
    title: "Restaurante",
  },
  {
    id: "email",
    title: "Email",
  },
  {
    id: "goals",
    title: "Goals",
  },
  {
    id: "createdByCompany",
    title: "Creado Manual",
  },
  {
    id: "createdAt",
    title: "Fecha Registro ",
  },
  {
    id: "lastInteraction",
    title: "Última interacción",
  },
  {
    id: "accessLevel",
    title: "Comportamiento",
  },
];
export const venueHeaders = [
  {
    id: "clientName",
    title: "Client Name",
  },
  {
    id: "title",
    title: "Venue Name",
  },
  {
    id: "status",
    title: "Status",
  },
  {
    id: "description",
    title: "Description",
  },
  
];

export const orderHeaders = [
  {
    id: "referenceNo",
    title: "Ref #",
  },
  {
    id: "venueTitle",
    title: "Título",
  },
  {
    id: "type",
    title: "Tipo Pedido",
  },
  {
    id: "totalPrice",
    title: "Importe",
  },
  {
    id: "status",
    title: "Estado",
  },
  {
    id: "createdAt",
    title: "Fecha",
  },
];
