import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  Fade,
  Modal,
  Backdrop,
  FormControl,
  TextField,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import {
  createCollaboratorGroup,
  editCollaboratorGroup,
  unAssignCollaboratorGroup,
} from "app/store/collaboratorGroup/CollaboratorGroupActions";
import LoadingOverlay from "react-loading-overlay";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "60%",
  },
  "availability--datepicker": {
    border: "1px solid #ddd",
  },
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 1000,
    },
    "& .rbc-label": {
      padding: "8px 6px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important",
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
      // ...theme.mixins.border(0),
    },
    "& .rbc-agenda-view table": {
      // ...theme.mixins.border(1),
      "& thead > tr > th": {
        // ...theme.mixins.borderBottom(0),
      },
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {
          // ...theme.mixins.borderLeft(1),
        },
      },
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-time-content": {
        flex: "0 1 auto",

        // ...theme.mixins.border(1),
      },
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-month-row": {
        // ...theme.mixins.border(1),
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128,
      },
      "& .rbc-header + .rbc-header": {
        // ...theme.mixins.borderLeft(1),
      },
      "& .rbc-header": {
        // ...theme.mixins.borderBottom(0),
      },
      "& .rbc-day-bg + .rbc-day-bg": {
        // ...theme.mixins.borderLeft(1),
      },
    },
    "& .rbc-day-slot .rbc-time-slot": {
      // ...theme.mixins.borderTop(1),
      opacity: 0.5,
    },
    "& .rbc-time-header > .rbc-row > * + *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-content > * + * > *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-day-bg + .rbc-day-bg": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-header > .rbc-row:first-child": {
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-timeslot-group": {
      minHeight: 64,
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit",
      },
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2],
      },
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px",
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0,0,0,0.03)"
          : "rgba(0,0,0,0.16)",
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child":
      {
        left: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child":
      {
        right: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99,
  },
}));
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() - 1);

function CreateCollaboratorGroupModal(props) {
  const classes = useStyles(props);
  const { loading, collaboratorGroups } = useSelector(
    (state) => state.banzzu.CollaboratorGroup
  );
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [order, setOrder] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const name = props && props.name ? props.name : "";
  const isError = !name;
  const { _id } = props;

  useEffect(() => {
    const selectedGroup = collaboratorGroups.find((group) => group._id === _id);
    if (selectedGroup && selectedGroup.collaboratorUserList) {
      setSelectedCollaborators(selectedGroup.collaboratorUserList);
    }
  }, [_id, collaboratorGroups]);

  const headerRows = {
    data: [
      {
        id: "firstName",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.FIRST_NAME"],
        sort: false,
        type: "text",
      },
      {
        id: "lastName",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.LAST_NAME"],
        sort: false,
        type: "text",
      },
      {
        id: "role",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ROLE"],
        sort: false,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: false,
        type: "text",
      },
    ],
    checkbox: true,
    clickable: false,
    locationSearch: false,
    searchBy: ["name"],
  };
  const onHandleDelete = (selected) => {
    const payload = {
      collaboratorGroupId: _id,
      collaboratorIds: selected,
    };
    dispatch(unAssignCollaboratorGroup(payload, languageStrings));
    props.onClose();
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const onSaveGroup = () => {
    if (name) {
      if (props && props.isEditing) {
        dispatch(
          editCollaboratorGroup({
            name,
            collaboratorIds: selectedCollaborators,
            _id: props._id,
          })
        );
      } else {
        dispatch(
          createCollaboratorGroup({
            name,
          })
        );
      }
      props.onClose();
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleChange = (event) => {
    props && props.setName(event.target.value);
  };
  return (
    <LoadingOverlay active={loading} spinner text="Loading...">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2 className="pt-8 pb-6" id="transition-modal-title">
              {languageStrings["COLLABORATOR.GROUP"]}
            </h2>
            <p id="transition-modal-description">
              <FormControl fullWidth>
                <TextField
                  className="mt-8 mb-16 mr-8"
                  label={languageStrings["GENERAL.NAME"]}
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  fullWidth
                />
              </FormControl>
              {selectedCollaborators.length > 0 && (
                <ListTablePaginated
                  handleDelete={onHandleDelete}
                  headerRows={headerRows}
                  data={selectedCollaborators}
                  totalData={selectedCollaborators.length}
                  order={order}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handlePageChange={handlePageChange}
                />
              )}
              <div className="flex mt-10 items-center justify-center">
                <div
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "red",
                    cursor: "pointer",
                    marginRight: "0.8rem",
                  }}
                  onClick={props.onClose}
                >
                  {languageStrings["GENERAL.CANCEL_BUTTON"]}
                </div>

                <div
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: isError ? "grey" : "#1D6F42",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!isError) {
                      onSaveGroup();
                    }
                  }}
                >
                  {props.isEditing
                    ? languageStrings["GENERAL.SAVE"]
                    : languageStrings["CREATE_GROUP"]}
                </div>
              </div>
            </p>
          </div>
        </Fade>
      </Modal>
    </LoadingOverlay>
  );
}

export default CreateCollaboratorGroupModal;
