import React, { FC } from "react";
import { Select, OutlinedInput } from "@material-ui/core";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

interface Props {
  value: string;
  onChange: (e) => void;
}

const CountryPicker: FC<Props> = ({ value, onChange }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <Select
      className="mt-8 mb-16"
      native
      fullWidth
      value={value}
      onChange={onChange}
      input={
        <OutlinedInput
          name="locale"
          labelWidth={100}
          id="outlined-venue-native-simple"
        />
      }
    >
      <option value="es">Spanish</option>
      <option value="en">English</option>
      <option value="fr">French</option>
      <option value="zh">Chinese</option>
    </Select>
  );
};

export default CountryPicker;
