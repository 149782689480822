import { ClientActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: ClientState = {
  clientsList: [],
  totalClients: 0,
  searchText: "",
  loading: false,
  clientDetails: {},
  emailTaken: false,
  venueDetail: {},
  taxes: [],
  currencies: [],
};

interface Action {
  payload: any;
  type: string;
}

const ClientReducer = (
  state: ClientState = INITIAL_STATE,
  action: Action
): ClientState => {
  switch (action.type) {
    case ClientActionTypes.SET_CLIENT_SEARCH_TEXT: {
      return updateObject(state, {
        searchText: action.payload.searchText,
      });
    }
    case ClientActionTypes.CLIENT_LIST_START: {
      return updateObject(state, {
        loading: true,
        clientsList: [],
      });
    }
    case ClientActionTypes.CLIENT_LIST_SUCCESS: {
      return updateObject(state, {
        clientsList: action.payload.clients,
        totalClients: action.payload.totalClients,
        loading: false,
      });
    }
    case ClientActionTypes.CLIENT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.ACCESS_CLIENT_START: {
      return { ...state, loading: true };
    }
    case ClientActionTypes.ACCESS_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.ACCESS_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case ClientActionTypes.CLIENT_DETAILS_START: {
      return { ...state, loading: true };
    }
    case ClientActionTypes.CLIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        clientDetails: action.payload,
        loading: false,
      };
    }
    case ClientActionTypes.CLIENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.UPDATE_CLIENT_START: {
      return { ...state, loading: true };
    }
    case ClientActionTypes.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        clientDetails: action.payload,
        loading: false,
      };
    }
    case ClientActionTypes.UPDATE_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.CHECK_EMAIL_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case ClientActionTypes.CHECK_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        emailTaken: action.payload.emailExists,
      };
    }

    case ClientActionTypes.CHECK_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.CLIENT_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ClientActionTypes.CLIENT_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.CLIENT_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.GET_CLIENT_VENUE_DETAIL_START: {
      return { ...state, loading: true, venueDetail: {} };
    }
    case ClientActionTypes.GET_CLIENT_VENUE_DETAIL_SUCCESS: {
      return {
        ...state,
        venueDetail: action.payload,
        loading: false,
      };
    }
    case ClientActionTypes.GET_CLIENT_VENUE_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
        venueDetail: {},
      };
    }
    case ClientActionTypes.GET_TAXES_START: {
      return { ...state, loading: true };
    }
    case ClientActionTypes.GET_TAXES_SUCCESS: {
      return {
        ...state,
        taxes: action.payload,
        loading: false,
      };
    }
    case ClientActionTypes.GET_TAXES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case ClientActionTypes.GET_CURRENCIES_START: {
      return { ...state, loading: true };
    }
    case ClientActionTypes.GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        currencies: action.payload,
        loading: false,
      };
    }
    case ClientActionTypes.GET_CURRENCIES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default ClientReducer;
