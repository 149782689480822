import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { FusePageSimple } from "@fuse";
import { connect, useSelector } from "react-redux";
import history from "@history";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { clientSettings } from "config";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

const styles = (theme) => ({
  layoutRoot: {},
});

const HomePage = (props) => {
  const { languageStrings } = useLanguageHelper();
  const user = useSelector(({ banzzu }) => banzzu.auth.user);

  const [url, setUrl] = useState("");
  const [locale, setLocale] = useState("");
  useEffect(() => {
    setLocale(getStorageItem(STORAGE_KEY.LOCALE) == "es" ? "es/" : "");
    setUrl(
      process.env.NODE_ENV === "production"
        ? "https://app.banzzu.com/register/" + locale + user.code
        : "http://localhost:2400/register/" + locale + user.code
    );
  }, [locale, user]);
  const accessToken = getStorageItem(STORAGE_KEY.ACCESS_TOKEN);
  const userId = getStorageItem(STORAGE_KEY.USER_ID);

  if (
    !accessToken ||
    accessToken == "undefined" ||
    !userId ||
    userId == "undefined"
  ) {
    history.push({
      pathname: `/login/${clientSettings.language}`,
    });
  }
  if (!accessToken !== "undefined") {
    history.push({
      pathname: "/clientsList",
    });
  }
  const { classes } = props;

  return (
    <FusePageSimple
      classes={{ root: classes.layoutRoot }}
      // header={
      //   // <div className="p-24">
      //   //   <h4>Header</h4>
      //   // </div>
      // }
      content={
        <div className="flex justify-center mt-10">
          <Card
            className={
              "flex flex-col items-center justify-center w-256 pt-10 pb-5 px-10 shadow-md"
            }
          >
            <h1>{languageStrings["GENERAL.YOUR_CODE"]}</h1>
            <h4 className={"mt-4"}>{user.code}</h4>
            <CopyToClipboard text={url}>
              <Button
                component="a"
                rel="noreferrer noopener"
                role="button"
                className="normal-case items-center justify-center mt-5"
                variant="contained"
                color="primary"
                style={{ height: "3rem", width: "14rem", fontSize: "12px" }}
              >
                {languageStrings["GENERAL.COPY_CODE"]}
              </Button>
            </CopyToClipboard>
          </Card>
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
