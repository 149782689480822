import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Fade, Modal, Backdrop } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch } from "react-redux";
import { getCollaboratorUserList } from "app/store/collaboratorUser/CollaboratorUserActions";
import AssignCollaboratorDropDown from "app/main/common/AssignCollaboratorDropDown";
import {
  createCollaboratorClient,
  deleteCollaboratorClient,
} from "app/store/collaboratorClient/collaboratorClientActions";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "60%",
  },
  "availability--datepicker": {
    border: "1px solid #ddd",
  },
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 1000,
    },
    "& .rbc-label": {
      padding: "8px 6px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important",
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
    },
    "& .rbc-agenda-view table": {
      "& thead > tr > th": {},
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {},
      },
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {},
      "& .rbc-time-content": {
        flex: "0 1 auto",
      },
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {},
      "& .rbc-month-row": {
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128,
      },
    },
    "& .rbc-day-slot .rbc-time-slot": {
      opacity: 0.5,
    },
    "& .rbc-timeslot-group": {},
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit",
      },
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2],
      },
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px",
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0,0,0,0.03)"
          : "rgba(0,0,0,0.16)",
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child":
      {
        left: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child":
      {
        right: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99,
  },
}));
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() - 1);

function AssignCollaboratorModal(props) {
  const classes = useStyles(props);
  const { languageStrings } = useLanguageHelper();
  const { clientCollaborators } = props;
  const [collaboratorId, setCollaboratorId] = useState("");
  const [order, setOrder] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { collaboratorUsers } = useSelector(
    (state) => state.banzzu.CollaboratorUser
  );
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaboratorUserList());
  }, []);
  useEffect(() => {
    setSelectedCollaborators(
      clientCollaborators.map((c) => c.collaboratorUserDetail)
    );
  }, [clientCollaborators, collaboratorUsers]);

  const isError = selectedCollaborators.length === 0;
  const onAssignCollaborator = () => {
    if (props && selectedCollaborators.length > 0 && props.selectedClientId) {
      const payload = {
        clientId: props.selectedClientId,
        collaboratorIds: selectedCollaborators.map((c) => c._id),
      };
      dispatch(createCollaboratorClient(payload, languageStrings));
      props.onClose();
    } else {
      toast.error("Something went wrong");
    }
  };
  const handleChange = (event) => {
    setCollaboratorId(event.target.value);
    const found = collaboratorUsers.find(
      (item) => item._id === event.target.value
    );
    if (
      found &&
      !selectedCollaborators.some((item) => item._id === found._id)
    ) {
      setSelectedCollaborators((prev) => [...prev, found]);
    }
  };
  const headerRows = {
    data: [
      {
        id: "firstName",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.FIRST_NAME"],
        sort: false,
        type: "text",
      },
      {
        id: "lastName",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.LAST_NAME"],
        sort: false,
        type: "text",
      },
      {
        id: "role",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ROLE"],
        sort: false,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: false,
        type: "text",
      },
    ],
    checkbox: true,
    clickable: false,
    locationSearch: false,
    searchBy: ["name"],
  };
  const onHandleDelete = (selected) => {
    const payload = {
      collaboratorIds: selected,
      clientId: props.selectedClientId,
    };
    dispatch(deleteCollaboratorClient(payload, languageStrings));
    props.onClose();
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 className="pt-8 pb-6" id="transition-modal-title">
            {languageStrings["ASSIGN_COLLABORATOR"]}
          </h2>
          <p id="transition-modal-description">
            <AssignCollaboratorDropDown
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              handleChange={handleChange}
              value={collaboratorId}
            />
          </p>
          {selectedCollaborators.length > 0 && (
            <ListTablePaginated
              handleDelete={onHandleDelete}
              headerRows={headerRows}
              data={selectedCollaborators}
              totalData={selectedCollaborators.length}
              order={order}
              page={page}
              rowsPerPage={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={handlePageChange}
            />
          )}
          <div className="flex mt-10 items-center justify-center">
            <div
              style={{
                color: "white",
                textDecoration: "none",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "red",
                cursor: "pointer",
                marginRight: "0.8rem",
              }}
              onClick={props.onClose}
            >
              {languageStrings["GENERAL.CANCEL_BUTTON"]}
            </div>

            <div
              style={{
                color: "white",
                textDecoration: "none",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: isError ? "grey" : "#1D6F42",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isError) {
                  onAssignCollaborator();
                }
              }}
            >
              {languageStrings["GENERAL.SAVE"]}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default AssignCollaboratorModal;
