import React from "react";
import { TableRow } from "@material-ui/core";

import ImageCell from "./ImageCell";
import CheckBoxCell from "./CheckBoxCell";
import SuccessAndDangerCell from "./SuccessAndDangerCell";
import PriceReduceCell from "./PriceReduceCell";
import IconCell from "./IconCell";
import DateTimeCell from "./DateTimeCell";
import DateCell from "./DateCell";
import MenuTypeCell from "./MenuTypeCell";
import OrderStatusCell from "./OrderStatusCell";
import ButtonCell from "./ButtonCell";
import OrderTypeCell from "./OrderTypeCell";
import DateComparison from "./DateComparison";
import AccessLevelCell from "./AccessLevelCell";
import CreatedTypeCell from "./CreatedTypeCell";
import ActionsCell from "./ActionsCell.js";
import VenueStatusCell from "./VenueStatusCell";
import TruncatedText from "./TruncatedText";
import SingleBtn from "./SingleBtn";

const RowComponent = ({
  isSelected,
  headerRows,
  handleCheck,
  handleClick,
  n,
}) => {
  return (
    <TableRow
      className={headerRows.clickable ? "h-64 cursor-pointer" : "h-64"}
      hover={headerRows.clickable}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={n._id}
      selected={isSelected}
      onClick={(event) => headerRows.clickable && handleClick(n)}
    >
      {headerRows.checkbox && (
        <CheckBoxCell
          isSelected={isSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleCheck(event, n._id)}
        />
      )}
      {headerRows.data.map((obj) => {
        switch (obj.type) {
          case "image":
            return <ImageCell src={n.image} alt={n.title} />;

          case "text":
            return <SuccessAndDangerCell n={n} obj={obj} />;
          case "truncatedText":
            return <TruncatedText n={n} obj={obj} />;

          case "date":
            return <DateCell date={n[obj.id]} />;

          case "time":
            return <DateTimeCell date={n[obj.id]} />;

          case "dateComparison":
            return <DateComparison data={n} />;

          case "reducedPrice":
            return <PriceReduceCell n={n} />;

          case "orderType":
            return <OrderTypeCell orderType={n[obj.id]} />;

          case "orderStatus":
            return <OrderStatusCell orderStatus={n.orderStatus} />;

          case "venueStatus":
            return <VenueStatusCell status={n.status} />;
          case "singleBtn":
            return <SingleBtn actions={obj.actions} n={n} />;
          case "icon":
            return <IconCell platform={n.platform} />;

          case "bool":
            return <MenuTypeCell n={n.isPdf} />;
          case "button":
            return (
              <ButtonCell
                keys={n._id}
                text={obj.text}
                onButtonClick={obj.onButtonClick}
                _id={n._id}
              />
            );
          case "accessLevel":
            return <AccessLevelCell n={n.accessLevel} />;
          case "actions":
            return <ActionsCell actions={obj.actions} n={n} />;

          case "createdType":
            return (
              <CreatedTypeCell
                key={obj.id + n._id}
                createdByCompany={n.createdByCompany}
                name={`${n.firstName}`}
              />
            );

          default:
            return null;
        }
      })}
    </TableRow>
  );
};

export default RowComponent;
