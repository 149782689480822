import React, { useEffect } from "react";
import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";

import { useDispatch, useSelector } from "react-redux";
import { getTaxes } from "app/store/client/ClientActions";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

const TaxPicker = ({
  value = { name: "IVA", percentage: "10", _id: "625547db73200dd83f4b74cc" },
  onChange,
}) => {
  const dispatch = useDispatch();
  const { taxes } = useSelector((state) => state.banzzu.client);
  const { languageStrings } = useLanguageHelper();
  useEffect(() => {
    dispatch(getTaxes());
  }, [dispatch]);
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center`}
      style={{ marginTop: -7 }}
    >
      <InputLabel className={`mt-8`} htmlFor="outlined-venue-native-simple">
        {languageStrings["TAX"]}
      </InputLabel>
      <Select
        className="mt-8 mb-16 mr-8"
        native
        fullWidth
        value={value._id}
        onChange={(e) => {
          const _tax = taxes.find((item) => item._id === e.target.value);
          onChange(e.target.value, _tax);
        }}
        disabled={isSalesPerson}
        input={
          <OutlinedInput
            name="selectTax"
            labelWidth={60}
            id="outlined-venue-native-simple"
          />
        }
      >
        {taxes.map((val) => (
          <option value={val._id}>{`${val.name} - ${val.percentage}`}</option>
        ))}
      </Select>
    </FormControl>
  );
};

export default TaxPicker;
