import { axiosInstance } from "app/api/axios";

import { AuthActionTypes } from "../redux/actionTypes";
import { User } from "app/models/User";
import {
  loginUserUrl,
  LOGOUT,
  getRegisterUserUrl,
  getUserInfoUrl,
  getReferralsUrl,
  getUserId,
  changeCollaboratorPasswordUrl,
  LOGIN_STAFF,
  CHANGE_PASSWORD,
} from "app/api/Endpoint";
import history from "@history";
import { errorAlert, successAlert } from "app/helpers/alerts";
import { clientSettings } from "config";
import {
  STORAGE_KEY,
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "app/helpers/storageHelper";

export const changeUserLanguage = (locale: string) => {
  return async (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHANGE_LANGUAGE_START,
    });

    const request = {
      collaboratorId: getStorageItem(STORAGE_KEY.USER_ID),
      locale,
    };

    let res = await axiosInstance.patch("collaborator/", request);

    if (res.data.statusCode === 200) {
      changeUserLanguageSuccess(dispatch);
    } else {
      changeUserLanguageFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
};

const changeUserLanguageFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.CHANGE_LANGUAGE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const changeUserLanguageSuccess = (dispatch) => {
  dispatch({
    type: AuthActionTypes.CHANGE_LANGUAGE_SUCCESS,
  });
};
export const submitLogin = (user: User) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_USER_START,
    });

    const request = {
      email: user.username,
      password: user.password,
    };
    const url = LOGIN_STAFF;

    axiosInstance
      .post(url, request)
      .then((res) => {
        const { data } = res.data;
        const { admin } = data;
        setStorageItem(STORAGE_KEY.ACCESS_TOKEN, admin.accessToken);
        setStorageItem(STORAGE_KEY.USER_ID, admin.staffId);
        setStorageItem(STORAGE_KEY.IS_ADMIN, data.isAdmin);
        setStorageItem(STORAGE_KEY.USER_ROLE, admin.role);
        setStorageItem(STORAGE_KEY.USER_EMAIL, admin.email);
        setStorageItem(STORAGE_KEY.LOGIN_TYPE, data.loginType);
        loginUserSuccess(dispatch, data);
      })
      .catch((e) => {
        loginUserFail(dispatch, "There was an error connection");
      });
  };
};

const loginUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.LOGIN_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const loginUserSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.LOGIN_USER_SUCCESS,
    payload: data,
  });
  history.replace({
    pathname: "/clientsList",
  });
  window.location.reload();
};

export const userLogout = () => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGOUT_USER_START,
    });
    let url = LOGOUT;
    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        userLogoutSuccess(dispatch, data);
      })
      .catch((e) => {
        if (e.message === "Request failed with status code 400") {
          errorAlert(dispatch, "Ya existe el correo electrónico");
        }
        userLogoutFail(dispatch, "There was an error connection");
      });
  };
};
const userLogoutFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.LOGOUT_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const userLogoutSuccess = (dispatch, data) => {
  removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
  removeStorageItem(STORAGE_KEY.USER_ID);
  removeStorageItem(STORAGE_KEY.IS_ADMIN);
  removeStorageItem(STORAGE_KEY.USER_ROLE);
  removeStorageItem(STORAGE_KEY.USER_EMAIL);
  removeStorageItem(STORAGE_KEY.LOGIN_TYPE);
  dispatch({
    type: AuthActionTypes.LOGOUT_USER_SUCCESS,
    payload: data,
  });

  history.replace({
    pathname: `/login/${clientSettings.language}`,
  });
  window.location.reload();
};
export const registerUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.REGISTER_USER_START,
    });

    const url = getRegisterUserUrl();
    const request = data;
    axiosInstance
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        registerUserSuccess(dispatch, data, request);
      })
      .catch((e) => {
        if (e.message === "Request failed with status code 400") {
          errorAlert(dispatch, "Ya existe el correo electrónico");
        }
        registerUserFail(dispatch, "There was an error connection");
      });
  };
};

const registerUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.REGISTER_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const registerUserSuccess = (dispatch, data, request) => {
  dispatch({
    type: AuthActionTypes.REGISTER_USER_SUCCESS,
    payload: data,
  });
  dispatch(
    submitLogin({ username: request.email, password: request.password })
  );
};
export const getUserInfo = (referrals?: boolean) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_INFO_START,
    });

    const userId = getStorageItem(STORAGE_KEY.USER_ID);
    const url = getUserInfoUrl(userId);

    axiosInstance
      .get(url)
      .then((res) => {
        getUserInfoSuccess(dispatch, res.data, referrals);
      })
      .catch((e) => {
        getUserInfoFail(dispatch, "There was an error connection");
      });
  };
};

const getUserInfoFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_INFO_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUserInfoSuccess = (dispatch, { data }, referrals) => {
  dispatch({
    type: AuthActionTypes.USER_INFO_SUCCESS,
    payload: data,
  });
  if (referrals) {
    dispatch(getReferrals(data.code));
  }
};
export function setReferralSearchText(event) {
  return {
    type: AuthActionTypes.SET_REFERRALS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getReferrals = (code) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.REFERRALS_LIST_START,
    });

    const url = getReferralsUrl(code);

    axiosInstance
      .get(url)
      .then((res) => {
        getReferralsSuccess(dispatch, res.data);
      })
      .catch((e) => {
        getReferralsFail(dispatch, "There was an error connection");
      });
  };
};

const getReferralsFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.REFERRALS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getReferralsSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.REFERRALS_LIST_SUCCESS,
    payload: data.data,
  });
};

export const changePassword = (password: string) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHANGE_PASSWORD_START,
    });

    const request = {
      password,
    };
    const url = CHANGE_PASSWORD;
    axiosInstance
      .put(url, request)
      .then((res) => {
        changePasswordSuccess(dispatch, res.data);
      })
      .catch(() => {
        changePasswordFail(dispatch, "There was an error connection");
      });
  };
};

const changePasswordFail = (dispatch, errorMessage) => {
  successAlert(dispatch, errorMessage);
  dispatch({
    type: AuthActionTypes.CHANGE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const changePasswordSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: data,
  });
  // History.push({
  //   pathname: "/",
  // });
};
