import React from "react";
import _ from "@lodash";
import { Typography, Avatar, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const CollaboratorDetailCard = ({ clientDetails }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <div className="pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Avatar className="mr-8" src={clientDetails.image} />
          <Typography className="h2" color="textSecondary">
            {clientDetails.firstName + " " + clientDetails.lastName}
          </Typography>
        </div>
        <div className="table-responsive ">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.EMAIL"]}</th>
                <th>{languageStrings["GENERAL.PHONE"]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography className="truncate">
                    {clientDetails.email}
                  </Typography>
                </td>
                <td>
                  <Typography className="truncate">
                    {clientDetails.phoneNumber}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default CollaboratorDetailCard;
