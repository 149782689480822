import { TextField } from "@material-ui/core";
import {
  DEFAULT_SMS_NOTIFICATION_MESSAGES,
  SMS_NOTIFICATION_PATTERNS,
} from "app/helpers/constants";
import { NOTIFICATION_MESSAGE_VALIDATION_PATTERN } from "app/helpers/constants/pattern";
import { dosePatternPresentInString } from "app/helpers/utilsHelper";
import * as React from "react";
import { useSelector } from "react-redux";

export default function NotificationMessagesInputs({
  handleChange,
  venueDetails,
  languageStrings,
  selectedVenue,
  error,
}) {
  const { messages = {} } = useSelector(
    (state) => state.banzzu.client.venueDetail
  );
  const { title } = selectedVenue || {};
  const {
    notifyOrderMessage,
    partialDeliveryMessage,
    fullDeliveryMessage,
    messageClaveApi,
    messageSenderId,
  } = venueDetails;

  const [
    defaultNotifyOrderMessage,
    defaultPartialDeliveryMessage,
    defaultFullDeliveryMessage,
  ] = React.useMemo(() => {
    return [
      messages.notifyOrderMessage ||
        DEFAULT_SMS_NOTIFICATION_MESSAGES.SPANISH.NOTIFY_ORDER_MESSAGE.replaceAll(
          SMS_NOTIFICATION_PATTERNS.VENUE_NAME,
          title
        ),
      messages.partialDeliveryMessage ||
        DEFAULT_SMS_NOTIFICATION_MESSAGES.SPANISH.PARTIAL_DELIVERY_MESSAGE.replaceAll(
          SMS_NOTIFICATION_PATTERNS.VENUE_NAME,
          title
        ),
      messages.fullDeliveryMessage ||
        DEFAULT_SMS_NOTIFICATION_MESSAGES.SPANISH.FULL_DELIVERY_MESSAGE.replaceAll(
          SMS_NOTIFICATION_PATTERNS.VENUE_NAME,
          title
        ),
    ];
  }, []);

  const isNotifyOrderMessage = React.useMemo(() => {
    return dosePatternPresentInString(
      notifyOrderMessage,
      NOTIFICATION_MESSAGE_VALIDATION_PATTERN
    );
  }, [notifyOrderMessage]);
  const isPartialDeliveryMessage = React.useMemo(() => {
    return dosePatternPresentInString(
      partialDeliveryMessage,
      NOTIFICATION_MESSAGE_VALIDATION_PATTERN
    );
  }, [partialDeliveryMessage]);
  const isFullDeliveryMessage = React.useMemo(() => {
    return dosePatternPresentInString(
      fullDeliveryMessage,
      NOTIFICATION_MESSAGE_VALIDATION_PATTERN
    );
  }, [fullDeliveryMessage]);

  // added below useEffect for initial case when we didn't have this field we have to pass in payload with default value
  React.useEffect(() => {
    if (!notifyOrderMessage) {
      handleChange("notifyOrderMessage", defaultNotifyOrderMessage);
    }
    if (!partialDeliveryMessage) {
      handleChange("partialDeliveryMessage", defaultPartialDeliveryMessage);
    }
    if (!fullDeliveryMessage) {
      handleChange("fullDeliveryMessage", defaultFullDeliveryMessage);
    }
  }, []);

  return (
    <>
      <div className="my-16">
        <div className="flex justify-between">
          <TextField
            label={languageStrings["NOTIFICATION.CREATE_ORDER_MESSAGE"]}
            id="extraMessage"
            name="notifyOrderMessage"
            defaultValue={defaultNotifyOrderMessage}
            value={notifyOrderMessage}
            onChange={(event) =>
              handleChange("notifyOrderMessage", event.target.value)
            }
            variant="outlined"
            multiline
            fullWidth
            rows={3}
            error={!isNotifyOrderMessage}
            helperText={
              !isNotifyOrderMessage &&
              languageStrings["NOTIFICATION.ERROR_STRING_MESSAGE"]
            }
          />
          <TextField
            className="mx-16"
            label={languageStrings["NOTIFICATION.TAKE_PARTIALLY_MESSAGE"]}
            id="partialDeliveryMessage"
            name="partialDeliveryMessage"
            defaultValue={defaultPartialDeliveryMessage}
            value={partialDeliveryMessage}
            onChange={(event) =>
              handleChange("partialDeliveryMessage", event.target.value)
            }
            variant="outlined"
            multiline
            fullWidth
            error={!isPartialDeliveryMessage}
            helperText={
              !isPartialDeliveryMessage &&
              languageStrings["NOTIFICATION.ERROR_STRING_MESSAGE"]
            }
            rows={3}
          />
          <TextField
            label={languageStrings["NOTIFICATION.TAKE_FULLY_MESSAGE"]}
            id="fullDeliveryMessage"
            name="fullDeliveryMessage"
            value={fullDeliveryMessage}
            defaultValue={defaultFullDeliveryMessage}
            onChange={(event) =>
              handleChange("fullDeliveryMessage", event.target.value)
            }
            variant="outlined"
            multiline
            fullWidth
            error={!isFullDeliveryMessage}
            helperText={
              !isFullDeliveryMessage &&
              languageStrings["NOTIFICATION.ERROR_STRING_MESSAGE"]
            }
            rows={3}
          />
        </div>
        <div className="flex justify-between mt-16">
          <TextField
            label={`${languageStrings["NOTIFICATION.SENDER_ID"]}`}
            id="messageSenderId"
            name="messageSenderId"
            value={messageSenderId}
            onChange={(event) => {
              handleChange("messageSenderId", event.target.value);
            }}
            variant="outlined"
            fullWidth
            inputProps={{
              minlength: "1",
              maxlength: "11",
            }}
            error={error}
          />
          <TextField
            className="ml-16"
            label={`${languageStrings["NOTIFICATION.CLAVE_API"]}`}
            id="messageClaveApi"
            name="messageClaveApi"
            value={messageClaveApi}
            onChange={(event) => {
              handleChange("messageClaveApi", event.target.value);
            }}
            variant="outlined"
            fullWidth
            error={error}
          />
        </div>
      </div>
    </>
  );
}
