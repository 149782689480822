import { FAILED_PAYMENTS } from "app/api/Endpoint";
import { axiosInstance as axios } from "app/api/axios";
import { FailedPaymentsActionTypes } from "../redux/actionTypes";
import { errorAlert, successAlert } from "app/helpers/alerts";
export function setFailedPaymentSearchText(event) {
  return {
    type: FailedPaymentsActionTypes.SET_FAILED_PAYMENTS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getFailedPaymentList = ({
  searchText = "",
  page = 1,
  limit = 10,
  initialDate,
  finalDate,
}) => {
  return (dispatch) => {
    dispatch({
      type: FailedPaymentsActionTypes.FAILED_PAYMENTS_START,
    });
    let url = `admin/${FAILED_PAYMENTS}?searchText=${searchText}&page=${page}&limit=${limit}`;
    if (initialDate && finalDate) {
      url += `&initialDate=${initialDate}&finalDate=${finalDate}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getFailedPaymentListSuccess(dispatch, res.data);
        } else {
          getFailedPaymentListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getFailedPaymentListFail(dispatch, "There was an error connection");
      });
  };
};

const getFailedPaymentListFail = (dispatch, errorMessage) => {
  dispatch({
    type: FailedPaymentsActionTypes.FAILED_PAYMENTS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getFailedPaymentListSuccess = (dispatch, data) => {
  dispatch({
    type: FailedPaymentsActionTypes.FAILED_PAYMENTS_SUCCESS,
    payload: data,
  });
};
export const paymentRefund = ({ _id, params, successMessage = "" }) => {
  return (dispatch) => {
    dispatch({
      type: FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_START,
    });
    const url = `admin/refund/failed-payments`;
    axios
      .post(url, { _id })
      .then((res) => {
        successAlert(dispatch, successMessage);
        paymentRefundSuccess(dispatch, res.data, params);
      })
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : "There was an error connection";
        errorAlert(dispatch, errorMessage);
        paymentRefundFail(dispatch, "There was an error connection");
      });
  };
};

const paymentRefundFail = (dispatch, errorMessage) => {
  dispatch({
    type: FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_SUCCESS,
    payload: {
      errorMessage,
    },
  });
};

const paymentRefundSuccess = (dispatch, data, params) => {
  dispatch({
    type: FailedPaymentsActionTypes.FAILED_PAYMENTS_REFUND_FAIL,
    payload: data,
  });
  if (params) {
    dispatch(getFailedPaymentList(params));
  }
};
