import { TwilioMessagesActionTypes } from "../redux/actionTypes";

const INITIAL_STATE = {
  messagesList: [],
  searchText: "",
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const TwilioMessagesReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case TwilioMessagesActionTypes.SET_TWILIO_MESSAGES_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    case TwilioMessagesActionTypes.GET_TWILIO_MESSAGES_LIST_START: {
      return { ...state, loading: true };
    }
    case TwilioMessagesActionTypes.GET_TWILIO_MESSAGES_LIST_SUCCESS: {
      return {
        ...state,
        messagesList: action.payload,
        loading: false,
      };
    }
    case TwilioMessagesActionTypes.GET_TWILIO_MESSAGES_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default TwilioMessagesReducer;
