import React, { useEffect } from "react";
import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "app/store/client/ClientActions";

export const CurrencyTypeDropdown = ({
  value = "66ded580b29f52c62361dd87",
  onChange,
}) => {
  const { languageStrings } = useLanguageHelper();

  const dispatch = useDispatch();
  const { currencies } = useSelector((state) => state.banzzu.client);
  useEffect(() => {
    dispatch(getCurrencies());
  }, [dispatch]);
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center `}
      style={{ marginTop: -14, marginLeft: 7 }}
    >
      <InputLabel className={`mt-16`} htmlFor="outlined-venue-native-simple">
        {languageStrings["GENERAL.CURRENCY_TYPE"]}
      </InputLabel>
      <Select
        native
        fullWidth
        value={value}
        onChange={(e) => {
          const _tax = currencies.find((item) => item._id === e.target.value);
          onChange(e.target.value, _tax);
        }}
        style={{ paddingBottom: 1 }}
        input={
          <OutlinedInput
            name="Currency Type"
            labelWidth={100}
            id="outlined-venue-native-simple"
          />
        }
      >
        {currencies.map((val) => (
          <option key={val._id} value={val._id}>{`${
            languageStrings[val.translationKey]
          } - ${val.symbol}`}</option>
        ))}
      </Select>
    </FormControl>
  );
};
