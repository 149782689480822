import React, { useState, useEffect } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { isEmailValid } from "app/helpers/validators";
import { useForm } from "@fuse/hooks";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { createClient, checkEmail } from "app/store/client/ClientActions";
import CountryPicker from "../common/CountryPicker";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

export default function CreateClientPage() {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  const emailTaken = useSelector(({ banzzu }) => banzzu.client.emailTaken);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);

  const [validEmail, setValidEmail] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    phone: "",
    phoneNumber: "",
    city: "",
    postcode: "",
    address: "",
    referralCode: "",
    comments: "",
    locale: "es",
  };

  const { form, handleChange, setForm } = useForm(initialData);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    const {
      firstName,
      lastName,
      email,
      title,
      phone,
      city,
      postcode,
      address,
    } = form;

    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      title.length > 0 &&
      phone.length > 0 &&
      city.length > 0 &&
      postcode.length > 0 &&
      address.length > 0 &&
      !emailTaken
    );
  }

  useEffect(() => {
    setForm((prevForm) => ({ ...prevForm, referralCode: user.code }));
  }, [user]);

  const onEmailChange = (e) => {
    handleChange(e);
    if (!isEmailValid(e.target.value)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
      dispatch(checkEmail(e.target.value));
    }
  };

  const onSubmit = () => {
    dispatch(createClient(form));
    setForm(initialData);
  };

  return (
    <LoadingOverlay
      active={false}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          //   overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {languageStrings["CLIENT.ADD_CLIENT"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={onSubmit}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24">
              <div>
                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.FIRST_NAME"]}
                    id="firstName"
                    name="firstName"
                    value={form.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.LAST_NAME"]}
                    id="lastName"
                    name="lastName"
                    value={form.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.PHONE"]}
                    id="phoneNumber"
                    name="phoneNumber"
                    value={form.phoneNumber}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.EMAIL"]}
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={onEmailChange}
                    variant="outlined"
                    fullWidth
                    error={!validEmail || emailTaken}
                    helperText={
                      !validEmail
                        ? languageStrings["ERRORS.INVALID_EMAIL"]
                        : emailTaken
                        ? languageStrings["ERRORS.EMAIL_TAKEN"]
                        : ""
                    }
                  />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.RESTAURANT_NAME"]}
                    id="title"
                    name="title"
                    value={form.title}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.RESTAURANT_PHONE"]}
                    id="phone"
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.CITY"]}
                    id="city"
                    name="city"
                    value={form.city}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.POST_CODE"]}
                    id="postcode"
                    name="postcode"
                    value={form.postcode}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.ADDRESS"]}
                    id="address"
                    name="address"
                    value={form.address}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                  <CountryPicker value={form.locale} onChange={handleChange} />
                </div>

                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    required
                    label={languageStrings["GENERAL.COMMENTS"]}
                    id="comments"
                    name="comments"
                    value={form.comments}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </div>
              </div>
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}
