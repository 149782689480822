import { SMS_NOTIFICATION_PATTERNS } from "app/helpers/constants";

export default {
  "HOME_PAGE.RESTAURANT": "Restaurant",
  "HOME_PAGE.YOUR_CODE": "Votre code QR",
  "ERRORS.IMAGE_IS_REQUIRED": "L’image est obligatoire",
  "ERRORS.FIELD_IS_REQUIRED": "Champ requis",
  "ERRORS.INVALID_EMAIL": "E-mail incorrect",
  "ERRORS.INVALID_PHONE": "Mauvais téléphone",
  "ERRORS.INVALID_URL": "URL incorrecte",
  "ERRORS.INVALID_LOGIN": "Connexion incorrecte. Veuillez réessayer,",
  "ERRORS.MINIMUM_10_CHAR": "Taille minimale 10 caractères",
  "ERRORS.MINIMUM_3_CHAR": "Taille minimum 3 caractères",
  "ERRORS.MINIMUM_1_CHAR": "Taille minimale 1 caractère",
  "ERRORS.MINIMUM_4_CHAR": "Taille minimale 4 caractères",
  "ERRORS.MINIMUM_6_CHAR": "Taille minimale 6 caractères",
  "ERRORS.EMAIL_TAKEN": "L’e-mail saisi existe déjà",
  "ERRORS.MUST_BE_NUMBER": "Il doit s’agir d’un nombre",
  "GENERAL.CITY": "Ville",
  "GENERAL.CREATE": "Créer",
  "GENERAL.ADD_BUTTON": "Ajouter",
  "GENERAL.BASIC_INFO": "Informations de base",
  "GENERAL.CANCEL_BUTTON": "Annuler",
  "GENERAL.CLIENT": "Client",
  "GENERAL.CONFIRM_BUTTON": "Confirmer",
  "GENERAL.CONTACT_INFO": " Coordonnées ",
  "GENERAL.DATE": "Date",
  "GENERAL.DELETE": "Supprimer",
  "GENERAL.DENY_BUTTON": "Annuler",
  "GENERAL.DESCRIPTION": "Description",
  "GENERAL.DETAIL": "Détail",
  "GENERAL.EMAIL": "Email",
  "GENERAL.IMAGE": "Image",
  "GENERAL.NAME": "Nom",
  "GENERAL.PASSWOR": "Mot de passe",
  "GENERAL.PHONE": "Téléphone",
  "GENERAL.POINTS": "Points",
  "GENERAL.POSTCODE": "Code postal",
  "GENERAL.PRICE": "Montant",
  "GENERAL.ROWS_PER_PAGE": "Enregistrements par page",
  "GENERAL.SAVE": "Sauvegarder",
  "GENERAL.SAVE_PREVIEW": "Enregistrer et prévisualiser",
  "GÉNÉRAL.SAVE_BUTTON": "Sauvegarder",
  "GENERAL.SEARCH": "Rechercher",
  "GENERAL.SEND": "Envoyer",
  "GENERAL.TIME": "Temps",
  "GENERAL.TITLE": "Titre",
  "GENERAL.TYPE": "Type",
  "GENERAL.USER_LIST": "Liste des utilisateurs",
  "GENERAL.VENUE": "Local",
  "GENERAL.STATUS": "Etat",
  "GENERAL.WEBSITE": "Site Web",
  "GENERAL.CATEGORY": "Catégorie",
  "GENERAL.ITEMS": "Articles",
  "GENERAL.ACTIONS": "Actions",
  "GENERAL.REF": "Ref #",
  "GENERAL.ALERT_TITLE": "Confirmation",
  "GENERAL.LATITUDE": "Latitude",
  "GENERAL.LONGITUDE": "Longueur",
  "HOME_PAGE.PENDING_ORDERS": "Commandes en attente",
  "GENERAL.ALERT_DESC":
    "Êtes-vous sûr de vouloir revenir en arrière sans enregistrer les modifications?",
  "GENERAL.REMOV": "Supprimer",
  "GENERAL.PLATFORM": "Plateforme",
  "GENERAL.TO": "Jusqu’à",
  "GENERAL.YES": "Oui",
  "GENERAL.NO": "Non",
  "GENERAL.VISIT_WEB": "Visiter le Web",
  "GENERAL.ACCESS_CLIENT": "Accès Client",
  "GENERAL.YOUR_CODE": "Votre code est",
  "GENERAL.COPY_CODE": "Copier le code",
  "GENERAL.ORDER_TYPE": "Type de commande",
  "GENERAL.FIRST_NAME": "Prénom",
  "GENERAL.LAST_NAME": "Nom",
  "GENERAL.RESTAURANT_NAME": "Nom du restaurant",
  "GENERAL.RESTAURANT_PHONE": "Téléphone du restaurant",
  "GENERAL.POST_CODE": "Code postal",
  "GENERAL.ADRESS": "Adresse",
  "GENERAL.COMMENTS": "Commentaires",
  "GENERAL.EXPORT": "Exporter",
  "GENERAL.EXPORT_CSV": "Télécharger csv",
  "GENERAL.EXPORT_XLS": "Télécharger xls",
  "GENERAL.COMMISSIONS": "commissions",
  "GENERAL.RESTAURANT": "Restaurant",
  "GENERAL.TABLE": "Tableau",
  "GENERAL.DATE_TIME": "FR Date et l'heure",
  "GENERAL.ROLE": "Rôle",

  "LOGIN_PAGE.LOGIN_BUTTON": "CONNEXION ",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "CONNECTEZ-VOUS À VOTRE COMPTE",
  "LOGIN_PAGE.REGISTER_LINK": "Vous n’avez pas de compte ? Inscrivez-vous,",
  "LOGIN_PAGE.EMAIL": "Email",
  "LOGIN_PAGE.PASSWORD": "Mot de passe",
  "SIDEBAR_MENUE.HOME": "Accueil",
  "SIDEBAR_MENUE.MENUE_LIST": "Menu Lista",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menu",
  "SIDEBAR_MENUE.TITLE": "Menu",
  "SIDEBAR_MENUE.TRANSACTION": "Liste des transactions",
  "SIDEBAR_MENUE.USER_LIST": "Utilisateurs",
  "SIDEBAR_MENUE.CLIENT_LIST": "Liste des clients",
  "SIDEBAR_MENUE.CLIENT": "Client",
  "SIDEBAR_MENUE.ADD_CLIENT": "Créer un client",
  "SIDEBAR_MENUE.REFERRALS": "Référé à",
  "SIDEBAR_MENUE.ORDERS": "Commandes",
  "SIDEBAR_MENUE.FAILED_PAYMENTS": "Paiements non effectués",
  "SIDEBAR_MENUE.ORDERS_LIST": "Liste de commandes",
  "SIDEBAR_MENUE.ANALYTICS": "Analytique ",
  "USER_LIST_PAGE.TITLE": "Liste des utilisateurs",
  "USER_DETAIL.BACK_LIST": "Liste des utilisateurs",
  "REGISTER_PAGE.TITLE": "Commencer n’a jamais été aussi facile",
  "REGISTER_PAGE.FIRST_NAME": "Prénom",
  "REGISTER_PAGE.LAST_NAME": "Nom",
  "REGISTER_PAGE.EMAIL": "Email",
  "REGISTER_PAGE.TELEPHONE": "Téléphone",
  "REGISTER_PAGE.PASSWORD": "Mot de passe",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Conditions Générales",
  "REGISTER_PAGE.PRIVACY_POLICY": "Politique de confidentialité",
  "REGISTER_PAGE.ACCEPT": "ACCEPTER",
  "REGISTER_PAGE.BUTTON_TEXT": "Continuer",
  "REGISTER_PAGE.LOGIN_LINK": "Avez-vous déjà un compte?",
  "REGISTER_PAGE.LOGIN": "Connexion",
  "REGISTER_PAGE.NEW_IN_BANZZU": "Nouveau à Banzzu?",
  "REGISTER_PAGE.SUB_TITLE_1 ":
    "Partez à la conquête de l’univers numérique de vos locaux",
  "REGISTER_PAGE.SUB_TITLE_2": "et découvrez toutes vos possibilités",
  "CLIENT.REGISTRATION_DATE": "Date d’enregistrement",
  "CLIENT.SUBSCRIPTION": "Abonnement",
  "CLIENT.CLIENT_LIST": "Liste des clients",
  "CLIENT.CLIENT_DETAILS": "Détail du client",
  "CLIENT.GOALS": "Objectifs",
  "CLIENT.ACCESS_LEVEL_PLACEHOLDER": "Niveau d’accès",
  "CLIENT.ALL": "Tous",
  "CLIENT.REFERRED_BY": "Désigné par",
  "CLIENT.ACTIONS": "Comportement",
  "CLIENT.LAST_INTERACTION": "Dernière interaction",
  "CLIENT.ADD_CLIENT": "Créer un client",
  "CLIENT.CREATED_TYPE": "Manuel créé",
  "ORDER_LIST_PAGE.TITLE": "Commandes",
  "ORDER_LIST_PAGE.ALL_STATUS": "Tous les États",
  "ORDER_LIST_PAGE.ALL_TYPES": "Tous les types",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Sélectionner le statut",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Sélectionner le type",
  "ORDER_PAGE.CUSTOMER": "Client",
  "ORDER_PAGE.EMAIL": "Email",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Nom",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Accepter les commandes",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Annuler",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "Prêt à ramasser",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "Commande collectée",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Préparation",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Commencer l’expédition",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Terminé",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Commander",
  "ORDER_PAGE.ORDERS": "Commandes",
  "ORDER_PAGE.ORDER_DETAIL": "Détail de la commande",
  "ORDER_PAGE.PHONE": "Téléphone",
  "ORDER_PAGE.PRODUCTS": "Produits",
  "ORDER_PAGE.QUANTITY": "Quantité",
  "ORDER_PAGE.DELIVERY": "Adresse de livraison ",
  "ORDER_PAGE.COMMENTS": "Commentaires",
  "ORDER_TYPE.DELIVERY": "À la maison",
  "ORDER_TYPE.COLLECT": "Collection locale",
  "ORDER_TYPE.FUTURE_ORDER": "Commande future",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Commande à partir de la table",
  "ORDER_STATUS.ORDER_NUMBER": "Référence de commande",
  "ORDER_STATUS.UPDATED_AT": "Date de création",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Payer en espèces",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Paiement accepté",
  "ORDER_STATUS.ORDER_ACCEPTED": "Commande acceptée",
  "ORDER_STATUS.PREPARING": "Préparation de la commande",
  "ORDER_STATUS.ON_DELIVERY": "Expédition",
  "ORDER_STATUS.DELIVERED": "Terminé",
  "ORDER_STATUS.CANDELED": "Annulé",
  "ORDER_STATUS.COLLECT": "Prêt à ramasser",
  "ORDER_STATUS.COLLECTED": "Commande collectée",
  "ORDER_STATUS.ORDER_TIME": "Heure de la commande",
  "ORDER_STATUS.ORDER_TABLE": "Table de commande",
  "REFERRAL.REFERRAL_LIST": "Liste des références",
  "HELP_MENU.CHANGE_PASSWORD": "Modifier le mot de passe",
  "HELP_MENU.LOGOUT": "Quitter",
  "ANALYTICS.WEEK_NUMBER": "Nombre de Semaine",
  "ANALYTICS.FIDELITY": "Fidélité",
  "ANALYTICS.ANALYTICS": "Analytiques",
  "ANALYTICS.ENGAGEMENT": "Engagement",
  "ANALYTICS.ACTIVE_FREE_USERS": "Utilisateurs actifs gratuits",
  "ANALYTICS.ACTIVE_PAID_USERS": "Utilisateurs actifs payants",
  COLLABORATOR: "Collaborateurs",
  "COLLABORATOR.LIST": "Liste des collaborateurs",
  "COLLABORATOR.GROUP": "Groupe de collaborateurs",
  "COLLABORATOR.CREATE": "Créer un collaborateur",
  "GENERAL.SURNAME": "Nom de famille",
  LOCALE: "Langue",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  NEW_PASSWORD: "Nouveau mot de passe",
  STRIPE_CONNECTED_ACCOUNT: "Compte Connecté Stripe",
  STRIPE_REMOVE_CONFIRMATION: "Confirmer la Suppression",
  STRIPE_REMOVE_CONFIRMATION_MESSAGE:
    "Êtes-vous sûr de vouloir supprimer cet élément ?",
  "GENERAL.REMOVE": "Supprimer",

  "VENUE_LIST.VENUE_LIST": "Liste des sites",
  "VENUE_LIST.CLIENT_NAME": "Nom du client",
  "VENUE_LIST.VENUE_NAME": "Nom du lieu",
  "VENUE_LIST.STATUS": "Statut",
  "VENUE_LIST.MARK_AS_REAL": "Marquer comme Réel",
  "VENUE_LIST.MARK_AS_TEST": "Marquer comme Test",
  "VENUE_STATUS.REAL": "Réel",
  "VENUE_STATUS.TEST": "Test",
  "VENUE.SELECT_VENUE_STATUS": "Select Venue Statue",
  "VENUE_STATUS.NOT_COMPUTE": "non calculé",
  "VENUE_STATUS.CLIENT_TESTING": "test client",
  "VENUE_LIST.CHANGE_DESCRIPTION": "Modifier la description",
  "VENUE_LIST.DESCRIPTION": "Description",
  PARTIAL_DELIVERY: "Livraison Partielle",
  IN_PROGRESS: "En Cours",
  DUPLICAR_MENU: "Duplicar Menu",
  ORIGINAL_CLIENT: "Client d'origine",
  MENU: "Menu",
  DESTINATION_CLIENT: "Client de destination",
  TWILIO_MESSAGES: "Messages de Twilio",
  MENU_DUPLICATE_SUCCESS: "Menu duplication réussie",
  DUPLICATE_BTN: "Dupliquer",
  MONTHS: "Mois",
  MESSAGES: "Messages",
  DATE: "Date",
  VENUE_DETAILS: "Établissements",
  RESTAURANT_CHARGES: "Coût par transaction",
  ROZAN_SOCIAL: "Nom de l'entreprise",
  CIF: "SIRET",
  AUTO_SERVICES: "Libre-Service",
  AUTO_SERVICES_SCREEN: "Libre-Service screen",
  Virtual_Basket: "Panier virtuel",
  PAY_AT_TABLE: "Payer à table",
  SERVICE: "Service supplémentaire",
  TAX: "Taxe",
  TICKET_NUMBER: "Ticket #",
  ORDER: "Pide ",
  ORDER_AND_PAY: "Pide y paga",
  PENDIGN_ORDERS: "Paiement en attente",
  TIP: "Conseil",
  EMAIL: "Email",
  WHATSAPP: "Whatsapp",
  SMS: "SMS",
  NOTIFICATION_METHOD: "Méthodes de notification",
  ENV_SWITCH: "Preuve de paiement",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "La date de fin doit être supérieure à la date de début",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "La date de début doit être antérieure à la date de fin",
  POPUP_SWITCH: "Activer Pop-up",

  INTEGRATIONS: "Intégrations",
  NO_INTEGRATION_DEFAULT: "Sans intégration",
  WRONG_MESSAGE: "Quelque chose a mal tourné",
  UPDATE_SUCCESS: "L'intégration a été mise à jour avec succès",
  MENU_TYPE: "Type de menu",
  URL_MENU: "URL",
  CHECK_MENU: "Menu Check",
  COCA_COLA_MENU: "Menu Coca Cola",
  SINQRO_ORDER_TYPE: "Type de commande",
  GENERAL_YES: "Oui",
  GENERAL_NO: "Non",
  CASH_PAY: "Paiement en espèces",
  ENABLE_RATING: "Activer le classement",
  RATING_URL: "URL de classement",
  LOYALTY_PROGRAM: "Programme de fidélisation",
  INVOICE_EXTRA_TEXT: "Réception d'un message supplémentaire",
  "SIDEBAR_MENUE.RATINGS": "Notations",
  "GENERAL.RATING": "Notations",
  AVERAGE_RATING: "Note moyenne",
  RATING_RANGE: "Fourchette de valeurs",
  SCHEDULE_ORDERS: "Planifier des commandes",
  TAKE_AWAY_ORDERS: "Commandes Take Away",
  NUMBER_OF_FUTURE_DAYS: "Jours disponibles pour emporter",
  STAFF: "Personnel",
  "STAFF.LIST": "liste Du Personnel",
  "STAFF.CREATE": "Créer Du Personnel",
  "STAFF.UPDATE": "Modifier le personnel",
  REVO_REFERENCE: "Référence Revo",
  CLIENT_INFO: "Information",
  MODIFY_CLIENT_DETAILS: "Modifier le formulaire",
  INTEGRATION: "Intégration",
  SALES_PERSON: "Commercial",
  FUTURE_ORDERS: "Programmée",
  "CREATE_COLLABORATOR.GROUP": "Créer Un Groupe De Collaborateurs",
  CREATE_GROUP: "Créer un groupe",
  NO_GROUP: "Aucun groupe",
  ENABLE_CATALOGUE: "Activer le catalogue",
  ENABLE_MENU_V2: "Activer le menu V2",
  ADMIN: "Administrateur",
  TECHNICIAN: "Technicien",
  QR_MENU: "Lettre QR",
  REQUIRE_NAME: "Exiger le nom",
  ASSIGN_COLLABORATOR: "Affecter un collaborateur",
  COllABORATED_CREATED: "Utilisateur collaborateur créé avec succès",
  COllABORATED_EDITED: "Utilisateur collaborateur modifié avec succès",
  COllABORATED_REMOVED: "Utilisateur collaborateur supprimé avec succès",
  CONNECTION_ERROR: "Une erreur de connexion s'est produite",
  COLLABORATOR_ASSIGNED: "Collaborateur attribué avec succès",
  COLLABORATOR_GROUP_UN_ASSIGNED: "Collaborateur non assigné avec succès",
  FOR_FREE: "Gratuit",
  CHECK_TABLES: "Vérifier les tables",
  EXTRATARRIF: "Frais supplémentaires",
  ENABLE_STOCK: "Activer le Stock",
  "NOTIFICATION.CREATE_ORDER_MESSAGE":
    "Message pour la création d'une commande",
  "NOTIFICATION.TAKE_PARTIALLY_MESSAGE":
    "Message pour les livraisons partielles",
  "NOTIFICATION.TAKE_FULLY_MESSAGE": "Message pour les livraisons complètes",
  SELECT_PAYMENT_PROVIDER: "Sélectionnez le fournisseur de paiement",
  "NOTIFICATION.ERROR_STRING_MESSAGE": `Les données d'entrée doivent contenir "${SMS_NOTIFICATION_PATTERNS.ID}" obligatoire`,
  "NOTIFICATION.SENDER_ID": "Sender ID",
  "NOTIFICATION.CLAVE_API": "Clave Api",
  HIDE_ORDER_BUTTON: "Masquer Le Bouton de Commande",
  ENABLE_KIOSK: "Mode kiosque",
  SELECT_TICKET_BAI: "Select Ticket Bai",
  TICKET_BAI: "Ticket Bai",
  LANGUAGES_MODAL: "Langues modales",
  REQUIRE_ADDRESS: "Adresse requise",
  PAYMENT_ID: "Id de paiement",
  FILTER_BY_DATE: "Filtrer Par Date",
  REFOUND: "Remboursement",
  REFOUND_SUCCESS: "Montant déjà remboursé",
  "GENERAL.CURRENCY_TYPE": "Type de Monnaie",
  ALLOW_ORDER_WITH_ZERO_AMOUNT: "Autoriser les commandes avec un montant de 0",
  EURO: "Euro",
  MEXICAN_PESO: "Peso mexicain",
};
