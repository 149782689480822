import React from "react";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export const orderStatuses = [
  {
    id: 0,
    name: "ORDER_STATUS.PAYMENT_BY_CASH",
    color: "#FFF",
    bgColor: "#388172",
  },
  {
    id: 1,
    name: "ORDER_STATUS.PAYMENT_ACCEPTED",
    color: "#FFF",
    bgColor: "#38C172",
  },
  {
    id: 2,
    name: "ORDER_STATUS.ORDER_ACCEPTED",
    color: "#000",
    bgColor: "#FFED4A",
  },
  {
    id: 3,
    name: "ORDER_STATUS.PREPARING",
    color: "#FFF",
    bgColor: "#F6993F",
  },
  {
    id: 4,
    name: "ORDER_STATUS.ON_DELIVERY",
    color: "#FFF",
    bgColor: "#9561E2",
  },
  {
    id: 5,
    name: "ORDER_STATUS.DELIVERED",
    color: "#FFF",
    bgColor: "#1F9D55",
  },
  {
    id: 6,
    name: "ORDER_STATUS.CANCELED",
    color: "#FFF",
    bgColor: "#F66D9B",
  },
  {
    id: 7,
    name: "ORDER_STATUS.COLLECT",
    color: "#FFF",
    bgColor: "#E3342F",
  },
  {
    id: 8,
    name: "ORDER_STATUS.COLLECTED",
    color: "#FFF",
    bgColor: "#005c27",
  },
  {
    id: -1,
    name: "HOME_PAGE.PENDING_ORDERS",
    color: "#FFF",
    bgColor: "#F2D024",
  },
  {
    id: 11,
    name: "PARTIAL_DELIVERY",
    color: "#FFF",
    bgColor: "#F2D",
  },
  {
    id: 12,
    name: "IN_PROGRESS",
    color: "#FFF",
    bgColor: "#9561E2",
  },
  {
    id: 13,
    name: "PENDIGN_ORDERS",
    color: "#FFF",
    bgColor: "#E3342F",
  },
  {
    id: 14,
    name: "FUTURE_ORDERS",
    color: "#FFF",
    bgColor: "#69D6D1",
  },
];

const OrdersStatus = (props) => {
  const status = _.find(orderStatuses, { id: Number(props.status) });
  const { languageStrings } = useLanguageHelper();
  return (
    <div
      style={{
        display: "inline",
        padding: " 0.4rem",
        backgroundColor: status && status.bgColor ? status.bgColor : "#FFAE00",
        color: status && status.color ? status.color : "#FFF",
      }}
      className="text-12 rounded truncate"
    > 
      {status && status.name ? languageStrings[status.name] : "N/A"}
    </div>
  );
};

export default OrdersStatus;
