import React from "react";
import {
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { DASHBOARD_ROLES } from "app/helpers/utilsHelper";
import { getUserRole } from "app/helpers/storageHelper";

export const ScheduleOrderDropdown = ({ value = false, onChange }) => {
  const { languageStrings } = useLanguageHelper();
  const isSalesPerson = getUserRole() === DASHBOARD_ROLES.SALESPERSON;
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={`flex justify-center `}
      style={{ marginTop: -16, marginBottom: 12 }}
    >
      <InputLabel
        className={"mr-12 mt-8"}
        htmlFor="outlined-venue-native-simple"
      >
        {languageStrings["TAKE_AWAY_ORDERS"]}
      </InputLabel>
      <Select
        className="mb-8"
        native
        fullWidth
        value={value}
        onChange={(e) => {
          onChange("isScheduleOrdersEnabled", e.target.value);
        }}
        disabled={isSalesPerson}
        style={{ paddingBottom: 1 }}
        input={
          <OutlinedInput
            name="Order Type"
            labelWidth={125}
            id="outlined-venue-native-simple"
          />
        }
      >
        <option value={false}>{languageStrings["GENERAL_NO"]}</option>
        <option value={true}>{languageStrings["GENERAL_YES"]}</option>
      </Select>
    </FormControl>
  );
};
