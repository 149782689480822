import React, { FC } from "react";
import CustomModal from "app/main/common/CustomModal";
import { Icon, PropTypes } from "@material-ui/core";

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  rating: number;
  review?: string;
}
const iconList: { color: PropTypes.Color | "action" | "disabled" | "error" }[] =
  [
    {
      color: "error",
    },
    {
      color: "error",
    },
    {
      color: "error",
    },
    {
      color: "error",
    },
    {
      color: "error",
    },
  ];
export const ReviewModal: FC<IProps> = ({
  showModal,
  onCloseModal,
  rating,
  review = "",
}) => {
  return (
    <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
      <div
        className={"flex flex-col bg-white p-16 rounded items-center"}
        style={{ minWidth: "30%", maxWidth: "30%" }}
      >
        <div className={"flex  bg-white p-16 rounded items-center"}>
          {iconList.map((item, index) => {
            let clr = item.color;
            if (rating <= index) {
              clr = "action";
            }
            return <Icon color={clr}>star</Icon>;
          })}
        </div>
        <span className="mt-8">{review}</span>
      </div>
    </CustomModal>
  );
};
