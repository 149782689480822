import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

//NEW ENDPOINTS
export const LOGIN = "admin/auth/login";
export const LOGIN_STAFF = "/admin/staff/login";
export const CLIENT = "admin/auth/client";
export const LOGOUT = "admin/auth/logout";
export const EXPORT_ORDERS = "/admin/export-orders";
export const TIPS = "tips";
export const RATINGS = "ratings";
export const FAILED_PAYMENTS = "failed-payments";
export const CHANGE_PASSWORD = "/admin/change-password";

//OLD ENDPOINTS
export const UPLOAD = "uploadfile.php";
export const INDEX =
  "index.php?apikey=67a74306b06d0c01624fe0d0249a570f4d093747&";
export const APP_CODE = "1";

export const getUserId = (): string => {
  return getStorageItem(STORAGE_KEY.USER_ID);
};

export const getMasterId = (): string => {
  return getStorageItem(STORAGE_KEY.MASTER_ID);
};

export const loginUserUrl = (): string => {
  return LOGIN;
};

export const getClientsListUrl = (): string => {
  return "admin/client";
};
export const updateClientUrl = (): string => {
  return "admin/client";
};
export const updateCheckClientUrl = (): string => {
  return "/admin/check/client";
};
export const getAccessClientUrl = (): string => {
  return "admin/access_client";
};
export const getRegisterUserUrl = (): string => {
  return "collaborator/auth/register";
};
export const getUserInfoUrl = (id): string => {
  return "collaborator/" + id;
};

export const getReferralsUrl = (code): string => {
  return "collaborator/referrals/" + code;
};
export const getClientDetailsUrl = (id): string => {
  return "admin/client/" + id;
};

export const createClientUrl = (isCollaborator): string => {
  return isCollaborator
    ? encodeURI("collaborator/client")
    : encodeURI("admin/client");
};

export const getOrderListUrl = (): string => {
  return encodeURI("admin/order");
};
export const getOrderDetailUrl = (id: string): string => {
  return encodeURI("admin/order/" + id);
};

export const deleteOrderUrl = (): string => {
  return encodeURI("admin/order/delete");
};

export const checkEmaillUrl = (): string => {
  return encodeURI("/venue/validate-email");
};

export const getAnalyticsUrl = (): string => {
  return encodeURI("admin/analytics");
};

export const createCollaboratorUrl = (): string => {
  return "collaborator/auth/register";
};
export const getCollaboratorUrl = (): string => {
  return "/collaborators";
};

export const changeCollaboratorPasswordUrl = (): string => {
  return "/admin/auth/changeCollaboratorPassword";
};
export const getVenuesListUrl = (): string => {
  return "/admin/venue/getAllVenues";
};
export const venuesStatusUpdateUrl = (): string => {
  return "/admin/venue/updateStatus";
};
export const getPosInputs = (): string => {
  return "/admin/venue/pos/pos-integration/";
};
export const updatePosIntegration = (): string => {
  return "/admin/venue/pos/pos-integration/update";
};
export const getClientIntegrationUrl = (): string => {
  return "/admin/venue/pos/pos-integration/current";
};
export const updateVenuePopupUrl = (): string => {
  return "/admin/venue/popup";
};
export const getVenuePopupUrl = (venueId): string => {
  return "/admin/venue/popup/" + venueId;
};

export const getMenueById = `menu-admin`;
export const getTwilioMessages = `/admin/twilio/getTwilioMessages`;
export const STAFF = `/admin/staff`;
export const STAFF_DETAIL = `/admin/staff-detail`;
export const COLLABORATOR_GROUP = `/admin/collaborator-group`;
export const COLLABORATOR_GROUP_DETAIL = `/admin/collaborator-group-detail`;
export const COLLABORATOR_USER = `/admin/collaborator-user`;
export const COLLABORATOR_USER_DETAIL = `/admin/collaborator-user-detail`;
export const COLLABORATOR_USER_GROUP = `/admin/collaborator-user-group`;
export const STAFF_BY_ROLE = `/admin/staff-by-role`;
export const ROLES = `/admin/roles`;
export const getVenueDetail = `/admin/venue`;
export const GET_TAXES = "/getTaxes";
export const COLLABORATOR_CLIENT = `/admin/collaborator-client`;
export const UN_ASSIGN_COLLABORATOR_GROUP =
  "/admin/collaborator-group-unassign";
export const ADMIN_MENU_V2 = `menu-v2-admin`;
export const GET_CURRENCIES = "/currencies";
