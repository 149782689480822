import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Fade, Modal, Backdrop } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch } from "react-redux";
import { getCollaboratorGroupList } from "app/store/collaboratorGroup/CollaboratorGroupActions";
import { createCollaboratorUserGroupGroup } from "app/store/collaboratorUser/CollaboratorUserActions";
import CreateGroupDropDown from "app/main/common/CreateGroupDropDown";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "20%",
  },
  "availability--datepicker": {
    border: "1px solid #ddd",
  },
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 1000,
    },
    "& .rbc-label": {
      padding: "8px 6px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important",
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
      // ...theme.mixins.border(0),
    },
    "& .rbc-agenda-view table": {
      // ...theme.mixins.border(1),
      "& thead > tr > th": {
        // ...theme.mixins.borderBottom(0),
      },
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {
          // ...theme.mixins.borderLeft(1),
        },
      },
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-time-content": {
        flex: "0 1 auto",

        // ...theme.mixins.border(1),
      },
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-month-row": {
        // ...theme.mixins.border(1),
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128,
      },
      "& .rbc-header + .rbc-header": {
        // ...theme.mixins.borderLeft(1),
      },
      "& .rbc-header": {
        // ...theme.mixins.borderBottom(0),
      },
      "& .rbc-day-bg + .rbc-day-bg": {
        // ...theme.mixins.borderLeft(1),
      },
    },
    "& .rbc-day-slot .rbc-time-slot": {
      // ...theme.mixins.borderTop(1),
      opacity: 0.5,
    },
    "& .rbc-time-header > .rbc-row > * + *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-content > * + * > *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-day-bg + .rbc-day-bg": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-header > .rbc-row:first-child": {
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-timeslot-group": {
      minHeight: 64,
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit",
      },
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2],
      },
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px",
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0,0,0,0.03)"
          : "rgba(0,0,0,0.16)",
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child":
      {
        left: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child":
      {
        right: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99,
  },
}));
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() - 1);

function CreateGroupModal(props) {
  const classes = useStyles(props);
  const { languageStrings } = useLanguageHelper();
  const [groupId, setGroupId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaboratorGroupList());
  }, []);

  const isError = !groupId;

  const onCreateGroup = () => {
    if (props && groupId && props.selectedCollaborators) {
      dispatch(
        createCollaboratorUserGroupGroup({
          groupId,
          selectedCollaborators: props.selectedCollaborators,
        })
      );
      props.onClose();
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleChange = (event) => {
    setGroupId(event.target.value);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 className="pt-8 pb-6" id="transition-modal-title">
            {languageStrings["COLLABORATOR.GROUP"]}
          </h2>
          <p id="transition-modal-description">
            <CreateGroupDropDown
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              handleChange={handleChange}
              value={groupId}
            />
            <div className="flex mt-10 items-center justify-center">
              <div
                style={{
                  color: "white",
                  textDecoration: "none",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: "red",
                  cursor: "pointer",
                  marginRight: "0.8rem",
                }}
                onClick={props.onClose}
              >
                {languageStrings["GENERAL.CANCEL_BUTTON"]}
              </div>

              <div
                style={{
                  color: "white",
                  textDecoration: "none",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: isError ? "grey" : "#1D6F42",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!isError) {
                    onCreateGroup();
                  }
                }}
              >
                {languageStrings["CREATE_GROUP"]}
              </div>
            </div>
          </p>
        </div>
      </Fade>
    </Modal>
  );
}

export default CreateGroupModal;
