import { OrderActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: OrderState = {
  orderList: [],
  totalOrders: 0,
  loading: false,
  searchText: "",
  orderDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const OrdersReducer = (
  state: OrderState = INITIAL_STATE,
  action: Action
): OrderState => {
  switch (action.type) {
    case OrderActionTypes.SET_ORDER_SEARCH_TEXT:
      return updateObject(state, {
        searchText: action.payload.searchText,
      });
    case OrderActionTypes.ORDERS_LIST_START:
      return updateObject(state, {
        loading: true,
        orderList: [],
      });

    case OrderActionTypes.ORDERS_LIST_SUCCESS:
      return updateObject(state, {
        orderList: action.payload.orders,
        totalOrders: action.payload.totalOrders,
        loading: false,
      });
    case OrderActionTypes.ORDERS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.ORDER_DETAIL_START:
      return updateObject(state, {
        loading: true,
        orderDetail: {},
      });

    case OrderActionTypes.ORDER_DETAIL_SUCCESS:
      return updateObject(state, {
        orderDetail: action.payload.data,
        loading: false,
      });

    case OrderActionTypes.ORDER_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.EXPORT_ORDERS_LIST_START:
      return updateObject(state, {
        loading: true,
      });
    case OrderActionTypes.EXPORT_ORDERS_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
      });
    case OrderActionTypes.EXPORT_ORDERS_LIST_FAIL:
      return updateObject(state, {
        loading: false,
      });
    default: {
      return state;
    }
  }
};

export default OrdersReducer;
