import React from "react";

export const MenuExtra = ({ product = {}, orderItems = [] }) => {
  const ExtraCat = ({
    extraCategories = [],
    showExtraTitle = true,
    productItem = null,
  }) => {
    return (
      <td className="w-80" style={{ padding: "0", borderBottom: 0 }}>
        {productItem && productItem.title ? (
          <td className="w-80 text-left pb-8" style={{ padding: "0" }}>
            <span className="truncate ">
              <b>{productItem.title}</b>
            </span>
          </td>
        ) : null}
        {extraCategories.map((item) => {
          return (
            <p>
              {showExtraTitle ? (
                <span className="truncate p-0">
                  <b>{item.title}</b>
                </span>
              ) : null}
              {item.items.map((subItem) => {
                if (!subItem.selected) {
                  return null;
                }
                let quantity = "";
                if (subItem && subItem.selectedQuantity > 0)
                  quantity = `x ${subItem.selectedQuantity}`;

                return (
                  <p>
                    <span className="truncate">
                      {subItem.title} {quantity}
                    </span>
                  </p>
                );
              })}
            </p>
          );
        })}
      </td>
    );
  };
  const isProductCombo =
    (product && product.isCombo) ||
    (product.comboCategories && product.comboCategories.length);
  if (!product) return null;

  return (
    <>
      {isProductCombo ? (
        product.comboCategories.map((comboCat) => {
          const products =
            comboCat && comboCat.products ? comboCat.products : [];
          return products.map((productItem) => {
            if (productItem && productItem.selected === true) {
              const productExtras =
                productItem && productItem.extraCategories
                  ? productItem.extraCategories
                  : [];
              return (
                <tr>
                  <ExtraCat
                    productItem={productItem}
                    showExtraTitle={false}
                    extraCategories={productExtras}
                  />
                </tr>
              );
            } else return null;
          });
        })
      ) : (
        <ExtraCat extraCategories={orderItems} />
      )}
    </>
  );
};
