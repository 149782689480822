import React, { useEffect, useState } from "react";
import { Icon, Tab, Tabs, Typography } from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Link } from "react-router-dom";
import withReducer from "app/store/withReducer";
import LoadingOverlay from "react-loading-overlay";
import OrderUserDetail from "./components/OrderUserDetail";
import OrderStatusDetail from "./components/OrderStatusDetail";
import reducer from "app/store/order/OrderReducer";
import { getOrderDetail } from "app/store/order/OrderActions";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import OrderDeliveryDetail from "./components/OrderDeliveryDetail";
import OrderComments from "./components/OrderComments";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { clientSettings } from "config";
import { MenuExtra } from "../common/MenuExtra";

const Order = (props) => {
  const dispatch = useDispatch();
  const order = useSelector(({ banzzu }) => banzzu.order.orderDetail);
  const { productId } = props.match.params;
  const loading = useSelector(({ banzzu }) => banzzu.order.loading);
  const [tabValue, setTabValue] = useState(0);

  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    dispatch(getOrderDetail(productId));
  }, [dispatch, productId]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          order &&
          order.customer && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Typography
                    className="normal-case flex items-center sm:mb-12"
                    component={Link}
                    role="button"
                    to="/orders"
                    color="inherit"
                  >
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["ORDER_PAGE.ORDERS"]}
                  </Typography>
                </FuseAnimate>
                <div className="flex flex-col min-w-0 items-center sm:items-start">
                  <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                    <Typography className="text-16 sm:text-20 truncate">
                      {languageStrings["ORDER_PAGE.ORDER"] +
                        order.referenceNo && order.referenceNo}
                    </Typography>
                  </FuseAnimate>

                  <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                    <Typography variant="caption">
                      {"From " +
                        order.customer.firstName +
                        " " +
                        order.customer.lastName}
                    </Typography>
                  </FuseAnimate>
                </div>
              </div>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["ORDER_PAGE.ORDER_DETAIL"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["ORDER_PAGE.PRODUCTS"]}
            />
          </Tabs>
        }
        content={
          order &&
          order.customer && (
            <div className="p-16 sm:p-24 w-full">
              {/*Order Details*/}
              {tabValue === 0 && (
                <div>
                  <div className="flex flex-wrap">
                    {clientSettings.showUserDetails && (
                      <OrderUserDetail customer={order.customer} />
                    )}
                    <OrderStatusDetail
                      orderNumber={order.referenceNo}
                      orderTime={order.orderTime}
                      statuses={order.status}
                      table={order.orderTable}
                      order={order}
                    />
                  </div>
                  {order.comments && (
                    <OrderComments comments={order.comments} />
                  )}
                  {order.type === "2" && (
                    <OrderDeliveryDetail delivery={order.address} />
                  )}
                </div>
              )}
              {/*Products*/}
              {tabValue === 1 && (
                <div className="table-responsive">
                  <table className="simple">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{languageStrings["ORDER_PAGE.NAME"]}</th>
                        <th>{languageStrings["ORDER_PAGE.EXTRAS"]}</th>
                        <th>{languageStrings["GENERAL.PRICE"]}</th>
                        <th>{languageStrings["ORDER_PAGE.QUANTITY"]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.items &&
                        order.items.map((product) => (
                          <tr key={product.id}>
                            <td className="w-80">
                              {product.hasImage && (
                                <img
                                  className="w-80"
                                  src={formatImageUrl(product.image)}
                                  alt=""
                                />
                              )}
                            </td>
                            <td className="w-64">
                              <span className="truncate">{product.title}</span>
                            </td>
                            <td className="w-80">
                              <MenuExtra
                                orderItems={product.extraCategories}
                                product={product}
                              />
                            </td>
                            <td className="w-32">
                              <span className="truncate flex  items-center">
                                {Number(product.price).toFixed(2)} €
                                {product && product.discountedQuantity > 0 ? (
                                  <Typography className="h4 text-red ml-4">
                                    {` (${product.discountedQuantity} ${languageStrings["FOR_FREE"]})`}
                                  </Typography>
                                ) : null}
                              </span>
                            </td>
                            <td className="w-32">
                              <span className="truncate">
                                {product.quantity}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("OrdersReducer", reducer)(Order);
