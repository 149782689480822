import { axiosInstance } from "app/api/axios";

import { CopyMenuV2ActionTypes } from "../redux/actionTypes";
import { ADMIN_MENU_V2 } from "app/api/Endpoint";
import { successAlert } from "app/helpers/alerts";

export const getMenuV2List = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CopyMenuV2ActionTypes.GET_MENU_LIST_START,
    });

    const params = {
      userId: payload,
    };
    axiosInstance
      .get(ADMIN_MENU_V2, { params })
      .then((res) => {
        let { data } = res.data;
        getMenuV2ListSuccess(dispatch, data);
      })
      .catch((e) => {
        getMenuV2ListFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuV2ListFail = (dispatch, errorMessage) => {
  dispatch({
    type: CopyMenuV2ActionTypes.GET_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuV2ListSuccess = (dispatch, data) => {
  dispatch({
    type: CopyMenuV2ActionTypes.GET_MENU_LIST_SUCCESS,
    payload: data,
  });
};
export const copyMenuV2 = (
  menuId,
  clientId,
  secondClientId,
  languageStrings
) => {
  return (dispatch) => {
    dispatch({
      type: CopyMenuV2ActionTypes.COPY_MENU_V2_START,
    });

    const payload = { menuId, clientId, secondClientId };
    axiosInstance
      .post(ADMIN_MENU_V2, payload)
      .then((res) => {
        let { data } = res.data;
        copyMenuV2Success(dispatch, data, languageStrings);
      })
      .catch((e) => {
        copyMenuV2Fail(dispatch, "There was an error connection");
      });
  };
};

const copyMenuV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: CopyMenuV2ActionTypes.COPY_MENU_V2_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const copyMenuV2Success = (dispatch, data, languageStrings) => {
  successAlert(dispatch, languageStrings["MENU_DUPLICATE_SUCCESS"]);
  dispatch({
    type: CopyMenuV2ActionTypes.COPY_MENU_V2_SUCCESS,
    payload: data,
  });
};
