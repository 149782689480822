import CollaboratorList from "./CollaboratorList";
import CreateCollaboratorPage from "./CreateCollaboratorPage";
import CreateCollaboratorGroupPage from "./CollaboratorGroupList";

export const CollaboratorListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/collaboratorsList",
      component: CollaboratorList,
    },
    {
      path: "/collaborator-group",
      component: CreateCollaboratorGroupPage,
    },
    {
      path: "/add-collaborator/:collaboratorId",
      component: CreateCollaboratorPage,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
