import { paginationActionTypes } from "../redux/actionTypes";

export const setListLimitPage = (
  dispatch,
  limit: number = 10,
  page: number = 0
) => {
  dispatch({
    type: paginationActionTypes.SET_LIST_LIMIT_PAGE,
    payload: {
      limit: limit,
      page: page,
    },
  });
};
